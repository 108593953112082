* {
  margin: 0;
  padding: 0;
  border: 0;
  border-collapse: collapse;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  outline: none;
}
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  border-collapse: collapse;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  outline: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote::before,
q::before,
blockquote::after,
q::after {
  content: '';
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a,
button {
  cursor: pointer;
}
input:-webkit-autofill,
select:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
body {
  font-family: 'FrutigerLTStd-Roman';
}
@font-face {
  font-family: 'FrutigerLTStd-Roman';
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('fonts/FrutigerLTStd-Roman/FrutigerLTStd-Roman.eot?');
  src: local('FrutigerLTStd-Roman'),
    url('fonts/FrutigerLTStd-Roman/FrutigerLTStd-Roman.woff2') format('woff2'),
    url('fonts/FrutigerLTStd-Roman/FrutigerLTStd-Roman.eot?#iefix') format('embedded-opentype'),
    url('fonts/FrutigerLTStd-Roman/FrutigerLTStd-Roman.otf') format('opentype'),
    url('fonts/FrutigerLTStd-Roman/FrutigerLTStd-Roman.woff') format('woff'),
    url('fonts/FrutigerLTStd-Roman/FrutigerLTStd-Roman.ttf') format('truetype'),
    url('fonts/FrutigerLTStd-Roman/FrutigerLTStd-Roman.svg#FrutigerLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FrutigerLTStd-Bold';
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('FrutigerLTStd-Bold'), url('fonts/FrutigerLTStd-Bold/FrutigerLTStd-Bold.eot?');
  src: url('fonts/FrutigerLTStd-Bold/FrutigerLTStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/FrutigerLTStd-Bold/FrutigerLTStd-Bold.woff2') format('woff2'),
    url('fonts/FrutigerLTStd-Bold/FrutigerLTStd-Bold.otf') format('opentype'),
    url('fonts/FrutigerLTStd-Bold/FrutigerLTStd-Bold.woff2') format('woff2'),
    url('fonts/FrutigerLTStd-Bold/FrutigerLTStd-Bold.woff') format('woff'),
    url('fonts/FrutigerLTStd-Bold/FrutigerLTStd-Bold.ttf') format('truetype'),
    url('fonts/FrutigerLTStd-Bold/FrutigerLTStd-Bold.svg#FrutigerLTStd-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FrutigerLTStd-Light';
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('FrutigerLTStd-Bold');
  src: local('FrutigerLTStd-Light'),
    url('fonts/FrutigerLTStd-Light/FrutigerLTStd-Light.woff2') format('woff2'),
    url('fonts/FrutigerLTStd-Light/FrutigerLTStd-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/FrutigerLTStd-Light/FrutigerLTStd-Light.otf') format('opentype'),
    url('fonts/FrutigerLTStd-Light/FrutigerLTStd-Light.woff') format('woff'),
    url('fonts/FrutigerLTStd-Light/FrutigerLTStd-Light.ttf') format('truetype'),
    url('fonts/FrutigerLTStd-Light/FrutigerLTStd-Light.svg#FrutigerLTStd-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
.FONT-ROMAN,
.FONT-DEFAULT,
.FONT-SMALLER,
#disclaimer .holder ul li,
.link.small,
.listOfAreas.predictiveSearch .areaGroup .area .link,
.listOfAreas.predictiveSearch .areaGroup .area .button,
.listOfAreas:not(.predictiveSearch).small .areaGroup .area .button,
.listOfAreas:not(.predictiveSearch).small .areaGroup .area .link,
.listOfAreas .areaGroup .area,
.listOfAreas.small .areaGroup .area,
.text p,
.text p .link,
.contactData .link,
.contactData p,
.footerInner .contactData .link,
.footerInner .contactData p,
.colorPick p,
.timeTable h3,
.timeTable p,
.address,
.address h5,
.purchase .detail > p,
.bill > p span,
.button,
.input,
.textarea,
.input + label,
.textarea + label,
.input:placeholder-shown + label,
.textarea:placeholder-shown + label,
.geosuggest .geosuggest__input-wrapper .geosuggest__input,
.geosuggest__item,
.select .placeholder,
.select .optionHolder .option,
.sizePick p,
.sizePick .form .field label,
.footerInner,
.footerInner .warranties .warranty,
.footerInner .copyRight,
.price em,
.productList .productPreview .title,
.productList .productPreview .subTitle,
.productListed .subTitle,
.bill .productListed .title,
.bill .productListed .price,
.product .previewHolder.mainProduct .bottom h2,
.product .previewHolder.mainProduct .bottom .dateAvailable,
.product .previewHolder.mainProduct .bottom .row .price .iva,
.pack .packNum,
.pack .info .packItem,
.pack .info .packItem span,
.pack .info .packItem.main,
.magazinePreview .info h3,
.magazinePreview .info p,
.magazinePreview .buttonGroup .button,
.characts h3,
.characts p,
.filters .buttonGroup .button,
.filtersOrder .button:hover:before,
.filterOpen .button:not(.tag),
.filter > .link,
.filter .collapsibleHeading,
.filter .collapsibleContent .button,
.priceRangeLabel,
.popUp,
.cartToggle .button.shipping,
.cartToggle .button.economyShipping,
.cartToggle .button.freeShipping,
.cartToggle .button.payment,
.deliveryInfo,
.cartCalc .taxes .iva,
.cartCalc .taxes .tax,
.cartCalc .promo .feedback p,
.cartCalc .taxes p.coupon,
.cartCalc .taxes p.total,
.cartItem .itemData p,
.distribution p,
.emptyCart,
.cart-message,
.form .feedback {
  font-family: 'FrutigerLTStd-Roman';
  font-weight: normal;
}
.FONT-LIGHT,
.TITLE,
.TITLE-CAPS,
.TITLE-CAPS-22,
.TITLE-26,
.PRICE-SMALLER,
h2,
.homeSections .bannerSection .img,
.homeSections .bannerSection p,
h4,
.purchase .openPurchase .charge,
.form p.helpUs,
.button.default.big,
.input + label,
.textarea + label,
.newsletter.edit.feedback .text,
.promoPreview .bottom h3,
.price,
.previewHolder .bottom .title,
.productPreview.slider .title,
.productPreview.result .bottom h4.title,
.productPreview.result .bottom .price,
.productListed .subTitle,
.productListed .price,
.product .previewHolder.mainProduct .bottom h1,
.product .previewHolder.mainProduct .bottom .dateAvailable,
.pack .packNum,
.pack .info .packItem,
.magazinePreview .info h2,
.counter,
.abc .buttonGroup .button,
.tagList > span,
.cartToggle .button.shipping em,
.cartToggle .button.shipping .price,
.cartToggle .button.freeShipping em,
.cartToggle .button.freeShipping .price,
.cartCalc .taxes .iva,
.cartCalc .taxes .tax,
.cartCalc .taxes .total,
.cartCalc .taxes p.coupon,
.cartItem .itemData p.delivery,
.cartItem .itemData p.charge,
.priceShipping,
.feedback.cartFloat {
  font-family: 'FrutigerLTStd-Light';
  font-weight: normal;
}
.FONT-BOLD,
.FONT-TAG,
.TITLE-CAPS-16,
.SMALL-CAPS,
h3,
.bulletTitle .collapsibleHeading,
.box span,
.timeTable h3,
.purchase .openPurchase .code,
.purchase .openPurchase .date,
.bill > p span,
.freeDelivery .conditionsButton p,
.header .headerBottom .nav .button,
.iconLink.cartLink .cartCounter,
.footerInner .warranties .warranty,
.ebookInfo .steps span,
.filters .filterHeader > *:first-child,
.tag,
.tag.soon,
.tag.eBook,
.tag.stock,
.tag.noStock,
.cartToggle .button.address.add,
.cartToggle.noMargin.horizontal .button,
.cartCalc .taxes .subtotal,
.cartCalc .taxes p,
.cartItem .itemData .cartItemFooter .price,
.itemCounter {
  font-family: 'FrutigerLTStd-Bold';
  font-weight: normal;
}
.CAPS,
.TITLE-CAPS,
.TITLE-CAPS-22,
.TITLE-CAPS-16,
.SMALL-CAPS,
.PRICE-SMALLER,
h2,
h3,
.homeSections .bannerSection .img,
.homeSections .bannerSection p,
h4,
.bulletTitle .collapsibleHeading,
.box span,
.purchase .openPurchase .code,
.purchase .openPurchase .date,
.purchase .openPurchase .charge,
.navResponsive .link.rounded,
.header .headerBottom .nav .button,
.iconLink.cartLink .cartCounter,
.button.default.big,
.price,
.productPreview.result .bottom .price,
.productListed .price,
.filters .filterHeader > *:first-child,
.abc .buttonGroup .button,
.tag.soon,
.tag.eBook,
.tag.stock,
.tag.noStock,
.cartToggle .button.address.add,
.cartToggle .button.shipping .price,
.cartToggle .button.freeShipping .price,
.cartToggle .button.payment,
.cartToggle.noMargin.horizontal .button,
.cartCalc .taxes .subtotal,
.cartCalc .taxes p,
.cartCalc .taxes p.total,
.cartItem .itemData .cartItemFooter .price,
.itemCounter,
.priceShipping {
  text-transform: uppercase;
}
.FONT-DEFAULT,
.listOfAreas .areaGroup .area,
.address h5,
.button,
.input,
.textarea,
.input:placeholder-shown + label,
.textarea:placeholder-shown + label,
.geosuggest .geosuggest__input-wrapper .geosuggest__input,
.footerInner,
.price em,
.productList .productPreview .title,
.product .previewHolder.mainProduct .bottom h2,
.magazinePreview .info h3,
.characts h3,
.characts p,
.priceRangeLabel,
.deliveryInfo,
.cartCalc .taxes .iva,
.cartCalc .taxes .tax,
.cartCalc .taxes p.coupon,
.cartCalc .taxes p.total {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.06em;
}
.FONT-DEFAULT-TRUNCATED,
.productList .productPreview .title,
.productList .productPreview .subTitle {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: 51.800000000000004px;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.FONT-SMALLER,
#disclaimer .holder ul li,
.link.small,
.listOfAreas.predictiveSearch .areaGroup .area .link,
.listOfAreas.predictiveSearch .areaGroup .area .button,
.listOfAreas:not(.predictiveSearch).small .areaGroup .area .button,
.listOfAreas:not(.predictiveSearch).small .areaGroup .area .link,
.listOfAreas.small .areaGroup .area,
.text p,
.text p .link,
.contactData .link,
.contactData p,
.colorPick p,
.timeTable h3,
.timeTable p,
.address,
.purchase .detail > p,
.bill > p span,
.input + label,
.textarea + label,
.geosuggest__item,
.select .placeholder,
.select .optionHolder .option,
.sizePick p,
.sizePick .form .field label,
.footerInner .warranties .warranty,
.footerInner .copyRight,
.productList .productPreview .subTitle,
.productListed .subTitle,
.bill .productListed .title,
.bill .productListed .price,
.product .previewHolder.mainProduct .bottom .dateAvailable,
.product .previewHolder.mainProduct .bottom .row .price .iva,
.pack .packNum,
.pack .info .packItem,
.pack .info .packItem span,
.pack .info .packItem.main,
.magazinePreview .info p,
.magazinePreview .buttonGroup .button,
.filters .buttonGroup .button,
.filtersOrder .button:hover:before,
.filterOpen .button:not(.tag),
.filter > .link,
.filter .collapsibleHeading,
.filter .collapsibleContent .button,
.popUp,
.cartToggle .button.shipping,
.cartToggle .button.economyShipping,
.cartToggle .button.freeShipping,
.cartToggle .button.payment,
.cartCalc .promo .feedback p,
.cartItem .itemData p,
.distribution p,
.emptyCart,
.cart-message,
.form .feedback {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.05em;
}
.FONT-TAG,
.tag {
  font-size: 14px;
  letter-spacing: 0.05em;
}
.TITLE,
.TITLE-CAPS,
.TITLE-CAPS-22,
.TITLE-26,
.PRICE-SMALLER,
h2,
.homeSections .bannerSection .img,
.homeSections .bannerSection p,
h4,
.purchase .openPurchase .charge,
.button.default.big,
.promoPreview .bottom h3,
.price,
.previewHolder .bottom .title,
.productPreview.slider .title,
.productPreview.result .bottom h4.title,
.productPreview.result .bottom .price,
.productListed .price,
.product .previewHolder.mainProduct .bottom h1,
.magazinePreview .info h2,
.abc .buttonGroup .button,
.cartToggle .button.shipping .price,
.cartToggle .button.freeShipping .price,
.priceShipping {
  font-weight: normal;
  font-size: 18px;
  line-height: 1.1em;
  letter-spacing: 0.07em;
  text-align: left;
}
.TITLE-TRUNCATED,
.productPreview.slider .title,
.productPreview.result .bottom h4.title {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  line-height: 1.1em;
  max-height: 55px;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.TITLE-CAPS-22,
h2,
.homeSections .bannerSection .img,
.homeSections .bannerSection p,
.button.default.big {
  font-size: 22px;
  text-align: center;
}
.TITLE-26,
.productPreview.slider .title {
  font-size: 26px;
  text-align: center;
}
.TITLE-CAPS-16,
h3,
.bulletTitle .collapsibleHeading,
.filters .filterHeader > *:first-child,
.cartCalc .taxes .subtotal,
.cartItem .itemData .cartItemFooter .price {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
}
.SMALL-CAPS,
.box span,
.purchase .openPurchase .code,
.purchase .openPurchase .date,
.header .headerBottom .nav .button,
.iconLink.cartLink .cartCounter,
.tag.soon,
.tag.eBook,
.tag.stock,
.tag.noStock,
.cartToggle .button.address.add,
.cartToggle.noMargin.horizontal .button,
.cartCalc .taxes p,
.itemCounter {
  font-size: 12px;
  letter-spacing: 0.08em;
}
.PRICE-SMALLER,
.purchase .openPurchase .charge,
.price,
.productPreview.result .bottom .price,
.productListed .price,
.cartToggle .button.shipping .price,
.cartToggle .button.freeShipping .price,
.priceShipping {
  text-align: right;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
}
.SCRATCHED,
.price em {
  text-decoration: line-through;
  color: #b3b1b1;
  font-style: normal;
  letter-spacing: 0;
  font-size: 15px;
  line-height: 12px;
}
h2 {
  text-align: left;
  margin-bottom: 10px;
}
h3 {
  text-align: left;
  margin-bottom: 10px;
}
@media screen and (min-width: 1025px) {
  h2,
  h3 {
    max-width: 1441px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1920px) {
  h2,
  h3 {
    max-width: 1920px;
  }
}
.FLEX,
.FLEX-COL,
.FLEX-ROW-WRAP,
.FLEX-ROW-NOWRAP,
#disclaimer,
#disclaimer .holder .between .heavyPad,
.searchBox,
.homeSections,
#contact .heavyPad,
.colorPick .buttonGroup,
.colorPick .buttonGroup .button.unavailable:before,
.box,
.errorMessage.outOfStock,
.timeTable p,
.purchase .openPurchase,
.purchase .detail > p,
.bill > p,
.introMag > .text,
.introMag > .button,
.introMag > .row,
.introMag > .button.button,
.introMag > .row.button,
body .mainLayout .scrollbarHolder > div .mainSection,
html .mainLayout .scrollbarHolder > div .mainSection,
.pageContent#filters,
.pageContent#contactForm,
.pageContent#filters .form,
.pageContent#contactForm .form,
.pageContent#filters .form .field.textareaHolder,
.pageContent#contactForm .form .field.textareaHolder,
.actionsHolder,
.header,
.header .headerTop .userZone,
.header .headerBottom .nav,
.newsletter,
#cancelNewsletter.newsletter .phase1 .form,
#cancelNewsletter.newsletter .phase1 .form .actions,
.select .placeholder,
.select .placeholder i,
.select .optionHolder .option,
.sizePick .form .field,
.footerInner,
.footerInner .copyRight,
.paymentMethod,
.comingSoonBanner,
.productList,
.predictiveWrapper,
.predictiveWrapper .predictiveItem,
.productListed,
.product .previewHolder.mainProduct .bottom .row,
.product .productData.magazines,
.pack,
.pack .info .packItem,
.magazinePreview,
.magazinePreview .info,
.magazinePreview .buttonGroup,
.filters .filterHeader,
.filters .buttonGroup,
.filtersOrder .button:hover:before,
.pageContent#filters .filters,
.pageContent#filters .filters .row,
.filterOpen.areasOfInterest.expanded > div,
.abc .buttonGroup,
.tags,
.popUp,
.popUp.modal,
.cartToggle .button.payment,
.cartToggle.noMargin.horizontal,
.cartCalc,
.cartCalc .promo,
.cartCalc .promo .feedback,
.cartCalc .taxes p,
.cartCalc .taxes p strong,
.cartItem,
.cartItem .itemData,
.cartItem .itemData .cartItemFooter,
.itemCounter {
  display: flex;
}
.FLEX-COL,
.box,
.introMag > .text,
.introMag > .button,
.introMag > .row,
body .mainLayout .scrollbarHolder > div .mainSection,
html .mainLayout .scrollbarHolder > div .mainSection,
.pageContent#filters,
.pageContent#contactForm,
.pageContent#filters .form,
.pageContent#contactForm .form,
.pageContent#filters .form .field.textareaHolder,
.pageContent#contactForm .form .field.textareaHolder,
#cancelNewsletter.newsletter .phase1 .form,
.comingSoonBanner,
.pageContent#filters .filters,
.pageContent#filters .filters .row,
.filterOpen.areasOfInterest.expanded > div,
.popUp,
.popUp.modal,
.cartCalc .promo,
.cartItem .itemData {
  flex-direction: column;
}
.FLEX-ROW-WRAP,
.homeSections,
.colorPick .buttonGroup,
.footerInner,
.paymentMethod,
.productList,
.product .productData.magazines,
.magazinePreview .info,
.tags,
.cartCalc,
.cartItem .itemData {
  flex-flow: row wrap;
}
.FLEX-ROW-NOWRAP,
#disclaimer .holder .between .heavyPad,
.searchBox,
#contact .heavyPad,
.colorPick .buttonGroup .button.unavailable:before,
.errorMessage.outOfStock,
.timeTable p,
.purchase .openPurchase,
.purchase .detail > p,
.bill > p,
.actionsHolder,
.header,
.header .headerTop .userZone,
.header .headerBottom .nav,
.newsletter,
.predictiveWrapper,
.productListed,
.product .previewHolder.mainProduct .bottom .row,
.pack,
.pack .info .packItem,
.magazinePreview,
.magazinePreview .buttonGroup,
.filters .filterHeader,
.filters .buttonGroup,
.abc .buttonGroup,
.cartToggle .button.payment,
.cartToggle.noMargin.horizontal,
.cartCalc .taxes p,
.cartCalc .taxes p strong,
.cartItem,
.cartItem .itemData .cartItemFooter,
.itemCounter {
  flex-flow: row nowrap;
}
.START-STRETCH,
body .mainLayout .scrollbarHolder > div .mainSection,
html .mainLayout .scrollbarHolder > div .mainSection,
.pageContent#filters,
.pageContent#contactForm,
.pageContent#filters .form,
.pageContent#contactForm .form,
.pageContent#filters .form .field.textareaHolder,
.pageContent#contactForm .form .field.textareaHolder,
.pageContent#filters .filters,
.pageContent#filters .filters .row,
.cartItem {
  justify-content: flex-start;
  align-items: stretch;
}
.START-CENTER,
.searchBox,
.colorPick .buttonGroup,
.timeTable p,
.purchase .detail > p,
.bill > p,
.select .optionHolder .option,
.predictiveWrapper,
.predictiveWrapper .predictiveItem,
.abc .buttonGroup,
.cartItem .itemData {
  justify-content: flex-start;
  align-items: center;
}
.END-CENTER,
.sizePick .form .field,
.itemCounter.disabled {
  justify-content: flex-end;
  align-items: center;
}
.START-START {
  justify-content: flex-start;
  align-items: flex-start;
}
.SP-BETWEEN-START,
.footerInner .copyRight,
.productList {
  justify-content: space-between;
  align-items: flex-start;
}
.SP-BETWEEN-CENTER,
.homeSections,
.header,
.select .placeholder,
.productListed,
.product .previewHolder.mainProduct .bottom .row,
.product .productData.magazines,
.pack,
.pack .info .packItem,
.cartCalc .promo,
.cartCalc .taxes p,
.cartCalc .taxes p strong,
.itemCounter {
  justify-content: space-between;
  align-items: center;
}
.SP-BETWEEN-END,
.actionsHolder,
.cartCalc,
.cartItem .itemData .cartItemFooter {
  justify-content: space-between;
  align-items: flex-end;
}
.SP-BETWEEN-STRETCH,
.actionsHolder.stretch {
  justify-content: space-between;
  align-items: stretch;
}
.CENTER-CENTER,
#disclaimer,
#disclaimer .holder .between .heavyPad,
.searchBox,
#contact .heavyPad,
.colorPick .buttonGroup .button.unavailable:before,
.box,
.errorMessage.outOfStock,
.purchase .openPurchase,
.introMag > .text,
.introMag > .button,
.introMag > .row,
.header > *.link,
.header .headerBottom .nav,
#cancelNewsletter.newsletter .phase1 .form,
.select .placeholder i,
.paymentMethod,
.comingSoonBanner,
.predictiveWrapper,
.magazinePreview,
.filters .filterHeader,
.filtersOrder .button:hover:before,
.filterOpen.areasOfInterest.expanded > div,
.tags,
.popUp.modal,
.cartToggle .button.payment,
.cartToggle.noMargin.horizontal,
.cartItem .itemData .cartItemFooter {
  justify-content: center;
  align-items: center;
}
.start {
  justify-content: flex-start;
}
.end {
  justify-content: flex-end;
}
.between {
  justify-content: space-between;
}
.around {
  justify-content: space-around;
}
.center {
  justify-content: center;
}
.center-h2 {
  text-align: center;
}
.TRANS-150,
.sizePick .form .field,
.productListed.button:hover .title,
.productListed.button:hover .subTitle,
.feedback {
  transition: all 150ms ease-out;
}
.TRANS-300,
.DIVIDER,
.DIVIDER-OVER-DARK,
.DIVIDER-OVER-DARKER,
.MIDDLE-DOT,
.searchBox,
.homeSections .bannerSection,
.homeSections .blank,
.homeSections .bannerSection .img,
.homeSections .bannerSection p,
.link,
.divider,
.navResponsive .divider,
.footer .divider,
.colorPick .divider,
body .mainLayout,
html .mainLayout,
body .mainLayout .scrollbarHolder > div .mainSection,
html .mainLayout .scrollbarHolder > div .mainSection,
.navResponsive,
.header,
.header .headerTop .userZone .iconLink.button,
.button,
.input,
.textarea,
.field,
.button.default,
.input + label,
.textarea + label,
.input:placeholder-shown + label,
.textarea:placeholder-shown + label,
.select:hover,
.select .optionHolder,
.select .optionHolder .option,
.field.checkbox .icon::after,
.footerInner,
.slick-dots li,
.slick-dots li button::before,
.promoPreview .top .img,
.previewHolder .top .img,
.previewHolder.link:hover,
.previewHolder.link:focus,
.previewHolder.link:hover .top .img,
.previewHolder.link:focus .top .img,
.previewHolder.link:hover .bottom .title,
.previewHolder.link:focus .bottom .title,
.predictiveWrapper,
.filters,
.filters.active,
.filtersOrder .button:hover,
.filterOpen.areasOfInterest,
.abc.fixed,
.tag {
  transition: all 300ms ease-out;
}
.TRANS-600,
.resultsWrapper.fadeIn,
.productListed.button:hover {
  transition: all 600ms ease-out;
}
.TRANS-DELAY-100 {
  transition-delay: 100ms;
}
.CIRCLE,
.MIDDLE-DOT,
.field.checkbox .icon::after,
.slick-dots li,
.slick-dots li button::before {
  border-radius: 50%;
}
.BORDERED,
.FIELD-LIKE,
.bordered,
#disclaimer .holder .between .heavyPad,
#contact .heavyPad,
.box,
[class^='icon-'].bullet-plus,
[class*=' icon-'].bullet-plus,
.icon.bullet-plus,
[class^='icon-'].bullet-minus,
[class*=' icon-'].bullet-minus,
.icon.bullet-minus,
[class^='icon-'].bullet-empty,
[class*=' icon-'].bullet-empty,
.icon.bullet-empty,
[class^='icon-'].bullet-check,
[class*=' icon-'].bullet-check,
.icon.bullet-check,
[class^='icon-'].footer,
[class*=' icon-'].footer,
.icon.footer,
[class^='icon-'].bullet,
[class*=' icon-'].bullet,
.icon.bullet,
[class^='icon-'].bulletCheck,
[class*=' icon-'].bulletCheck,
.icon.bulletCheck,
.sliderHolder .slick-prev.bullet-plus,
.sliderHolder .slick-next.bullet-plus,
.sliderHolder .slick-prev.bullet-minus,
.sliderHolder .slick-next.bullet-minus,
.sliderHolder .slick-prev.bullet-empty,
.sliderHolder .slick-next.bullet-empty,
.sliderHolder .slick-prev.bullet-check,
.sliderHolder .slick-next.bullet-check,
.sliderHolder .slick-prev.footer,
.sliderHolder .slick-next.footer,
.sliderHolder .slick-prev.bullet,
.sliderHolder .slick-next.bullet,
.sliderHolder .slick-prev.bulletCheck,
.sliderHolder .slick-next.bulletCheck,
.field .input,
.field.textareaHolder,
.button.default,
.geosuggest .geosuggest__input-wrapper,
.googleMap,
.slick-dots li,
.predictiveWrapper .predictive,
.productListed,
.productListed.button:hover,
.pack.active,
.filters,
.filterWrapper,
.filtersOrder .button:hover:before,
.filterOpen,
.filter .collapsibleContent,
.tag,
.popUp .modal,
.itemCounter {
  border: 2px solid;
}
.BORDER-BLACK,
.FIELD-LIKE,
.bordered,
#disclaimer .holder .between .heavyPad,
#contact .heavyPad,
.field .input,
.button.default,
.input:placeholder-shown,
.textarea:placeholder-shown,
.input::-webkit-input-placeholder,
.textarea::-webkit-input-placeholder,
.input:-ms-input-placeholder,
.textarea:-ms-input-placeholder,
.input::-moz-placeholder,
.textarea::-moz-placeholder,
.input:-moz-placeholder,
.textarea:-moz-placeholder,
.geosuggest .geosuggest__input-wrapper,
.predictiveWrapper .predictive,
.filterWrapper {
  border-color: #000;
}
.BORDER-PINK,
.input,
.textarea,
.input:focus,
.textarea:focus,
.input:active,
.textarea:active,
.input:placeholder-shown:focus,
.textarea:placeholder-shown:focus,
.input:placeholder-shown:active,
.textarea:placeholder-shown:active,
.field.checkbox .input:checked ~ .icon,
.slick-dots li.slick-active,
.productListed.button:hover,
.filtersOrder .button:hover:before,
.tag {
  border-color: #e6004c;
}
.BORDER-GREY,
.filters.inactive,
.filter > .link,
.filter > .button,
.filter.inactive > .button,
.filter.inactive > .link {
  border-color: #525252;
}
.fullWidth {
  width: 100%;
}
.gap-1 {
  gap: 5px;
}
.gap-2 {
  gap: 10px;
}
.FIELD-LIKE,
.field .input,
.button.default,
.geosuggest .geosuggest__input-wrapper {
  min-height: 50px;
  padding: 0 20px;
  width: 100%;
}
.WHITE-FADE,
.pageContent#filters .filters .row .scrollbarHolder::before {
  background-image: url('https://pics.imgix.net/img/white-fade.png');
  background-size: auto 100%;
}
.DIVIDER,
.DIVIDER-OVER-DARK,
.DIVIDER-OVER-DARKER,
.divider,
.navResponsive .divider,
.footer .divider,
.colorPick .divider {
  height: 2px;
  width: 100%;
  border-radius: 2px;
  margin: 20px auto;
  background-color: #000;
}
.DIVIDER-OVER-DARK,
.navResponsive .divider,
.colorPick .divider {
  background-color: rgba(0, 0, 0, 0.06);
}
@media screen and (min-width: 1025px) {
  .DIVIDER-OVER-DARK,
  .navResponsive .divider,
  .colorPick .divider {
    background-color: #000;
  }
}
.DIVIDER-OVER-DARKER,
.footer .divider {
  background-color: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 1025px) {
  .DIVIDER-OVER-DARKER,
  .footer .divider {
    background-color: #000;
  }
}
.BOX-SHADOW-OVER-DARK,
[class^='icon-'].big,
[class*=' icon-'].big,
.icon.big,
.sliderHolder .slick-prev.big,
.sliderHolder .slick-next.big,
.button.default.pinkFill {
  box-shadow: -2px 4px 0 rgba(0, 0, 0, 0.06);
}
.MIDDLE-DOT,
.field.checkbox .icon::after,
.slick-dots li button::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 4px;
  width: 4px;
  transform: translate(-2px, -2px);
}
.SCROLLBAR-HOLDER,
.select .optionHolder > .scrollbarHolder {
  flex: 1 0 100%;
  position: relative;
  height: 100%;
  min-height: 400px;
}
.SCROLLBAR-HOLDER > div,
.select .optionHolder > .scrollbarHolder > div {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}
.header {
  z-index: 4;
}
.buttonMenu {
  z-index: 5000;
}
.searchBox {
  z-index: 4;
}
.cartHeader {
  z-index: 4;
}
.feedback.cartFloat {
  z-index: 5000;
}
.filterOpen {
  z-index: 7;
}
body {
  position: relative;
  font-weight: normal;
}
body .mainLayout {
  background-color: #fff;
}
.row,
.img {
  max-width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .row {
    width: 100%;
  }
}
.img.noImage {
  max-height: 250px;
}
.row {
  margin-bottom: 20px;
}
.row > .actionsHolder {
  margin-left: auto;
  margin-right: auto;
}
.row.grey {
  background-color: #f5f3f3;
  margin-left: -15px;
  margin-right: -15px;
  padding: 20px 15px;
  max-width: calc(100% + 30px);
}
.row.grey.introMagWrapper {
  max-width: none;
}
.row.bordered {
  padding: 30px 20px;
}
.row.heavyPad {
  padding: 30px 40px;
}
.row.center {
  text-align: center;
}
.row.center > * {
  text-align: center;
}
.row.margin10bottom {
  margin: 0 auto 10px auto;
}
@media screen and (min-width: 1025px) {
  .row {
    max-width: 1541px;
    margin-left: auto;
    margin-right: auto;
  }
  .row > *,
  .row > .actionsHolder {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}
@media screen and (min-width: 1441px) {
  .row > *,
  .row > .actionsHolder {
    width: 80%;
  }
}
@media screen and (min-width: 1920px) {
  .row {
    max-width: 1441px;
  }
  .row.listing,
  .row.prePublished,
  .row.productList {
    max-width: 1920px;
  }
  .row > *,
  .row > .actionsHolder {
    width: 95%;
  }
  .row.bordered {
    padding-right: 40px;
    padding-left: 40px;
  }
  .row.bordered.contact {
    margin-top: 20px;
    margin-bottom: 40px;
    display: inline-block;
    width: auto;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 1024px) {
  .row.homeSectionsWrapper,
  .row.newsletterWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 50px auto 100px auto;
  }
}
.newsletterWrapper {
  display: flex;
  justify-content: center;
}
.newsletterWrapper .littleCheck label {
  color: #000 !important;
}
.homeSectionsWrapper {
  background-color: #f5f3f3;
  max-width: 3000px;
}
@media screen and (min-width: 2500px) {
  .homeSectionsWrapper {
    max-width: 1440px;
  }
}
#disclaimer {
  text-align: center;
}
@media screen and (min-width: 1920px) {
  #disclaimer .row {
    max-width: 1441px;
  }
}
#disclaimer h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
#disclaimer strong {
  font-weight: 900;
}
#disclaimer .text {
  text-align: left;
  column-count: 1;
  column-gap: 40px;
}
@media screen and (min-width: 1025px) {
  #disclaimer .text {
    column-count: 2;
  }
}
@media screen and (max-width: 1024px) {
  #disclaimer .text .link {
    color: #e6004c;
  }
}
#disclaimer .holder {
  margin-bottom: 30px;
}
#disclaimer .holder:last-of-type {
  margin-bottom: 0;
}
#disclaimer .holder h3 {
  margin-left: 0;
}
#disclaimer .holder a {
  color: #e6004c;
  text-decoration: none;
}
#disclaimer .holder ul li {
  padding: 0 0 10px 20px;
}
#disclaimer .holder .between .text,
#disclaimer .holder .between .heavyPad {
  width: calc(50% - 20px);
  margin: 0;
}
#disclaimer .holder .between .heavyPad {
  padding: 20px 40px;
  align-self: center;
}
@media screen and (max-width: 1024px) {
  #disclaimer .holder .between .heavyPad {
    width: auto;
    margin: 20px 0;
  }
}
#disclaimer .holder .between .text {
  column-count: 1;
}
@media screen and (max-width: 1024px) {
  #disclaimer .holder .between .text {
    width: 100%;
  }
  #disclaimer .holder .between .text .link {
    color: #e6004c;
  }
}
@media screen and (max-width: 1024px) {
  #disclaimer .holder .between {
    flex-direction: column;
    justify-content: center;
  }
}
#disclaimer.privacy .text p,
#disclaimer.salesConditions .text p,
#disclaimer.shippingConditions .text p,
#disclaimer.privacy .text li,
#disclaimer.salesConditions .text li,
#disclaimer.shippingConditions .text li {
  font-size: 14px;
}
@media screen and (min-width: 1025px) {
  #disclaimer.privacy .text,
  #disclaimer.salesConditions .text,
  #disclaimer.shippingConditions .text {
    column-count: 1;
  }
}
#disclaimer.avisoLegal .row {
  margin-bottom: 0;
}
#disclaimer.avisoLegal .holder .between .heavyPad {
  width: 100%;
  margin: 20px 0;
}
#disclaimer.avisoLegal .noSidePad a {
  color: #000;
}
#disclaimer.avisoLegal .noSidePad .holder.between {
  margin-bottom: 10px;
}
#disclaimer.avisoLegal .noColumn {
  column-count: 1;
}
#disclaimer.avisoLegal h3 {
  font-size: 13px;
  margin-top: 30px;
}
@media (max-width: 736px) {
  #disclaimer .cookiesWrapper {
    overflow-x: scroll;
  }
}
#disclaimer .cookiesTable {
  width: 100%;
  text-align: left;
}
#disclaimer .cookiesTable thead tr th {
  background-color: #e6004c;
  color: #fff;
  padding: 10px;
  font-size: 15px;
}
#disclaimer .cookiesTable tbody tr td {
  font-size: 13px;
  padding: 10px;
}
#disclaimer .cookiesTable tbody tr + tr td {
  border-top: 1px solid #f5f3f3;
}
#contactPage .mapLinkContainer {
  margin: 10px auto;
  width: 260px;
}
@media (max-width: 1024px) {
  #contactPage .holder {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #contactPage .holder .timeTable {
    margin-top: 20px;
  }
  #contactPage .holder .timeTable h3 {
    text-align: center;
  }
}
p.center {
  text-align: center;
}
.searchBox {
  background-color: transparent;
  padding: 0;
}
.searchBox .field {
  flex: 1 1 100%;
}
.searchBox .field .icon.search {
  left: 20px;
}
.searchBox .field .icon.close {
  cursor: pointer;
}
@media (max-width: 1024px) {
  .searchBox .field .icon.close {
    display: none;
  }
}
.searchBox .field .input {
  padding-top: 3px;
  border-radius: 100px;
  padding: 3.5px 40px 0 45px;
}
.searchBox .button {
  flex: 0 0 auto;
  width: 100px;
  margin-left: 10px;
}
.searchBox .button.inactive {
  color: #525252;
}
.searchBox .button.active {
  color: #e6004c;
}
.searchBox.scrolled {
  background-color: #fff;
}
.searchBox.abc .button {
  width: 100px;
  position: relative;
  pointer-events: all;
}
.searchBox.fullscreen {
  background-color: #f5f3f3;
}
@media screen and (max-width: 1024px) {
  .searchBox.fullscreen {
    padding-left: 0;
    padding-right: 0;
  }
  .searchBox.fullscreen .field {
    max-width: calc(100% - 40px);
  }
  .searchBox.fullscreen .field .icon.close {
    display: flex;
  }
}
@media screen and (min-width: 1025px) {
  .searchBox.fullscreen {
    max-width: 1441px;
  }
  .searchBox.fullscreen .field {
    max-width: 1441px;
  }
  .searchBox.abc .field {
    flex: 0 0 auto;
    max-width: 100%;
    width: calc(990px - 110px);
  }
}
@media screen and (max-width: 1024px) {
  .searchBox {
    padding-left: 0;
    padding-right: 0;
  }
  .searchBox .field {
    max-width: calc(100% - 40px);
  }
}
.hidden {
  opacity: 0;
  pointer-events: none;
}
.hidden:not(.peek) {
  height: 0;
  position: absolute;
  visibility: hidden;
}
.holder {
  display: flex;
  min-height: 50px;
  position: relative;
}
.homeSections {
  margin: 100px auto !important;
  margin: 150px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0;
}
@media screen and (min-width: 1025px) {
  .homeSections {
    width: 100%;
  }
}
@media screen and (min-width: 1441px) {
  .homeSections {
    width: 100%;
  }
}
@media screen and (min-width: 1920px) {
  .homeSections {
    width: 100%;
  }
}
.homeSections .bannerSection {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  padding-top: 50%;
  position: relative;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .homeSections .bannerSection {
    overflow: hidden;
  }
}
.homeSections .bannerSection::after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}
.homeSections .bannerSection .img,
.homeSections .bannerSection p {
  display: block;
  width: 100%;
  position: absolute;
}
.homeSections .bannerSection .img.img,
.homeSections .bannerSection p.img {
  height: 100%;
  z-index: 1;
  object-fit: cover;
  top: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .homeSections .bannerSection .img.img,
  .homeSections .bannerSection p.img {
    -o-object-fit: cover;
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 1025px),
  (-ms-high-contrast: active) and (min-width: 1025px) {
  .homeSections .bannerSection .img.img,
  .homeSections .bannerSection p.img {
    width: auto;
    height: 100%;
  }
}
.homeSections .bannerSection .img.noPad,
.homeSections .bannerSection p.noPad {
  padding-bottom: 20px;
  top: 50%;
  color: #fff;
  z-index: 3;
}
@media screen and (min-width: 320px) and (max-width: 1920px) {
  .homeSections .bannerSection p {
    font-size: 26;
  }
}
@media screen and (min-width: 1025px) {
  .homeSections .bannerSection,
  .homeSections .blank {
    width: calc(33.33% - 20px);
    height: 380px;
    margin: 15px 0;
    padding-top: 310px;
  }
  .homeSections .bannerSection:hover::after,
  .homeSections .blank:hover::after {
    background-color: rgba(230, 0, 76, 0.6);
  }
  .homeSections .bannerSection:hover .img,
  .homeSections .blank:hover .img,
  .homeSections .maxContainer .bannerSection:hover .imgHome {
    opacity: 0.3;
  }
}
.homeSections .maxContainer .bannerSection .imgHome {
  transition: all 300ms ease-out;
}
@media screen and (max-width: 1024px) {
  .homeSections {
    padding: 25px 0;
    margin: 0 !important;
  }
}
.homeSections .maxContainer {
  width: calc(100% - 120px);
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 1025px) {
  .homeSections .maxContainer {
    padding: 0 75px;
  }
}
@media screen and (max-width: 736px) {
  .homeSections .maxContainer {
    width: calc(100% - 60px);
  }
}
@media screen and (max-width: 550px) {
  .homeSections .maxContainer {
    padding: 0 15px;
  }
}
.homeSections .maxContainer .bannerSection {
  padding: 0;
  width: calc(20% - 20px);
  height: auto;
  margin: 10px 0;
}
@media screen and (max-width: 1024px) {
  .homeSections .maxContainer .bannerSection {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 737px) {
  .homeSections .maxContainer .bannerSection {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .homeSections .maxContainer .bannerSection {
    margin: 35px 0;
  }
}
.homeSections .maxContainer .bannerSection img {
  height: auto !important;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .homeSections .maxContainer .bannerSection img {
    height: 150px !important;
  }
}
.homeSections .maxContainer .bannerSection p {
  position: static !important;
  margin-top: 25px;
  color: #000;
  padding: 12.5px 0 10px 0;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}
.homeSections .maxContainer .bannerSection::after {
  background-color: rgba(0, 0, 0, 0);
}
@media screen and (min-width: 1025px) and (min-width: 1920px) {
  .homeSections .bannerSection,
  .homeSections .blank {
    max-width: 330px;
  }
}
.addressData + .actionsHolder .button:first-child {
  margin-right: 10px;
  margin-left: auto;
  text-align: right;
}
.addressData + .actionsHolder .button:first-child .icon {
  margin-right: 0;
}
.link {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit !important;
  text-decoration: none;
  color: #000;
}
.navResponsive .link {
  color: #fff;
}
.navResponsive .menu .link {
  text-transform: uppercase;
  font-size: 20px;
}
.link.button {
  display: block;
}
.link.pink {
  color: #e6004c;
  width: 100%;
}
.link.small {
  color: #e6004c;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .link:hover {
    color: #e6004c;
  }
  .link:hover.small {
    text-decoration: underline;
  }
}
@media screen and (min-width: 1920px) {
  .divider {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}
h4 {
  color: #525252;
}
.listOfAreas {
  align-self: center;
}
@media (min-width: 1025px) {
  .listOfAreas {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 736px) {
  .listOfAreas {
    display: block;
  }
}
.listOfAreas .areaGroup {
  padding: 0 10px 20px 10px;
  display: inline-block;
  width: 100%;
}
.listOfAreas .areaGroup h4 {
  color: #e6004c;
}
.listOfAreas .areaGroup .area {
  padding-top: 15px;
}
.listOfAreas .areaGroup .area .button {
  text-align: left;
}
.listOfAreas .areaGroup .area .button span {
  color: #e6004c;
  margin-left: 5px;
}
.listOfAreas.small {
  padding: 10px;
}
.listOfAreas.small .areaGroup h4 {
  margin-bottom: 5px;
}
.listOfAreas.small .areaGroup .area {
  padding-top: 5px;
  text-align: left;
}
.listOfAreas.small .areaGroup .area .button {
  position: relative;
  padding: 0;
}
.listOfAreas.small .areaGroup .area .button .check {
  position: absolute;
  left: -25px;
  color: #e6004c;
}
.listOfAreas.small .areaGroup .area .button span {
  color: #e6004c;
  margin-left: 5px;
}
@media (max-width: 736px) {
  .listOfAreas:not(.predictiveSearch) {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 20px;
  }
  .listOfAreas.predictiveSearch .areaGroup .area {
    width: 100%;
    text-align: left;
  }
}
@media screen and (min-width: 737px) {
  .listOfAreas:not(.predictiveSearch) {
    column-count: 3;
  }
  .listOfAreas:not(.predictiveSearch).small {
    column-count: 2;
  }
  .listOfAreas.predictiveSearch .areaGroup .area {
    width: 33.33%;
  }
}
@media screen and (min-width: 1025px) {
  .listOfAreas:not(.predictiveSearch) {
    column-count: 4;
    max-width: 1441px;
  }
  .listOfAreas:not(.predictiveSearch).small {
    column-count: 4;
  }
  .listOfAreas:not(.predictiveSearch) .areaGroup .area .button,
  .listOfAreas:not(.predictiveSearch) .areaGroup .area .link {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 16px;
  }
  .listOfAreas.predictiveSearch .areaGroup .area {
    width: 25%;
  }
}
@media screen and (min-width: 1441px) {
  .listOfAreas:not(.predictiveSearch) {
    column-count: 5;
  }
  .listOfAreas.predictiveSearch .areaGroup .area {
    width: 20%;
  }
}
@media screen and (min-width: 1920px) {
  .listOfAreas {
    max-width: 1920px;
  }
  .listOfAreas:not(.predictiveSearch) {
    column-count: 6;
  }
  .listOfAreas.predictiveSearch .areaGroup .area {
    width: 16.6%;
  }
}
.text p {
  padding: 0 20px 10px 20px;
}
.text.noSidePad p {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 1920px) {
  .text {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}
#contact .text {
  margin: 20px auto;
}
@media screen and (max-width: 736px) {
  #contact .holder.between {
    display: block;
  }
}
#contact h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
#contact .heavyPad {
  padding: 20px 40px;
  width: 100%;
  height: 200px;
}
@media screen and (min-width: 737px) {
  #contact .heavyPad {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 736px) {
  #contact .heavyPad {
    margin-bottom: 20px;
  }
  #contact .heavyPad:last-of-type {
    margin-bottom: 0;
  }
}
.contactData {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.contactData .link:hover,
.contactData .link:active {
  color: #cc1451;
}
.contactData .link,
.contactData p {
  display: flex;
  padding: 0;
}
.contactData .link strong,
.contactData p strong {
  color: #000;
  margin-right: 10px;
  font-weight: normal;
}
.contactData .link span,
.contactData p span {
  color: #525252;
  margin-right: 10px;
}
.contactData .link.pink,
.contactData p.pink {
  color: #e6004c;
}
.contactData .link.addressFooter p,
.contactData p.addressFooter p {
  padding: 0;
}
@media (max-width: 1024px) {
  .footerInner .contactData {
    margin: 40px auto;
  }
}
.footerInner .contactData .link,
.footerInner .contactData p {
  color: #fff;
}
.footerInner .contactData .link strong,
.footerInner .contactData p strong,
.footerInner .contactData .link span,
.footerInner .contactData p span {
  color: #fff;
}
.footerInner .contactData .link.pink,
.footerInner .contactData p.pink {
  color: #e6004c;
}
@media screen and (min-width: 1025px) {
  .footerInner .contactData {
    margin-left: 40px;
  }
  .footerInner .contactData .link,
  .footerInner .contactData p {
    color: #000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 1.2em;
  }
  .footerInner .contactData .link strong,
  .footerInner .contactData p strong {
    color: #000;
  }
  .footerInner .contactData .link span,
  .footerInner .contactData p span {
    color: #525252;
  }
}
@media screen and (min-width: 1025px) {
  .collapsibleBlock {
    margin: 0 auto;
    max-width: 1441px;
  }
}
@media screen and (min-width: 1920px) {
  .collapsibleBlock {
    max-width: 1920px;
  }
}
.collapsibleBlock.peeking {
  position: relative;
}
.collapsibleBlock.peeking .collapsibleHeading::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://pics.imgix.net/img/white-fade.png');
  background-size: auto 100%;
  z-index: 999;
}
.bulletTitle {
  text-align: left;
  padding-bottom: 10px;
}
.bulletTitle .collapsibleHeading {
  background-color: #f5f3f3;
  text-align: left;
  padding: 0 10px;
  height: 44px;
  min-height: 44px;
  border: none;
}
.bulletTitle .collapsibleHeading .icon {
  margin-right: 10px;
}
@media screen and (min-width: 1025px) {
  .bulletTitle .collapsibleHeading {
    background-color: transparent;
    padding-left: 0;
  }
}
.bulletTitle.collapsed .collapsibleHeading .icon {
  color: #525252;
}
.bulletTitle .collapsibleContent {
  padding: 20px 0;
}
.bulletTitle .collapsibleContent.hidden {
  display: none;
}
.bulletTitle .collapsibleContent.hidden.peek {
  display: block;
  opacity: 0.4;
  max-height: 100px;
  overflow: hidden;
}
.bulletTitle .collapsibleContent.hidden.peek::after {
  content: '';
}
.bulletTitle .collapsibleContent .link.active {
  color: #e6004c;
}
#account .bulletTitle .collapsibleContent {
  padding: 20px 10px;
}
#account h2 {
  margin-bottom: 10px;
}
.colorPick {
  background-color: #f5f3f3;
  padding: 15px;
  margin-bottom: 20px;
}
.colorPick p {
  color: #525252;
  text-align: left;
}
.colorPick p em {
  color: #000;
  font-style: normal;
  padding: 0;
  font-size: 16px;
}
.colorPick .divider {
  margin: 15px;
  width: auto;
}
.colorPick .buttonGroup .button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: relative;
  margin: 0 10px 15px;
}
.colorPick .buttonGroup .button.active::after,
.colorPick .buttonGroup .button:hover::after {
  content: '';
  display: block;
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 2px solid;
  border-radius: 50%;
  color: inherit;
}
.colorPick .buttonGroup .button.black {
  color: #000;
  background-color: #000;
}
.colorPick .buttonGroup .button.grey {
  color: #9b9b9b;
  background-color: #9b9b9b;
}
.colorPick .buttonGroup .button.cyan {
  color: #004699;
  background-color: #004699;
}
.colorPick .buttonGroup .button.teal {
  color: #368c99;
  background-color: #368c99;
}
.colorPick .buttonGroup .button.turquoise {
  color: #53cfb3;
  background-color: #53cfb3;
}
.colorPick .buttonGroup .button.green {
  color: #4c8410;
  background-color: #4c8410;
}
.colorPick .buttonGroup .button.maroon {
  color: #9a1414;
  background-color: #9a1414;
}
.colorPick .buttonGroup .button.blue {
  color: #7ed8ff;
  background-color: #7ed8ff;
}
.colorPick .buttonGroup .button.yellow {
  color: #e9b15f;
  background-color: #e9b15f;
}
.colorPick .buttonGroup .button.unavailable {
  filter: opacity(0.3) saturate(200%);
  pointer-events: none;
}
.colorPick .buttonGroup .button.unavailable:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  height: 20px;
  line-height: 20px;
  width: 20px;
  text-align: center;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e90b';
  color: #f5f3f3;
}
.colorPick .buttonGroup .button.unavailable:hover {
  filter: none;
}
.colorPick .buttonGroup .button.unavailable:hover:before {
  content: none;
}
@media screen and (min-width: 1025px) {
  .colorPick {
    padding: 20px 30px;
  }
}
.box {
  padding: 40px 20px;
  flex: 1 1 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
.box:last-of-type {
  margin-bottom: 0;
}
.box.third {
  width: 33.33%;
}
@media screen and (min-width: 737px) {
  .box {
    width: 33.33%;
    height: 100%;
    margin-bottom: 0;
    margin-left: 10px;
  }
  .box:first-of-type {
    margin-left: 0;
  }
}
.errorMessage {
  background-color: #f5f3f3;
}
.errorMessage.heavyPad {
  padding-top: 100px;
  padding-bottom: 100px;
}
.errorMessage.heavyPad.error404Axon {
  background-color: #fff;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 1025px) {
  .errorMessage.heavyPad {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .errorMessage.heavyPad.error404Axon {
    padding: 0;
  }
  .errorMessage.heavyPad.error404Axon .error404Img {
    width: 50%;
  }
}
.errorMessage.outOfStock {
  padding: 0 !important;
  width: 100%;
  text-align: left;
}
.errorMessage.outOfStock .textContent {
  color: #606060;
  width: 100%;
  height: 550px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .errorMessage.outOfStock .textContent {
    height: 600px;
  }
}
.errorMessage.outOfStock .textContent .textMenssage {
  position: relative;
  z-index: 15;
  width: 400px;
  margin-top: 50px;
}
@media screen and (max-width: 1100px) {
  .errorMessage.outOfStock .textContent .textMenssage {
    margin-left: 0;
  }
}
@media screen and (max-width: 925px) {
  .errorMessage.outOfStock .textContent .textMenssage {
    margin-top: -15px;
    width: 350px;
  }
}
@media screen and (max-width: 800px) {
  .errorMessage.outOfStock .textContent .textMenssage {
    width: 350px;
    margin-top: 25px;
  }
}
@media screen and (max-width: 700px) {
  .errorMessage.outOfStock .textContent .textMenssage {
    width: 350px;
    margin-top: -50px;
  }
}
@media screen and (max-width: 600px) {
  .errorMessage.outOfStock .textContent .textMenssage {
    padding: 50px 15px 0 15px;
  }
}
.errorMessage.outOfStock .textContent .textMenssage h2 {
  font-family: 'FrutigerLTStd-Bold';
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1.5px solid #606060;
}
.errorMessage.outOfStock .textContent .textMenssage p {
  text-align: left;
  font-family: 'FrutigerLTStd-Light';
  font-size: 1.2rem;
  line-height: 2rem;
}
@media screen and (max-width: 1100px) {
  .errorMessage.outOfStock .textContent .textMenssage p {
    text-align: center;
  }
}
@media screen and (max-width: 925px) {
  .errorMessage.outOfStock .textContent .textMenssage p {
    font-size: 1.2rem;
  }
}
.errorMessage.outOfStock .textContent .textMenssage p:after {
  content: ' ';
  width: 200px;
  height: 200px;
  padding-top: 25px;
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  right: -150px;
  transform: translateX(-50%);
  z-index: 30;
  background-image: url('https://pics.imgix.net/img/lupa404.png');
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 800px) {
  .errorMessage.outOfStock .textContent .textMenssage p:after {
    top: 250px;
  }
}
@media screen and (max-width: 700px) {
  .errorMessage.outOfStock .textContent .textMenssage p:after {
    top: 220px;
  }
}
@media screen and (max-width: 700px) {
  .errorMessage.outOfStock .textContent .textMenssage p:after {
    width: 225px;
    height: 225px;
    top: 350px;
  }
}
.errorMessage.outOfStock .textContent:before {
  content: ' ';
  width: 800px;
  height: 800px;
  padding-top: 25px;
  position: absolute;
  top: 25px;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background-image: url('https://pics.imgix.net/img/404bg4x.png');
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 925px) {
  .errorMessage.outOfStock .textContent:before {
    width: 700px;
    height: 700px;
  }
}
@media screen and (max-width: 800px) {
  .errorMessage.outOfStock .textContent:before {
    width: 600px;
    height: 600px;
    background-image: url('https://pics.imgix.net/img/404bg4xmobile.png');
  }
}
@media screen and (max-width: 700px) {
  .errorMessage.outOfStock .textContent:before {
    width: 500px;
    height: 500px;
  }
}
@media screen and (max-width: 600px) {
  .errorMessage.outOfStock .textContent:before {
    background-image: url('https://pics.imgix.net/img/404bg4xmobilem.png');
    width: 500px;
    height: 800px;
  }
}
.errorMessage.outOfStock img {
  width: 20%;
}
@media screen and (max-width: 1025px) {
  .errorMessage.outOfStock {
    display: block;
    text-align: center;
    padding-top: 60px;
  }
  .errorMessage.outOfStock .textContent {
    margin: 0 auto;
    padding: 0;
  }
  .errorMessage.outOfStock .textContent h2,
  .errorMessage.outOfStock .textContent p {
    text-align: center;
  }
  .errorMessage.outOfStock .textContent h2 {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .errorMessage.outOfStock img {
    margin-top: 2rem;
    width: 30%;
  }
}
@media screen and (max-width: 736px) {
  .errorMessage.outOfStock {
    padding-left: 40px;
    padding-right: 40px;
  }
  .errorMessage.outOfStock .textContent h2 {
    font-size: 1.2rem;
  }
  .errorMessage.outOfStock .textContent p {
    font-size: 1rem;
  }
  .errorMessage.outOfStock img {
    margin-top: 2rem;
    width: 50%;
  }
}
@media screen and (max-width: 736px) and (orientation: landscape) {
  .errorMessage.outOfStock img {
    width: 30%;
  }
}
.timeTable h3 {
  padding: 2.5px;
}
.timeTable p {
  padding: 2.5px;
}
.timeTable p strong,
.timeTable p span {
  flex: 0 0 auto;
  padding-right: 10px;
}
.timeTable p strong {
  width: 9em;
  font-weight: normal;
}
.timeTable p span {
  width: auto;
}
.map {
  width: 100%;
  height: 130px;
  background-image: url('https://pics.imgix.net/img/mapa-dummy.png');
  background-size: 100%;
  background-position: center;
}
@media screen and (min-width: 1025px) {
  .map {
    height: 180px;
  }
}
.address {
  background-color: #faf8f8;
  padding: 30px;
  padding-bottom: 10px;
  position: relative;
  margin-bottom: 10px;
}
.address p.favorite {
  font-family: 'FrutigerLTStd-Roman';
  font-size: 0.8em;
  text-transform: uppercase;
  color: #e6004c;
  letter-spacing: 0.15em;
  margin-bottom: 10px;
}
.address.editing {
  padding-left: 20px;
  padding-right: 20px;
}
.address > .asterisk {
  color: #e6004c;
  position: absolute;
  top: 10px;
  left: 10px;
}
.address .edit {
  color: #e6004c;
}
.address .caps {
  text-transform: uppercase;
}
.address .comment {
  color: #525252;
}
.purchase {
  padding: 10px 0;
}
.purchase .openPurchase {
  width: 100%;
  text-align: left;
  overflow: visible;
}
.purchase .openPurchase .code {
  flex: 1 1 100%;
}
.purchase .openPurchase .code.date,
.purchase .openPurchase .date.date {
  color: #525252;
  padding-right: 10px;
}
.purchase .openPurchase .charge {
  padding-right: 10px;
  font-size: 100%;
}
.purchase .openPurchase .icon {
  margin-right: -5px;
}
.purchase .openPurchase .icon.arrow-select-down {
  color: #e6004c;
}
.purchase .openPurchase .icon.arrow-select-up {
  color: #525252;
}
.purchase .detail > p {
  margin: 5px 0;
  color: #525252;
}
.purchase .detail > p span {
  margin-right: 20px;
}
.bill {
  background-color: #faf8f8;
  padding: 20px 15px 20px 10px;
  margin-bottom: 10px;
}
.bill::after {
  content: '';
  display: block;
  clear: both;
  float: none;
}
.bill > p {
  padding-left: 10px;
}
.bill > p span:first-child {
  flex: 1 1 auto;
}
@media screen and (max-width: 736px) {
  .bill > p {
    justify-content: space-between;
  }
  .bill > p span:first-child {
    flex: 0 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 120px);
  }
  .bill > p span:first-child + span {
    font-size: 10px;
  }
}
.bill > p .link {
  flex: 0 0 auto;
}
.bill > .link ~ p {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.05em;
  padding-left: 70px;
  color: #525252;
}
@media screen and (max-width: 736px) {
  .bill > .link ~ p {
    width: 100%;
    justify-content: flex-start;
    text-align: left;
  }
}
@media screen and (min-width: 737px) {
  .bill > .link ~ p {
    text-align: right;
    justify-content: flex-end;
  }
}
.bill > .link + p {
  text-align: left;
}
.checkedContent .textLowerCase {
  text-transform: initial;
}
.checkedContent h3 .icon {
  margin-right: 10px;
}
.checkedContent:not(.checked) h3 .icon {
  color: #525252;
}
.checkedContent.checked h3 .icon {
  color: #e6004c;
}
.checkedContent .inner {
  padding: 0 10px 30px 30px;
}
.checkedContent.cartObservation {
  margin-bottom: 20px;
}
.checkedContent.cartObservation .inner:first-of-type {
  text-align: center;
}
.checkedContent.cartObservation .inner:first-of-type .button {
  padding-right: 20px;
}
.checkedContent.cartObservation.made {
  padding-top: 30px;
}
.checkedContent.cartObservation.made .inner:first-of-type {
  padding-top: 10px;
}
.checkedContent .forgotPassword {
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: auto;
}
.checkedContent .forgotPassword .link {
  width: auto;
  height: 20px;
}
.introMag {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
}
.introMag > .text {
  flex: 1 1 100%;
}
.introMag > .text p {
  width: 100%;
  text-align: left;
}
.introMag > .button,
.introMag > .row {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 20px;
}
.introMag > .button:last-child,
.introMag > .row:last-child {
  margin-bottom: 0;
}
.introMag > .button.button,
.introMag > .row.button {
  margin-right: 0;
}
@media screen and (min-width: 736px) {
  .introMag > .button,
  .introMag > .row {
    width: calc(50% - 15px);
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1024px) {
  .introMag > .button.button,
  .introMag > .row.button {
    color: #e6004c;
  }
}
@media screen and (min-width: 1025px) {
  .introMag {
    flex-flow: row nowrap;
    justify-content: center;
  }
  .introMag > .text,
  .introMag > .button,
  .introMag > .row {
    flex: 0 0 auto;
    width: 310px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0;
  }
  .introMag > .text.text,
  .introMag > .button.text,
  .introMag > .row.text {
    margin-left: 0;
  }
  .introMag > .text.text p,
  .introMag > .button.text p,
  .introMag > .row.text p {
    padding-left: 0;
  }
  .introMag > .text.button,
  .introMag > .button.button,
  .introMag > .row.button {
    margin-right: 0;
  }
}
.form p.helpUs {
  padding-left: 46px;
  margin-top: 10px;
  letter-spacing: 0.1em;
  font-size: 12px;
}
.form .areas {
  width: 100%;
  padding-left: 20px;
  margin: 20px 0;
  display: flex;
  flex-flow: row wrap;
}
@media screen and (min-width: 737px) {
  .form .areas .field.checkbox.littleCheck {
    flex: 1 1 50%;
  }
}
@media screen and (min-width: 1025px) {
  .form .areas .field.checkbox.littleCheck {
    flex: 1 1 33.33%;
  }
}
.form .noNL {
  width: 100%;
  margin-top: 30px;
  padding-left: 10px;
}
.form .noNL.noMargin {
  margin-top: 0;
}
.form .noNL .checkbox {
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 1025px) {
  .form .noNL .checkbox label {
    white-space: nowrap;
  }
}
.button.etc {
  color: #e6004c;
  border-color: #e6004c;
  height: 30px;
  border-radius: 15px;
  width: auto;
  min-height: 0;
  padding: 0 10px !important;
  display: flex !important;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.button.etc > div {
  height: 3px;
  width: 3px;
  background-color: #e6004c;
  flex: 0 0 auto;
  margin: 2px;
  border-radius: 50%;
}
.button.etc:hover {
  background-color: #e6004c;
}
.button.etc:hover > div {
  background-color: #fff;
}
.loader {
  width: 100%;
  margin: auto;
  text-align: center;
}
.loader > div {
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin: 15px 2px;
  opacity: 0.5;
  background-color: #000;
}
.loader > div.one {
  animation: dot1 1500ms linear infinite 300ms;
}
@-moz-keyframes dot1 {
  30% {
    margin: 15px 2px;
  }
  50% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  70% {
    margin: 15px 2px;
  }
}
@-webkit-keyframes dot1 {
  30% {
    margin: 15px 2px;
  }
  50% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  70% {
    margin: 15px 2px;
  }
}
@-o-keyframes dot1 {
  30% {
    margin: 15px 2px;
  }
  50% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  70% {
    margin: 15px 2px;
  }
}
@keyframes dot1 {
  30% {
    margin: 15px 2px;
  }
  50% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  70% {
    margin: 15px 2px;
  }
}
.loader > div.two {
  animation: dot2 1500ms linear infinite 300ms;
}
@-moz-keyframes dot2 {
  40% {
    margin: 15px 2px;
  }
  60% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  80% {
    margin: 15px 2px;
  }
}
@-webkit-keyframes dot2 {
  40% {
    margin: 15px 2px;
  }
  60% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  80% {
    margin: 15px 2px;
  }
}
@-o-keyframes dot2 {
  40% {
    margin: 15px 2px;
  }
  60% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  80% {
    margin: 15px 2px;
  }
}
@keyframes dot2 {
  40% {
    margin: 15px 2px;
  }
  60% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  80% {
    margin: 15px 2px;
  }
}
.loader > div.three {
  animation: dot3 1500ms linear infinite 300ms;
}
@-moz-keyframes dot3 {
  50% {
    margin: 15px 2px;
  }
  70% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  90% {
    margin: 15px 2px;
  }
}
@-webkit-keyframes dot3 {
  50% {
    margin: 15px 2px;
  }
  70% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  90% {
    margin: 15px 2px;
  }
}
@-o-keyframes dot3 {
  50% {
    margin: 15px 2px;
  }
  70% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  90% {
    margin: 15px 2px;
  }
}
@keyframes dot3 {
  50% {
    margin: 15px 2px;
  }
  70% {
    margin: 10px 2px 20px 2px;
    opacity: 1;
  }
  90% {
    margin: 15px 2px;
  }
}
.loader.cart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .loader.cart {
    top: 102px;
  }
}
.loader.big {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader.superBig {
  background-color: #fff;
  top: 100px;
}
@media screen and (max-width: 1024px) {
  .search .searchBox.abc {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.desistText,
.desistForm {
  flex: 0 0 auto;
  padding-top: 20px;
  width: calc(50% - 10px);
}
@media screen and (max-width: 1024px) {
  .desistText,
  .desistForm {
    width: 100%;
  }
}
.desistForm {
  height: 100%;
}
.desistForm h3,
.desistForm .field {
  width: 100% !important;
}
.desistForm h3.select,
.desistForm .field.select {
  min-height: 50px;
}
.desistForm h3.select .placeholder,
.desistForm .field.select .placeholder {
  border: 2px solid;
  padding-left: 20px;
  height: 50px;
}
.desistForm h3.select .placeholder p,
.desistForm .field.select .placeholder p {
  padding: 0;
  font-size: 16px;
}
.desistForm h3.select .optionHolder,
.desistForm .field.select .optionHolder {
  top: 48px;
  width: 100%;
}
.desistForm .button {
  margin: 20px auto;
}
.desistHolder {
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .desistHolder {
    flex-direction: column;
  }
}
ul.bulleted {
  font-size: 13px;
  list-style: disc;
  max-width: 530px;
  padding-left: 30px;
  margin: 20px auto;
}
ul.bulleted li {
  padding-bottom: 10px;
}
.fadeOut {
  opacity: 0;
  transition: opacity 2.5s;
}
.hiddenModal {
  visibility: hidden;
}
.alternateAddress {
  width: 100%;
  display: block;
  text-align: center;
  margin: 10px auto;
}
.alternateAddress .button.default.rounded {
  padding-right: 20px;
}
.policy {
  font-size: 12px;
  left: 6.666666666666667px;
}
.minimun {
  font-size: 12px;
  margin-top: 10px;
}
.filtersOpened .header,
.filtersOpened .search,
.filtersOpened .mainSection,
.filtersOpened .footer {
  pointer-events: none;
}
.filtersOpened .filterOpen {
  pointer-events: auto;
}
.overflowHidden .search,
.overflowHidden .mainSection,
.overflowHidden .footer {
  pointer-events: none;
}
.overflowHidden .filterOpen {
  pointer-events: auto;
}
.overflowHidden .search,
.overflowHidden .searchBox,
.overflowHidden .footer {
  display: none;
}
.overflowHidden .mainSection {
  height: 100vh;
  padding-top: 0 !important;
}
.overflowHidden .mainSection .pageContent {
  height: 100%;
}
.interestAreaMobileButton .link.button.default.rounded {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 200px;
  white-space: nowrap;
}
.permanentCoupon {
  border: 2px solid #000;
  padding: 20px;
}
.permanentCoupon + .permanentCoupon {
  margin-top: 10px;
}
.permanentCoupon > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.permanentCoupon > div .quantity {
  font-size: 30px;
  font-weight: bold;
}
.permanentCoupon > div .quantity span {
  font-size: 20px;
}
.permanentCoupon > div .date {
  color: #525252;
}
.permanentCoupon .name {
  text-transform: uppercase;
  font-weight: bold;
}
.logoImageBox {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0 30px 0;
}
.logoImageBox .logoAxon {
  width: 12.5%;
}
.pageErrorStyle {
  padding-top: 0 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.pageErrorStyle .img {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 50%;
}
.pageErrorStyle .errorBtt {
  margin-top: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.pageErrorStyle .errorBtt p {
  color: #e6004c;
  position: relative;
}
.pageErrorStyle .errorBtt p::after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 50px;
  border-bottom: 2px solid;
  color: #e6004c;
  top: -7px;
  left: -60px;
}
.pageErrorStyle .errorBtt p::before {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 50px;
  border-bottom: 2px solid;
  color: #e6004c;
  top: -8px;
  left: 112px;
}
.pageErrorStyle .errorBtt:hover p {
  color: #000;
}
.pageErrorStyle .errorBtt:hover p::after {
  color: #000;
}
.pageErrorStyle .errorBtt:hover p::before {
  color: #000;
}
@media screen and (min-width: 1024px) {
  .separatorTop {
    position: relative;
    width: 100%;
    height: 2px;
    border-radius: 10px;
    margin-bottom: 25px;
  }
  .separatorTop::after {
    position: absolute;
    content: ' ';
    height: 3px;
    width: 27.5%;
    background-color: #e6004c;
    border-radius: 100px;
    left: 0;
  }
  .separatorTop::before {
    position: absolute;
    content: ' ';
    height: 3px;
    width: 70%;
    border-radius: 100px;
    background-color: #000;
    right: 0;
  }
  .separatorBottom {
    position: relative;
    width: 100%;
    height: 2px;
    border-radius: 10px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .separatorBottom::after {
    position: absolute;
    content: ' ';
    height: 3px;
    width: 70%;
    border-radius: 100px;
    background-color: #000;
    left: 0;
  }
  .separatorBottom::before {
    position: absolute;
    content: ' ';
    height: 3px;
    width: 27.5%;
    border-radius: 100px;
    background-color: #e6004c;
    right: 0;
  }
}
.plusSold {
  margin: 50px 0;
}
.plusSold.home {
  padding: 0;
  max-width: 1440px;
  width: calc(100% - 120px);
  padding: 0 75px;
}
.plusSold.home h3 {
  position: relative;
  padding: 0;
}
.plusSold.home h3::after {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 27.5%;
  background-color: #e6004c;
  border-radius: 100px;
  left: 0;
  top: 60px;
}
.plusSold.home h3::before {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 70%;
  border-radius: 100px;
  background-color: #000;
  right: 0;
  top: 60px;
}
@media screen and (min-width: 1440px) {
  .plusSold.home {
    padding: 0 110px;
    width: calc(100% - 60px);
    max-width: 1440px;
  }
}
@media screen and (max-width: 1024px) {
  .plusSold {
    width: 100%;
  }
  .plusSold.home {
    padding: 0;
  }
}
.plusSold h3 {
  position: relative;
  font-family: 'FrutigerLTStd-Roman';
  padding: 0 5%;
  margin-bottom: 90px;
  margin-top: 90px;
}
.plusSold .relatedSlider .productBox button.slick-next {
  right: 25px;
}
.plusSold .relatedSlider .productBox button.slick-next::before,
.plusSold .relatedSlider .productBox button.slick-prev::before {
  font-size: 40px;
}
@media screen and (max-width: 1024px) {
  .plusSold .relatedSlider .productBox button.slick-next {
    right: -10px;
  }
  .plusSold .relatedSlider .productBox button.slick-prev {
    left: -10px;
  }
}
.plusSold .relatedSlider .productBox .slideRelated .bottom .title {
  color: #000;
  margin-bottom: 20px;
}
.plusSold .relatedSlider .productBox .slideRelated .bottom .subTitle {
  display: none;
}
.plusSold .relatedSlider .productBox .slideRelated .bottom .price {
  color: #000;
  background: #fff;
  text-align: right;
  font-family: 'FrutigerLTStd-Bold';
}
.pageContent {
  position: relative;
}
@media screen and (min-width: 1920px) {
  .footer .footerInner {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .footer .footerInner {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .footer {
    padding: 0 45px;
  }
}
.fichaProductoBOX .slick-slider .slick-dots {
  bottom: 25px;
}
.navResponsive {
  background-color: #333 !important;
  z-index: 3003;
}
.navResponsive .scrollbarHolder .navWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navResponsive .scrollbarHolder .headerMobileNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.navResponsive .scrollbarHolder .headerMobileNav i {
  font-size: 30px;
  width: 50px;
  height: 30px;
  color: #fff;
}
.navResponsive .scrollbarHolder .headerMobileNav i::before {
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.navResponsive .scrollbarHolder .headerMobileNav a {
  width: 100%;
}
.navResponsive .scrollbarHolder .headerMobileNav a i {
  font-size: 30px;
  width: 50px;
  height: 30px;
}
.navResponsive .scrollbarHolder .headerMobileNav a i::before {
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.navResponsive .menu .link {
  padding: 20px;
}
.navResponsive .divider {
  background-color: #fff;
  opacity: 0.25;
}
.collapsibleBlock.ebooks .collapsibleContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}
.accountEbookItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.accountEbookItem img {
  width: 130px;
}
.accountEbookItem .button {
  margin-top: 20px;
}
.accountEbookItem .button.default.rounded {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 110px;
}
.deliveryTagsWrapper {
  width: 100%;
  margin-top: 10px;
}
.deliveryTagsWrapper .link.tag {
  min-width: 100px;
  text-align: center;
  line-height: 30px;
}
.freeDelivery {
  position: relative;
  height: 50px;
  width: auto;
}
.freeDelivery .conditionsButton {
  background-color: transparent;
  position: absolute;
  bottom: -15px;
  right: 0;
}
.freeDeliveryToast .conditionsButton {
  background-color: transparent;
  position: static;
  bottom: -15px;
}
.freeDelivery .conditionsButton p {
  text-transform: uppercase;
  color: #e6004c;
  font-size: 11px;
  letter-spacing: 1.5px;
  white-space: nowrap;
  position: relative;
}
.freeDeliveryToast .conditionsButton p {
  text-transform: uppercase;
  color: #e6004c;
  font-size: 11px;
  letter-spacing: 1.5px;
  white-space: nowrap;
  position: relative;
}
@media (max-width: 480px) {
  .freeDelivery .conditionsButton p {
    font-size: 12px;
  }
}
.freeDelivery .conditionsButton p:before {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #e6004c;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.2s opacity ease, 0.2s bottom ease;
  pointer-events: none;
}
.freeDelivery .conditionsButton:hover p:before {
  opacity: 1;
  bottom: -5px;
}
.freeDelivery.product .freeDeliveryWrapper {
  position: relative;
}
.freeDelivery.product .freeDeliveryWrapper .conditionsButton {
  right: 34px;
  bottom: 10px;
}
@media (max-width: 480px) {
  .freeDelivery.product .freeDeliveryWrapper .conditionsButton {
    bottom: 4px;
    right: 46px;
  }
}
body,
html {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
body .mainLayout,
html .mainLayout {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
  /* &.fullscreen
		background-color GREY-LIGHT */
}
body .mainLayout .scrollbarHolder,
html .mainLayout .scrollbarHolder {
  height: 100%;
  width: 100%;
  position: relative;
}
body .mainLayout .scrollbarHolder > div,
html .mainLayout .scrollbarHolder > div {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100%;
}
body .mainLayout .scrollbarHolder > div > div:nth-child(3),
html .mainLayout .scrollbarHolder > div > div:nth-child(3) {
  width: 8px !important;
  border-radius: 8px !important;
  right: 4px !important;
  top: 4px !important;
  bottom: 4px !important;
  z-index: 4000;
}
body .mainLayout .scrollbarHolder > div > div:nth-child(3) > div:hover,
html .mainLayout .scrollbarHolder > div > div:nth-child(3) > div:hover,
body .mainLayout .scrollbarHolder > div > div:nth-child(3) > div:focus,
html .mainLayout .scrollbarHolder > div > div:nth-child(3) > div:focus {
  background-color: #e6004c !important;
  cursor: grab !important;
}
body .mainLayout .scrollbarHolder > div > div > div,
html .mainLayout .scrollbarHolder > div > div > div {
  z-index: 100;
}
body .mainLayout .scrollbarHolder > div .header,
html .mainLayout .scrollbarHolder > div .header {
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}
@media screen and (min-width: 1025px) {
  body .mainLayout .scrollbarHolder > div .header,
  html .mainLayout .scrollbarHolder > div .header {
    height: 80px;
  }
}
body .mainLayout .scrollbarHolder > div .searchBox,
html .mainLayout .scrollbarHolder > div .searchBox {
  top: 50px;
  left: 0;
  height: 70px;
  width: 100%;
  z-index: 1000;
}
@media screen and (max-width: 1024px) {
  body .mainLayout .scrollbarHolder > div .searchBox,
  html .mainLayout .scrollbarHolder > div .searchBox {
    margin-top: 50px !important;
  }
}
body .mainLayout .scrollbarHolder > div .searchBox.scrolled,
html .mainLayout .scrollbarHolder > div .searchBox.scrolled {
  height: 70px;
}
@media screen and (min-width: 1025px) {
  body .mainLayout .scrollbarHolder > div .searchBox,
  html .mainLayout .scrollbarHolder > div .searchBox {
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
}
body .mainLayout .scrollbarHolder > div .mainSection,
html .mainLayout .scrollbarHolder > div .mainSection {
  flex: 1;
  min-height: 60vh;
  width: 100%;
  padding: 125px 30px 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media screen and (max-width: 1025px) {
  body .mainLayout .scrollbarHolder > div .mainSection,
  html .mainLayout .scrollbarHolder > div .mainSection {
    padding-top: 25px !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body .mainLayout .scrollbarHolder > div .mainSection,
  html .mainLayout .scrollbarHolder > div .mainSection {
    display: block;
  }
}
body .mainLayout .scrollbarHolder > div .mainSection.areasOpen,
html .mainLayout .scrollbarHolder > div .mainSection.areasOpen {
  height: 100%;
}
@media (max-width: 1024px) {
  body .mainLayout .scrollbarHolder > div .mainSection.areasOpen,
  html .mainLayout .scrollbarHolder > div .mainSection.areasOpen {
    height: 90vh;
  }
  body .mainLayout .scrollbarHolder > div .mainSection.areasOpen .areaGroup:last-of-type,
  html .mainLayout .scrollbarHolder > div .mainSection.areasOpen .areaGroup:last-of-type {
    margin-bottom: 30px;
  }
}
body .mainLayout .scrollbarHolder > div .mainSection.hide,
html .mainLayout .scrollbarHolder > div .mainSection.hide {
  display: none !important;
  pointer-events: none;
}
@media screen and (max-width: 1024px) {
  body .mainLayout .scrollbarHolder > div .mainSection,
  html .mainLayout .scrollbarHolder > div .mainSection {
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
  }
  body .mainLayout .scrollbarHolder > div .mainSection .pageContent:not(#home),
  html .mainLayout .scrollbarHolder > div .mainSection .pageContent:not(#home) {
    padding: 115px 30px 0;
  }
}
@media screen and (max-width: 736px) {
  body .mainLayout .scrollbarHolder > div .mainSection .pageContent:not(#home).newsletterAreas,
  html .mainLayout .scrollbarHolder > div .mainSection .pageContent:not(#home).newsletterAreas {
    padding: 115px 10px 0;
    width: 100vw;
  }
  body .mainLayout .scrollbarHolder > div .mainSection #disclaimer,
  html .mainLayout .scrollbarHolder > div .mainSection #disclaimer {
    padding: 40px 30px 0;
  }
}
@media screen and (min-width: 1025px) {
  body .mainLayout .scrollbarHolder > div .mainSection,
  html .mainLayout .scrollbarHolder > div .mainSection {
    padding: 90px 30px 0;
  }
}
@media screen and (min-width: 1441px) {
  body .mainLayout .scrollbarHolder > div .mainSection,
  html .mainLayout .scrollbarHolder > div .mainSection {
    padding: 90px 60px 0;
  }
}
@media screen and (max-width: 1024px) and (min-width: 1281px) {
  body .mainLayout .scrollbarHolder > div .mainSection:not(#home),
  html .mainLayout .scrollbarHolder > div .mainSection:not(#home) {
    padding: 40px 30px 0;
  }
}
body .mainLayout.ios-bottomBar .scrollbarHolder > div .footerInner,
html .mainLayout.ios-bottomBar .scrollbarHolder > div .footerInner {
  padding-bottom: 44px;
}
body .mainLayout .ridof.buttonMenu,
html .mainLayout .ridof.buttonMenu {
  background-color: transparent;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  z-index: 110;
}
body .mainLayout.sidebar,
html .mainLayout.sidebar {
  left: calc(100vw - 50px);
}
body .mainLayout.sidebar .scrollbarHolder > div .header,
html .mainLayout.sidebar .scrollbarHolder > div .header,
body .mainLayout.sidebar .scrollbarHolder > div .searchBox,
html .mainLayout.sidebar .scrollbarHolder > div .searchBox,
body .mainLayout.sidebar .scrollbarHolder > div .abc,
html .mainLayout.sidebar .scrollbarHolder > div .abc {
  left: calc(100vw - 50px);
}
body .mainLayout.sidebar .ridof.buttonMenu,
html .mainLayout.sidebar .ridof.buttonMenu {
  right: 0;
  left: auto;
  opacity: 0;
  height: 100%;
  top: 0;
}
body .mainLayout.sidebar .filters.active,
html .mainLayout.sidebar .filters.active {
  left: 100%;
  transform: none;
  margin-left: -50px;
}
body .mainLayout.noSearch.noCart .scrollbarHolder > div .mainSection,
html .mainLayout.noSearch.noCart .scrollbarHolder > div .mainSection {
  padding-top: 50px;
}
@media screen and (min-width: 1025px) {
  body .mainLayout.noSearch.noCart .scrollbarHolder > div .mainSection,
  html .mainLayout.noSearch.noCart .scrollbarHolder > div .mainSection {
    padding-top: 90px;
  }
}
body .mainLayout.noSearch.noCart.ios-bottomBar .scrollbarHolder > div .mainSection,
html .mainLayout.noSearch.noCart.ios-bottomBar .scrollbarHolder > div .mainSection {
  min-height: calc(100vh - 70px);
}
.pageContent.listOfResults {
  text-align: center;
}
@media screen and (max-width: 736px) {
  .pageContent.listOfResults > div:nth-child(1) {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  .pageContent.listOfResults > div:nth-child(1) .areasButton {
    min-width: 80%;
    margin: 0;
  }
  .pageContent.listOfResults > div:nth-child(1) .removeButton {
    margin-left: 0 !important;
    top: 0 !important;
  }
}
@media screen and (max-width: 1024px) {
  .pageContent.listOfResults > div:nth-child(1) {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  .pageContent.listOfResults > div:nth-child(1) .areasButton {
    min-width: 80%;
    margin: 0;
  }
  .pageContent.listOfResults > div:nth-child(1) .removeButton {
    margin-left: 0 !important;
    top: 0 !important;
  }
}
.pageContent.listOfResults .libros-mas-vendidos {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageContent.listOfResults .relatedSection.prePublished {
    padding: 0 100px;
  }
  .pageContent.listOfResults .relatedSection.prePublished a {
    width: 100%;
  }
  .pageContent.listOfResults .relatedSection.prePublished a h3 {
    text-align: center;
  }
  .pageContent.listOfResults .relatedSection.prePublished .relatedWrapper {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .pageContent.listOfResults .relatedSection.prePublished {
    padding: 0 100px;
  }
  .pageContent.listOfResults .relatedSection.prePublished a {
    width: 100%;
  }
  .pageContent.listOfResults .relatedSection.prePublished a h3 {
    text-align: center;
  }
  .pageContent.listOfResults .relatedSection.prePublished .relatedWrapper {
    width: 65%;
  }
}
.pageContent.listOfResults .button.default.rounded.removeButton {
  padding: 23px;
  width: 20px;
  height: 20px;
  top: 22px;
  margin-left: 10px;
}
.pageContent.listOfResults .button.default.rounded.removeButton .icon {
  margin-top: -10px;
  right: 14px;
}
.pageContent.listOfResults .prePublished {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  flex-wrap: nowrap;
}
.pageContent.listOfResults .prePublished > a {
  width: 20%;
}
@media screen and (max-width: 1024px) {
  .pageContent.listOfResults .prePublished > a {
    width: 30%;
  }
}
@media screen and (max-width: 736px) {
  .pageContent.listOfResults .prePublished > a {
    width: 100%;
  }
}
@media screen and (min-width: 1920px) {
  .pageContent.listOfResults .prePublished {
    max-width: 1800px;
  }
}
@media screen and (max-width: 736px) {
  .pageContent.listOfResults .prePublished {
    flex-direction: column;
  }
  .pageContent.listOfResults .prePublished .relatedWrapper {
    width: 100%;
  }
}
.pageContent.listOfResults .prePublished .relatedWrapper h3 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}
@media screen and (max-width: 1024px) {
  .pageContent.listOfResults .prePublished .relatedWrapper {
    width: 70%;
  }
}
@media screen and (max-width: 736px) {
  .pageContent.listOfResults .prePublished .relatedWrapper {
    width: 100%;
  }
}
.pageContent.listOfResults .prePublished .productBox.prePublished {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  background-color: #f4f3f4;
  padding-bottom: 10px;
  padding-top: 10px;
}
@media screen and (max-width: 1024px) {
  .pageContent.listOfResults .prePublished .productBox.prePublished h3 {
    text-align: center;
  }
}
@media screen and (max-width: 736px) {
  .pageContent.listOfResults .prePublished .productBox.prePublished {
    margin-right: 0;
    width: 100%;
    margin-bottom: 25px;
  }
  .pageContent.listOfResults .prePublished .productBox.prePublished h3 {
    text-align: center;
    margin-top: 10px;
  }
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent {
  background-color: #f4f3f4;
  padding: 30px 20px;
  display: flex;
  padding-top: 0;
  flex-direction: column;
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent h4 {
  display: -webkit-box;
  line-height: 1.1em;
  max-height: 55px;
  min-height: 55px;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 19px;
  max-width: 800px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
}
.pageContent.listOfResults
  .prePublished
  .productBox
  .prePublishedContent
  h4
  -o-text-overflow
  ellipsis {
  text-overflow: ellipsis;
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent h5 {
  font-weight: normal;
  text-align: left;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
  max-height: 20px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.05em;
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent p {
  text-align: left;
  width: 100%;
  font-size: 22px;
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent .bottom {
  padding: 0;
  width: 100%;
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent .topimportant {
  padding: 20px;
  border: 1px solid #bf802a;
  position: relative;
  margin-bottom: 15px;
  min-height: auto;
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent .topimportant img {
  width: auto;
  object-fit: contain;
  height: 200px;
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent .top {
  padding: 20px;
  border: 1px solid #bf802a;
  position: relative;
  margin-bottom: 15px;
  min-height: auto;
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent .top:before {
  content: 'PRÓXIMAMENTE';
  background: #bf802a;
  padding: 10px;
  top: 10px;
  position: absolute;
  left: 0;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  color: #fff;
}
.pageContent.listOfResults .prePublished .productBox .prePublishedContent .top img {
  width: auto;
  object-fit: contain;
  height: 200px;
}
@media screen and (max-width: 736px) {
  .pageContent.listOfResults .prePublished .productBox .prePublishedContent {
    padding: 5px 20px;
  }
  .pageContent.listOfResults .prePublished .productBox .prePublishedContent .top:before {
    font-size: 0.8rem;
  }
  .pageContent.listOfResults .prePublished .productBox .prePublishedContent .top img {
    height: 250;
  }
  .pageContent.listOfResults .prePublished .productBox .prePublishedContent .bottom {
    position: relative;
    width: 50%;
    margin-left: 10px;
  }
  .pageContent.listOfResults .prePublished .productBox .prePublishedContent .bottom .price {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 450px) {
  .pageContent.listOfResults .prePublished .productBox .prePublishedContent .top img {
    height: 135px;
  }
}
@media screen and (max-width: 736px) {
  .pageContent.listOfResults .prePublished .productBox .prePublishedContent a {
    flex-direction: row;
  }
}
@media screen and (max-width: 1024px) {
  .pageContent {
    overflow-x: hidden;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageContent {
    flex: 1 1 100%;
    padding-top: 105px;
  }
  .pageContent .sliderHolder {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
    max-width: calc(100% + 60px);
  }
}
@media screen and (min-width: 1441px) {
  .pageContent {
    flex: 1 1 100%;
    padding-top: 110px;
  }
  .pageContent .sliderHolder {
    margin-left: -60px;
    margin-right: -60px;
    width: calc(100% + 120px);
    max-width: calc(100% + 120px);
  }
}
.pageContent#filters .form,
.pageContent#contactForm .form {
  flex: 1 1 100%;
}
.pageContent#filters .form .field,
.pageContent#contactForm .form .field {
  flex: 0 0 auto;
}
.pageContent#filters .form .field.textareaHolder,
.pageContent#contactForm .form .field.textareaHolder {
  flex: 1 1 100%;
}
.pageContent#filters .form .field.textareaHolder .textarea,
.pageContent#contactForm .form .field.textareaHolder .textarea {
  flex: 1 1 100%;
}
@media screen and (max-width: 1024px) {
  .pageContent#home {
    margin: 0;
    margin-top: 100px;
  }
  .pageContent#home .sliderHolder.fullscreen {
    margin-top: -40px;
  }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  .pageContent#home.fullscreen {
    margin-top: 0;
  }
}
.pageContent .pageTitleArea {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0 auto;
  padding-top: 115px;
}
.pageContent .proxiHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  width: 100%;
}
.pageContent .proxiHeader.fixed {
  width: calc(100% - 200px);
  position: fixed;
  z-index: 3;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 20px 20px 0 #fff;
}
@media screen and (max-width: 1024px) {
  .pageContent .proxiHeader.fixed {
    width: 100%;
    top: 120px;
  }
}
.pageContent .proxiHeader h1 {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
.pageContent .proxiHeader .pageTitleArea {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0 auto;
  padding-top: 115px;
  margin-bottom: 15px;
}
.pageContent .productList:not(.related) {
  padding-top: 25px;
}
.pageContent .productList:not(.related) .counter {
  padding-top: 80px;
}
@media screen and (min-width: 1025px) {
  .pageContent .productList:not(.related) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.pageContent .productList.paddingList {
  padding-top: 50px;
}
.pageContent .productList.paddingList.fixed {
  padding-top: 250px;
}
.actionsHolder {
  width: 100%;
  flex: 0 0 auto;
  padding: 10px 0 15px 0;
}
.actionsHolder.column {
  flex-direction: column;
}
.actionsHolder.noPad {
  padding: 0 0 0 0;
}
.actionsHolder.justEnd {
  justify-content: flex-end;
}
.actionsHolder.justEnd .button {
  margin-right: 20px;
}
.actionsHolder.justEnd .button:last-child {
  margin-right: 0;
}
.actionsHolder.backFromCart {
  padding-bottom: 30px;
}
.actionsHolder.backFromCart .button:first-child:last-child {
  margin-left: 0;
}
.actionsHolder .button:first-child:last-child {
  margin-left: auto;
}
.actionsHolder .center:first-child:last-child {
  margin: 0 auto;
  padding-right: 20px;
}
@media screen and (min-width: 1920px) {
  .actionsHolder {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}
.navResponsive {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.06);
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  animation: navBarIn 300ms ease;
  z-index: 3003;
}
@media screen and (max-width: 1024px) {
  .navResponsive .navWrapper .link.rounded.default {
    background-color: #cc1451;
    color: #fff;
    border: 0;
    width: 100%;
  }
  .navResponsive .navWrapper .link.rounded.default .icon.close {
    background-color: #a21d48;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    margin-top: 0;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    width: 50px;
  }
  .navResponsive .navWrapper .link.rounded.default .icon.close:before {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}
.navResponsive .navWrapper .link:not(.rounded).active {
  color: #cc1451;
}
@media (min-width: 1025px) {
  .navResponsive #scrollBarNav {
    min-height: 100vh;
  }
}
.navResponsive:before {
  content: '';
  background-color: #333;
  width: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.06;
}
@media screen and (max-width: 1024px) {
  .navResponsive {
    padding: 40px 20px 20px;
  }
  .navResponsive:before {
    content: '';
    background-color: #333;
    width: 10px;
  }
}
.navResponsive .link {
  padding: 10px 20px;
  display: block;
}
.navResponsive .link.rounded {
  padding-top: 16px;
  padding-bottom: 16px;
}
.navResponsive .menu {
  padding: 10px 0;
}
.navResponsive .pinkFill {
  margin: 20px 0;
}
@-moz-keyframes navBarIn {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes navBarIn {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}
@-o-keyframes navBarIn {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}
@keyframes navBarIn {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}
.button.disabled {
  background-color: #a9a9a9 !important;
  border-color: #a9a9a9 !important;
}
.relatedWrapper {
  position: relative;
  margin: 0 auto;
}
@media screen and (min-width: 1920px) {
  .relatedWrapper {
    width: 80%;
  }
}
.iconFont,
[class^='icon-'],
[class*=' icon-'],
.icon,
[class^='icon-']::before,
[class*=' icon-']::before,
.icon::before,
.sliderHolder .slick-prev,
.sliderHolder .slick-next,
.sliderHolder .slick-prev::before,
.sliderHolder .slick-next::before,
.sliderHolder.regular.product .slide .imageWrapper.zoom:hover:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  height: 20px;
  line-height: 20px;
  width: 20px;
  text-align: center;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bigIcon,
[class^='icon-'].cart-add,
[class*=' icon-'].cart-add,
.icon.cart-add,
[class^='icon-'].icon-cart-add,
[class*=' icon-'].icon-cart-add,
.icon.icon-cart-add,
[class^='icon-'].cart-add::before,
[class*=' icon-'].cart-add::before,
.icon.cart-add::before,
[class^='icon-'].icon-cart-add::before,
[class*=' icon-'].icon-cart-add::before,
.icon.icon-cart-add::before,
.sliderHolder .slick-prev.cart-add,
.sliderHolder .slick-next.cart-add,
.sliderHolder .slick-prev.icon-cart-add,
.sliderHolder .slick-next.icon-cart-add,
.sliderHolder .slick-prev.cart-add::before,
.sliderHolder .slick-next.cart-add::before,
.sliderHolder .slick-prev.icon-cart-add::before,
.sliderHolder .slick-next.icon-cart-add::before {
  height: 30px;
  width: 30px;
  font-size: 30px;
  line-height: 30px;
}
@font-face {
  font-family: 'icomoon';
  font-display: swap;
  src: url('fonts/iconmoon/icomoon.eot?472gy1');
  src: url('fonts/iconmoon/icomoon.eot?472gy1#iefix') format('embedded-opentype'),
    url('fonts/iconmoon/icomoon.ttf?472gy1') format('truetype'),
    url('fonts/iconmoon/icomoon.woff?472gy1') format('woff'),
    url('fonts/iconmoon/icomoon.svg?472gy1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='icon-'],
[class*=' icon-'],
.icon,
.sliderHolder .slick-prev,
.sliderHolder .slick-next {
  display: inline-block;
  vertical-align: middle;
}
[class^='icon-'].pink::before,
[class*=' icon-'].pink::before,
.icon.pink::before,
.sliderHolder .slick-prev.pink::before,
.sliderHolder .slick-next.pink::before {
  color: #e6004c;
}
[class^='icon-'].arrow-big-down::before,
[class*=' icon-'].arrow-big-down::before,
.icon.arrow-big-down::before,
.sliderHolder .slick-prev.arrow-big-down::before,
.sliderHolder .slick-next.arrow-big-down::before,
[class^='icon-'].icon-arrow-big-down::before,
[class*=' icon-'].icon-arrow-big-down::before,
.icon.icon-arrow-big-down::before,
.sliderHolder .slick-prev.icon-arrow-big-down::before,
.sliderHolder .slick-next.icon-arrow-big-down::before {
  content: '\e900';
}
[class^='icon-'].calendar::before,
[class*=' icon-'].calendar::before,
.icon.calendar::before,
.sliderHolder .slick-prev.calendar::before,
.sliderHolder .slick-next.calendar::before {
  content: '\e919';
}
[class^='icon-'].bestSellers::before,
[class*=' icon-'].bestSellers::before,
.icon.bestSellers::before,
.sliderHolder .slick-prev.bestSellers::before,
.sliderHolder .slick-next.bestSellers::before {
  content: '\e921';
}
[class^='icon-'].tick::before,
[class*=' icon-'].tick::before,
.icon.tick::before,
.sliderHolder .slick-prev.tick::before,
.sliderHolder .slick-next.tick::before {
  content: '\e924';
}
[class^='icon-'].atention::before,
[class*=' icon-'].atention::before,
.icon.atention::before,
.sliderHolder .slick-prev.atention::before,
.sliderHolder .slick-next.atention::before {
  content: '\e922';
}
[class^='icon-'].ok::before,
[class*=' icon-'].ok::before,
.icon.ok::before,
.sliderHolder .slick-prev.ok::before,
.sliderHolder .slick-next.ok::before {
  content: '\e923';
}
[class^='icon-'].alfabeta::before,
[class*=' icon-'].alfabeta::before,
.icon.alfabeta::before,
.sliderHolder .slick-prev.alfabeta::before,
.sliderHolder .slick-next.alfabeta::before {
  content: '\e918';
}
[class^='icon-'].alfabetaBack::before,
[class*=' icon-'].alfabetaBack::before,
.icon.alfabetaBack::before,
.sliderHolder .slick-prev.alfabetaBack::before,
.sliderHolder .slick-next.alfabetaBack::before {
  content: '\e91f';
}
[class^='icon-'].euro::before,
[class*=' icon-'].euro::before,
.icon.euro::before,
.sliderHolder .slick-prev.euro::before,
.sliderHolder .slick-next.euro::before {
  content: '\e91a';
}
[class^='icon-'].euroHigh::before,
[class*=' icon-'].euroHigh::before,
.icon.euroHigh::before,
.sliderHolder .slick-prev.euroHigh::before,
.sliderHolder .slick-next.euroHigh::before {
  content: '\e920';
}
[class^='icon-'].visited::before,
[class*=' icon-'].visited::before,
.icon.visited::before,
.sliderHolder .slick-prev.visited::before,
.sliderHolder .slick-next.visited::before {
  content: '\e91c';
}
[class^='icon-'].zoomIn::before,
[class*=' icon-'].zoomIn::before,
.icon.zoomIn::before,
.sliderHolder .slick-prev.zoomIn::before,
.sliderHolder .slick-next.zoomIn::before {
  content: '\e91d';
}
[class^='icon-'].zoomOut::before,
[class*=' icon-'].zoomOut::before,
.icon.zoomOut::before,
.sliderHolder .slick-prev.zoomOut::before,
.sliderHolder .slick-next.zoomOut::before {
  content: '\e91e';
}
[class^='icon-'].dir::before,
[class*=' icon-'].dir::before,
.icon.dir::before,
.sliderHolder .slick-prev.dir::before,
.sliderHolder .slick-next.dir::before {
  content: '\e925';
}
[class^='icon-'].lock::before,
[class*=' icon-'].lock::before,
.icon.lock::before,
.sliderHolder .slick-prev.lock::before,
.sliderHolder .slick-next.lock::before {
  content: '\e925';
}
[class^='icon-'].tel::before,
[class*=' icon-'].tel::before,
.icon.tel::before,
.sliderHolder .slick-prev.tel::before,
.sliderHolder .slick-next.tel::before {
  content: '\e926';
}
[class^='icon-'].trash,
[class*=' icon-'].trash,
.icon.trash,
.sliderHolder .slick-prev.trash,
.sliderHolder .slick-next.trash {
  font-size: 14px;
}
[class^='icon-'].trash::before,
[class*=' icon-'].trash::before,
.icon.trash::before,
.sliderHolder .slick-prev.trash::before,
.sliderHolder .slick-next.trash::before {
  content: '\e909';
  font-size: 14px;
}
[class^='icon-'].arrow-select::before,
[class*=' icon-'].arrow-select::before,
.icon.arrow-select::before,
.sliderHolder .slick-prev.arrow-select::before,
.sliderHolder .slick-next.arrow-select::before,
[class^='icon-'].arrow-select-down::before,
[class*=' icon-'].arrow-select-down::before,
.icon.arrow-select-down::before,
.sliderHolder .slick-prev.arrow-select-down::before,
.sliderHolder .slick-next.arrow-select-down::before,
[class^='icon-'].icon-arrow-select-down::before,
[class*=' icon-'].icon-arrow-select-down::before,
.icon.icon-arrow-select-down::before,
.sliderHolder .slick-prev.icon-arrow-select-down::before,
.sliderHolder .slick-next.icon-arrow-select-down::before {
  content: '\e903';
}
[class^='icon-'].arrow-select-right::before,
[class*=' icon-'].arrow-select-right::before,
.icon.arrow-select-right::before,
.sliderHolder .slick-prev.arrow-select-right::before,
.sliderHolder .slick-next.arrow-select-right::before,
[class^='icon-'].arrow-select-next::before,
[class*=' icon-'].arrow-select-next::before,
.icon.arrow-select-next::before,
.sliderHolder .slick-prev.arrow-select-next::before,
.sliderHolder .slick-next.arrow-select-next::before,
[class^='icon-'].icon-arrow-select-right::before,
[class*=' icon-'].icon-arrow-select-right::before,
.icon.icon-arrow-select-right::before,
.sliderHolder .slick-prev.icon-arrow-select-right::before,
.sliderHolder .slick-next.icon-arrow-select-right::before {
  content: '\e905';
}
[class^='icon-'].arrow-select-left::before,
[class*=' icon-'].arrow-select-left::before,
.icon.arrow-select-left::before,
.sliderHolder .slick-prev.arrow-select-left::before,
.sliderHolder .slick-next.arrow-select-left::before,
[class^='icon-'].arrow-select-prev::before,
[class*=' icon-'].arrow-select-prev::before,
.icon.arrow-select-prev::before,
.sliderHolder .slick-prev.arrow-select-prev::before,
.sliderHolder .slick-next.arrow-select-prev::before,
[class^='icon-'].icon-arrow-select-left::before,
[class*=' icon-'].icon-arrow-select-left::before,
.icon.icon-arrow-select-left::before,
.sliderHolder .slick-prev.icon-arrow-select-left::before,
.sliderHolder .slick-next.icon-arrow-select-left::before {
  content: '\e904';
}
[class^='icon-'].arrow-select-up::before,
[class*=' icon-'].arrow-select-up::before,
.icon.arrow-select-up::before,
.sliderHolder .slick-prev.arrow-select-up::before,
.sliderHolder .slick-next.arrow-select-up::before,
[class^='icon-'].icon-arrow-select-up::before,
[class*=' icon-'].icon-arrow-select-up::before,
.icon.icon-arrow-select-up::before,
.sliderHolder .slick-prev.icon-arrow-select-up::before,
.sliderHolder .slick-next.icon-arrow-select-up::before {
  content: '\e906';
}
[class^='icon-'].arrow-right::before,
[class*=' icon-'].arrow-right::before,
.icon.arrow-right::before,
.sliderHolder .slick-prev.arrow-right::before,
.sliderHolder .slick-next.arrow-right::before,
[class^='icon-'].arrow-next::before,
[class*=' icon-'].arrow-next::before,
.icon.arrow-next::before,
.sliderHolder .slick-prev.arrow-next::before,
.sliderHolder .slick-next.arrow-next::before,
[class^='icon-'].icon-arrow-right::before,
[class*=' icon-'].icon-arrow-right::before,
.icon.icon-arrow-right::before,
.sliderHolder .slick-prev.icon-arrow-right::before,
.sliderHolder .slick-next.icon-arrow-right::before {
  content: '\e902';
}
[class^='icon-'].arrow-left::before,
[class*=' icon-'].arrow-left::before,
.icon.arrow-left::before,
.sliderHolder .slick-prev.arrow-left::before,
.sliderHolder .slick-next.arrow-left::before,
[class^='icon-'].arrow-prev::before,
[class*=' icon-'].arrow-prev::before,
.icon.arrow-prev::before,
.sliderHolder .slick-prev.arrow-prev::before,
.sliderHolder .slick-next.arrow-prev::before,
[class^='icon-'].icon-arrow-left::before,
[class*=' icon-'].icon-arrow-left::before,
.icon.icon-arrow-left::before,
.sliderHolder .slick-prev.icon-arrow-left::before,
.sliderHolder .slick-next.icon-arrow-left::before {
  content: '\e901';
}
[class^='icon-'].asterisk::before,
[class*=' icon-'].asterisk::before,
.icon.asterisk::before,
.sliderHolder .slick-prev.asterisk::before,
.sliderHolder .slick-next.asterisk::before,
[class^='icon-'].icon-asterisk::before,
[class*=' icon-'].icon-asterisk::before,
.icon.icon-asterisk::before,
.sliderHolder .slick-prev.icon-asterisk::before,
.sliderHolder .slick-next.icon-asterisk::before {
  content: '\e907';
}
[class^='icon-'].cart::before,
[class*=' icon-'].cart::before,
.icon.cart::before,
.sliderHolder .slick-prev.cart::before,
.sliderHolder .slick-next.cart::before {
  content: '\e917';
}
.icon.cart:hover::before,
.icon.user:hover::before {
  color: black;
  background-color: rgb(230 0 76 / 20%);
  border-radius: 50%;
}
.icon.cart::before,
.icon.user::before {
  font-size: 37px;
}
[class^='icon-'].cart.full::before,
[class*=' icon-'].cart.full::before,
.icon.cart.full::before,
.sliderHolder .slick-prev.cart.full::before,
.sliderHolder .slick-next.cart.full::before {
  content: '\e917';
  color: black;
}
.icon.cart::before {
  position: relative;
  z-index: 10;
}

[class^='icon-'].cart-add::before,
[class*=' icon-'].cart-add::before,
.icon.cart-add::before,
.sliderHolder .slick-prev.cart-add::before,
.sliderHolder .slick-next.cart-add::before,
[class^='icon-'].icon-cart-add::before,
[class*=' icon-'].icon-cart-add::before,
.icon.icon-cart-add::before,
.sliderHolder .slick-prev.icon-cart-add::before,
.sliderHolder .slick-next.icon-cart-add::before {
  content: '\e908';
}
[class^='icon-'].check::before,
[class*=' icon-'].check::before,
.icon.check::before,
.sliderHolder .slick-prev.check::before,
.sliderHolder .slick-next.check::before,
[class^='icon-'].icon-check::before,
[class*=' icon-'].icon-check::before,
.icon.icon-check::before,
.sliderHolder .slick-prev.icon-check::before,
.sliderHolder .slick-next.icon-check::before {
  content: '\e90a';
}
[class^='icon-'].close::before,
[class*=' icon-'].close::before,
.icon.close::before,
.sliderHolder .slick-prev.close::before,
.sliderHolder .slick-next.close::before,
[class^='icon-'].icon-close::before,
[class*=' icon-'].icon-close::before,
.icon.icon-close::before,
.sliderHolder .slick-prev.icon-close::before,
.sliderHolder .slick-next.icon-close::before {
  content: '\e90b';
}
[class^='icon-'].hidden,
[class*=' icon-'].hidden,
.icon.hidden,
.sliderHolder .slick-prev.hidden,
.sliderHolder .slick-next.hidden,
[class^='icon-'].eye-closed,
[class*=' icon-'].eye-closed,
.icon.eye-closed,
.sliderHolder .slick-prev.eye-closed,
.sliderHolder .slick-next.eye-closed,
[class^='icon-'].icon-eye-closed,
[class*=' icon-'].icon-eye-closed,
.icon.icon-eye-closed,
.sliderHolder .slick-prev.icon-eye-closed,
.sliderHolder .slick-next.icon-eye-closed {
  right: 40px !important;
}
[class^='icon-'].hidden::before,
[class*=' icon-'].hidden::before,
.icon.hidden::before,
.sliderHolder .slick-prev.hidden::before,
.sliderHolder .slick-next.hidden::before,
[class^='icon-'].eye-closed::before,
[class*=' icon-'].eye-closed::before,
.icon.eye-closed::before,
.sliderHolder .slick-prev.eye-closed::before,
.sliderHolder .slick-next.eye-closed::before,
[class^='icon-'].icon-eye-closed::before,
[class*=' icon-'].icon-eye-closed::before,
.icon.icon-eye-closed::before,
.sliderHolder .slick-prev.icon-eye-closed::before,
.sliderHolder .slick-next.icon-eye-closed::before {
  content: '\e90c';
}
[class^='icon-'].open,
[class*=' icon-'].open,
.icon.open,
.sliderHolder .slick-prev.open,
.sliderHolder .slick-next.open,
[class^='icon-'].eye-open,
[class*=' icon-'].eye-open,
.icon.eye-open,
.sliderHolder .slick-prev.eye-open,
.sliderHolder .slick-next.eye-open,
[class^='icon-'].icon-eye-open,
[class*=' icon-'].icon-eye-open,
.icon.icon-eye-open,
.sliderHolder .slick-prev.icon-eye-open,
.sliderHolder .slick-next.icon-eye-open {
  right: 40px !important;
}
[class^='icon-'].open::before,
[class*=' icon-'].open::before,
.icon.open::before,
.sliderHolder .slick-prev.open::before,
.sliderHolder .slick-next.open::before,
[class^='icon-'].eye-open::before,
[class*=' icon-'].eye-open::before,
.icon.eye-open::before,
.sliderHolder .slick-prev.eye-open::before,
.sliderHolder .slick-next.eye-open::before,
[class^='icon-'].icon-eye-open::before,
[class*=' icon-'].icon-eye-open::before,
.icon.icon-eye-open::before,
.sliderHolder .slick-prev.icon-eye-open::before,
.sliderHolder .slick-next.icon-eye-open::before {
  content: '\e91b';
}
[class^='icon-'].edit::before,
[class*=' icon-'].edit::before,
.icon.edit::before,
.sliderHolder .slick-prev.edit::before,
.sliderHolder .slick-next.edit::before,
[class^='icon-'].icon-edit::before,
[class*=' icon-'].icon-edit::before,
.icon.icon-edit::before,
.sliderHolder .slick-prev.icon-edit::before,
.sliderHolder .slick-next.icon-edit::before {
  content: '\e90d';
}
[class^='icon-'].filtro::before,
[class*=' icon-'].filtro::before,
.icon.filtro::before,
.sliderHolder .slick-prev.filtro::before,
.sliderHolder .slick-next.filtro::before,
[class^='icon-'].icon-filters::before,
[class*=' icon-'].icon-filters::before,
.icon.icon-filters::before,
.sliderHolder .slick-prev.icon-filters::before,
.sliderHolder .slick-next.icon-filters::before {
  content: '\e90e';
}
[class^='icon-'].fra::before,
[class*=' icon-'].fra::before,
.icon.fra::before,
.sliderHolder .slick-prev.fra::before,
.sliderHolder .slick-next.fra::before,
[class^='icon-'].icon-fra::before,
[class*=' icon-'].icon-fra::before,
.icon.icon-fra::before,
.sliderHolder .slick-prev.icon-fra::before,
.sliderHolder .slick-next.icon-fra::before {
  content: '\e90f';
}
[class^='icon-'].info::before,
[class*=' icon-'].info::before,
.icon.info::before,
.sliderHolder .slick-prev.info::before,
.sliderHolder .slick-next.info::before,
[class^='icon-'].icon-info::before,
[class*=' icon-'].icon-info::before,
.icon.icon-info::before,
.sliderHolder .slick-prev.icon-info::before,
.sliderHolder .slick-next.icon-info::before {
  content: '\e928';
}
[class^='icon-'].search::before,
[class*=' icon-'].search::before,
.icon.search::before,
.sliderHolder .slick-prev.search::before,
.sliderHolder .slick-next.search::before,
[class^='icon-'].icon-search::before,
[class*=' icon-'].icon-search::before,
.icon.icon-search::before,
.sliderHolder .slick-prev.icon-search::before,
.sliderHolder .slick-next.icon-search::before {
  content: '\e910';
}
[class^='icon-'].menu::before,
[class*=' icon-'].menu::before,
.icon.menu::before,
.sliderHolder .slick-prev.menu::before,
.sliderHolder .slick-next.menu::before,
[class^='icon-'].icon-menu::before,
[class*=' icon-'].icon-menu::before,
.icon.icon-menu::before,
.sliderHolder .slick-prev.icon-menu::before,
.sliderHolder .slick-next.icon-menu::before {
  content: '\e911';
}
[class^='icon-'].minus.click,
[class*=' icon-'].minus.click,
.icon.minus.click,
.sliderHolder .slick-prev.minus.click,
.sliderHolder .slick-next.minus.click {
  cursor: pointer;
}
[class^='icon-'].plus.click,
[class*=' icon-'].plus.click,
.icon.plus.click,
.sliderHolder .slick-prev.plus.click,
.sliderHolder .slick-next.plus.click {
  cursor: pointer;
}
[class^='icon-'].close.click,
[class*=' icon-'].close.click,
.icon.close.click,
.sliderHolder .slick-prev.close.click,
.sliderHolder .slick-next.close.click {
  cursor: pointer;
}
[class^='icon-'].minus::before,
[class*=' icon-'].minus::before,
.icon.minus::before,
.sliderHolder .slick-prev.minus::before,
.sliderHolder .slick-next.minus::before,
[class^='icon-'].icon-minus::before,
[class*=' icon-'].icon-minus::before,
.icon.icon-minus::before,
.sliderHolder .slick-prev.icon-minus::before,
.sliderHolder .slick-next.icon-minus::before {
  content: '\e912';
}
[class^='icon-'].plus::before,
[class*=' icon-'].plus::before,
.icon.plus::before,
.sliderHolder .slick-prev.plus::before,
.sliderHolder .slick-next.plus::before,
[class^='icon-'].icon-plus::before,
[class*=' icon-'].icon-plus::before,
.icon.icon-plus::before,
.sliderHolder .slick-prev.icon-plus::before,
.sliderHolder .slick-next.icon-plus::before {
  content: '\e913';
}
[class^='icon-'].bullet-plus,
[class*=' icon-'].bullet-plus,
.icon.bullet-plus,
.sliderHolder .slick-prev.bullet-plus,
.sliderHolder .slick-next.bullet-plus {
  border-radius: 50%;
  position: relative;
}
[class^='icon-'].bullet-plus::before,
[class*=' icon-'].bullet-plus::before,
.icon.bullet-plus::before,
.sliderHolder .slick-prev.bullet-plus::before,
.sliderHolder .slick-next.bullet-plus::before {
  content: '\e913';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
[class^='icon-'].bullet-minus,
[class*=' icon-'].bullet-minus,
.icon.bullet-minus,
.sliderHolder .slick-prev.bullet-minus,
.sliderHolder .slick-next.bullet-minus {
  border-radius: 50%;
  position: relative;
}
[class^='icon-'].bullet-minus::before,
[class*=' icon-'].bullet-minus::before,
.icon.bullet-minus::before,
.sliderHolder .slick-prev.bullet-minus::before,
.sliderHolder .slick-next.bullet-minus::before {
  content: '\e912';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
[class^='icon-'].bullet-empty,
[class*=' icon-'].bullet-empty,
.icon.bullet-empty,
.sliderHolder .slick-prev.bullet-empty,
.sliderHolder .slick-next.bullet-empty,
[class^='icon-'].bullet-check,
[class*=' icon-'].bullet-check,
.icon.bullet-check,
.sliderHolder .slick-prev.bullet-check,
.sliderHolder .slick-next.bullet-check {
  border-radius: 50%;
  position: relative;
}
[class^='icon-'].bullet-empty::before,
[class*=' icon-'].bullet-empty::before,
.icon.bullet-empty::before,
.sliderHolder .slick-prev.bullet-empty::before,
.sliderHolder .slick-next.bullet-empty::before,
[class^='icon-'].bullet-check::before,
[class*=' icon-'].bullet-check::before,
.icon.bullet-check::before,
.sliderHolder .slick-prev.bullet-check::before,
.sliderHolder .slick-next.bullet-check::before {
  content: '\e90a';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
[class^='icon-'].bullet-empty.bullet-empty::before,
[class*=' icon-'].bullet-empty.bullet-empty::before,
.icon.bullet-empty.bullet-empty::before,
.sliderHolder .slick-prev.bullet-empty.bullet-empty::before,
.sliderHolder .slick-next.bullet-empty.bullet-empty::before,
[class^='icon-'].bullet-check.bullet-empty::before,
[class*=' icon-'].bullet-check.bullet-empty::before,
.icon.bullet-check.bullet-empty::before,
.sliderHolder .slick-prev.bullet-check.bullet-empty::before,
.sliderHolder .slick-next.bullet-check.bullet-empty::before {
  color: transparent;
}
[class^='icon-'].refresh::before,
[class*=' icon-'].refresh::before,
.icon.refresh::before,
.sliderHolder .slick-prev.refresh::before,
.sliderHolder .slick-next.refresh::before,
[class^='icon-'].reset::before,
[class*=' icon-'].reset::before,
.icon.reset::before,
.sliderHolder .slick-prev.reset::before,
.sliderHolder .slick-next.reset::before,
[class^='icon-'].icon-reset::before,
[class*=' icon-'].icon-reset::before,
.icon.icon-reset::before,
.sliderHolder .slick-prev.icon-reset::before,
.sliderHolder .slick-next.icon-reset::before {
  content: '\e914';
}
[class^='icon-'].user::before,
[class*=' icon-'].user::before,
.icon.user::before,
.sliderHolder .slick-prev.user::before,
.sliderHolder .slick-next.user::before,
[class^='icon-'].icon-user::before,
[class*=' icon-'].icon-user::before,
.icon.icon-user::before,
.sliderHolder .slick-prev.icon-user::before,
.sliderHolder .slick-next.icon-user::before {
  content: '\e915';
}
@media screen and (min-width: 1025px) {
  [class^='icon-'].footer,
  [class*=' icon-'].footer,
  .icon.footer,
  .sliderHolder .slick-prev.footer,
  .sliderHolder .slick-next.footer {
    border-color: #000;
    border-radius: 50%;
  }
}
[class^='icon-'].tw,
[class*=' icon-'].tw,
.icon.tw,
.sliderHolder .slick-prev.tw,
.sliderHolder .slick-next.tw {
  background-image: url('https://pics.imgix.net/img/icon/twitter.svg');
}
@media screen and (min-width: 1025px) {
  [class^='icon-'].tw,
  [class*=' icon-'].tw,
  .icon.tw,
  .sliderHolder .slick-prev.tw,
  .sliderHolder .slick-next.tw {
    background-image: url('https://pics.imgix.net/img/icon/twitter-pink.svg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
[class^='icon-'].fb,
[class*=' icon-'].fb,
.icon.fb,
.sliderHolder .slick-prev.fb,
.sliderHolder .slick-next.fb {
  background-image: url('https://pics.imgix.net/img/icon/facebook.svg');
}
@media screen and (min-width: 1025px) {
  [class^='icon-'].fb,
  [class*=' icon-'].fb,
  .icon.fb,
  .sliderHolder .slick-prev.fb,
  .sliderHolder .slick-next.fb {
    background-image: url('https://pics.imgix.net/img/icon/facebook-pink.svg');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
[class^='icon-'].in,
[class*=' icon-'].in,
.icon.in,
.sliderHolder .slick-prev.in,
.sliderHolder .slick-next.in {
  background-image: url('https://pics.imgix.net/img/icon/linkedin.svg');
}
@media screen and (min-width: 1025px) {
  [class^='icon-'].in,
  [class*=' icon-'].in,
  .icon.in,
  .sliderHolder .slick-prev.in,
  .sliderHolder .slick-next.in {
    background-image: url('https://pics.imgix.net/img/icon/linkedin-pink.svg');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
[class^='icon-'].pt,
[class*=' icon-'].pt,
.icon.pt,
.sliderHolder .slick-prev.pt,
.sliderHolder .slick-next.pt {
  background-image: url('https://pics.imgix.net/img/icon/pinterest.svg');
}
@media screen and (min-width: 1025px) {
  [class^='icon-'].pt,
  [class*=' icon-'].pt,
  .icon.pt,
  .sliderHolder .slick-prev.pt,
  .sliderHolder .slick-next.pt {
    background-image: url('https://pics.imgix.net/img/icon/pinterest-pink.svg');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
[class^='icon-'].big,
[class*=' icon-'].big,
.icon.big,
.sliderHolder .slick-prev.big,
.sliderHolder .slick-next.big {
  height: 40px;
  width: 40px;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #cc1451;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px 20px 10px;
  border: none;
}
[class^='icon-'].bullet,
[class*=' icon-'].bullet,
.icon.bullet,
.sliderHolder .slick-prev.bullet,
.sliderHolder .slick-next.bullet {
  border-radius: 50%;
}
[class^='icon-'].bulletCheck,
[class*=' icon-'].bulletCheck,
.icon.bulletCheck,
.sliderHolder .slick-prev.bulletCheck,
.sliderHolder .slick-next.bulletCheck {
  border-radius: 50%;
}
@media (max-width: 1024px) {
  .sliderHolder .slick-prev,
  .sliderHolder .slick-next {
    height: 35px;
    width: 35px;
    line-height: 2px;
  }
}
.filter .collapsibleContent > .button::before {
  color: transparent;
  content: '\e90a';
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  transform: translateY(4px);
  height: 20px;
  line-height: 20px;
  width: 20px;
  text-align: center;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.filter .collapsibleContent > .button.active::before {
  color: #e6004c;
}
.geosuggest__input-wrapper::before {
  content: '\e907';
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  transform: translateY(4px);
  height: 20px;
  line-height: 20px;
  width: 20px;
  text-align: center;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header {
  display: flex;
  width: 100%;
  height: 50px;
  position: fixed;
  z-index: 999;
  min-height: 50px;
  background-color: #fff;
}
@media screen and (min-width: 1025px) {
  .header {
    flex-direction: column;
    box-shadow: 0 17.5px 17.5px 0 #fff;
    height: 175px;
    min-height: 175px;
    padding: 0 20px 0 20px;
    padding-bottom: 25px;
  }
}
@media (min-width: 1025px) {
  .header.mobile {
    display: none;
  }
}
.header.fullscreen {
  background-color: #f5f3f3;
  padding-right: 25px;
  box-shadow: none;
  transition: box-shadow 0.001s ease-in-out;
}
.header.fullscreen .headerBottom {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.05s linear;
  pointer-events: none;
}
.header.fullscreen .emptyPositioner {
  display: none;
}
@media screen and (max-width: 1024px) {
  .header.fullscreen {
    padding: 0 25px;
  }
}
.header .areaSearch {
  width: calc(100% - 80px);
  position: absolute;
  max-width: 1440px;
  bottom: 30px;
}
.header .closeAreas {
  position: absolute;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  border-radius: 50%;
  border-color: #e6004c;
}
@media (max-width: 1024px) {
  .header .closeAreas {
    border: none;
  }
}
.header .closeAreas .icon {
  color: #e6004c;
}
@media (max-width: 1024px) {
  .header .closeAreas .icon {
    color: #000;
  }
}
.header .closeAreas:hover {
  background-color: #e6004c;
}
@media (max-width: 1024px) {
  .header .closeAreas:hover {
    background-color: transparent;
  }
}
.header .closeAreas:hover .icon {
  color: #fff;
}
@media (max-width: 1024px) {
  .header .closeAreas:hover .icon {
    color: #e6004c;
  }
}
.header > * {
  flex: 0 0 auto;
}
.header > *.button {
  padding: 15px;
}
.header > *.button a {
  text-decoration: none;
  color: #000;
}
.header > *.link {
  display: flex;
  height: 50px;
  width: 100px;
}
.header > *.link .img {
  display: block;
  margin: 0;
}
.header > *.link.cartLink {
  width: 50px;
  padding: 15px;
}
.header > *.link.cartLink.newCartLink {
  width: 64px;
  padding: 15px;
}
.header > *.link.cartLink .cartCounter {
  width: 50px;
}
.header .headerTop {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 1440px;
  min-height: 100px;
}
input#searchBox {
  min-height: 35px;
  padding-top: 3px;
}
.searchBox .field .icon.search,
.searchBox .field .icon.close {
  top: 8px;
}
.modalCart {
  border: 1.5px solid black;
  border-radius: 4px;
  padding: 10px 0px 0px;
  box-shadow: -1px 2px 3px rgb(0 0 0 / 30%), -2px 4px 6px rgb(0 0 0 / 20%);
  width: 103%;
  max-width: 200px;
  position: absolute;
  top: 76px;
  right: -3px;
  background-color: white;
  z-index: 10;
  animation-delay: 200ms;
  max-height: calc(100vh - 76px);
}
.articleWrapper {
  overflow: auto;
  margin-bottom: 5px;
}
.articleWrapper .items {
  max-height: calc(var(--vh, 1vh) * 100 - 233px);
  overflow: auto;
}
.articleWrapper .item {
  padding: 10px 25px;
  display: flex;
  align-items: center;
}
.articleWrapper .item:not(:last-child) {
  border-bottom: 1px solid #dedbdb;
}
.articleWrapper .item img {
  max-width: 60px;
  margin-right: 20px;
  max-height: 88px;
  min-width: 60px;
  margin-top: 5px;
}
.flex-col-cart {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-around;
}
.itemName {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  margin-top: 3px;
}
.w100 {
  width: 100%;
}
.itemPrice {
  color: black;
  font-weight: 400;
  font-size: 16px;
}
.quantyPill {
  background-color: #e2e2e2;
  border: 0.5px solid #999999;
  border-radius: 11px;
  overflow: hidden;
  max-width: 62px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.quantyBtn {
  background-color: #e2e2e2;
  color: #6a6a6a;
  font-size: 27px;
  line-height: 12px;
  padding: 0px 4px 4px;
}
.plusCard {
  font-size: 21px;
  padding-top: 4px;
}
.quanty {
  padding-top: 2px;
  background-color: #e2e2e2;
}
.flexWrap {
  display: flex;
  justify-content: space-between;
}
.flexWrap .icon.trash::before {
  font-size: 20px;
  margin-top: 2px;
}
.flexWrap .icon.trash:hover::before {
  color: #e6004c;
}
.itemPrice .quantity {
  font-size: 15px;
  font-weight: 100;
}
/* width */
.items::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.items::-webkit-scrollbar-track {
  background: white;
  padding: 2px;
  border-left: 1px solid #dedbdb;
}

/* Handle */
.items::-webkit-scrollbar-thumb {
  background: #dedbdb;
  border-radius: 5px;
}

/* Handle on hover */
.items::-webkit-scrollbar-thumb:hover {
  background: #e6004c;
}
.priceWrapper {
  display: flex;
  justify-content: space-between;
  padding: 18px 25px 13px;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
}
.taxes {
  font-size: 9px;
  font-weight: 100;
  color: rgba(0, 0, 0, 0.9);
  display: block;
}
.buttonWrapper {
  padding: 20px 25px 26px;
  text-align: center;
}
.buttonWrapper .buyButton {
  font-size: 12.5px;
  background-color: white;
  padding: 7px 10px;
  color: #e6004c;
  border-radius: 20px;
  border: 2px solid #e6004c;
}
.buttonWrapper .buyButton:hover {
  background-color: #e6004c;
  color: white;
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@media screen and (min-width: 1025px) {
  .header .headerTop .emptyPositioner {
    width: 250px;
  }
  .header .headerTop .emptyPositioner.large {
    width: 0;
  }
  .header .headerTop .boxSearch {
    width: 0;
    flex: 0;
    -webkit-transition: flex 1s, width 1s;
  }
  .header .headerTop .boxSearch.large {
    width: 100%;
    max-width: 1350px;
    flex: 1;
    margin-left: 20px;
    margin-right: 25px;
    -webkit-transition: flex 1s, width 1s;
  }
  .header .headerTop .boxSearch.large .icon.close {
    margin: 0;
  }
  .header .headerTop .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: flex 1s;
    flex: 1;
  }
  .header .headerTop .logoWrapper.large {
    flex: 0;
    -webkit-transition: flex 1s;
  }
  .header .headerTop .logoHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 150px;
  }
  .header .headerTop .userZone {
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin-top: -5px;
    margin-left: 15px;
    margin-right: 5px;
    width: 250px;
    -webkit-transition: width 1s;
  }
  .header .headerTop .userZone.large {
    width: 100%;
    -webkit-transition: width 1s;
  }
  .header .headerTop .userZone button {
    background: none;
  }
  .header .headerTop .userZone .icon {
    margin-right: 20px;
  }
  .header .headerTop .userZone .icon + .header .headerTop .userZone .icon {
    margin-right: 0;
  }
  .header .headerTop .userZone .icon.active::before {
    color: black;
  }
  .header .headerTop .userZone .iconLink.button {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: -1;
  }
  .header .headerTop .userZone .iconLink.button .icon::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    display: block;
    height: 20px;
    width: 2px;
    border-radius: 2px;
    background-color: #000;
  }
  .header .headerTop .userZone .userLogged {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header .headerTop .userZone .userLogged.active .link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  .header .headerTop .userZone .userLogged.active .link .icon {
    margin-right: 10px;
  }
  .header .headerTop .userZone .userLogged.active .link span {
    color: #e6004c;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 4px;
    max-width: 120px;
  }
  .header .headerTop .userZone .userLogged.active:hover .iconLink.button {
    opacity: 1;
    right: -30px;
    pointer-events: auto;
    z-index: 1;
  }
  .header .headerTop .userZone .userLogged.active:hover .iconLink.button:hover {
    color: #e6004c;
  }
  .header .headerTop .userZone .userLogged.active:hover .iconLink.button:hover .icon::after {
    background-color: #e6004c;
  }
  .header .headerTop .userZone .search.short {
    border-radius: 100px;
    border: 2px solid #000;
    height: 35px;
    margin-right: 20px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
  }
  .header .headerTop .userZone .search.short:hover {
    background-color: rgb(230 0 76 / 20%);
    border: 2px solid #e6004c;
    color: #e6004c;
  }

  .header .headerTop .userZone .search.short .icon {
    margin-right: 0;
    margin-left: 10px;
  }
}
.header .headerBottom {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  background-color: #fff;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.75s linear;
  transition-delay: 0.25s;
}
.header .headerBottom .nav {
  padding: 15px 0 12.5px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}
.header .headerBottom .nav .button {
  left: 0;
  min-height: 36px;
  line-height: 36px;
  color: #e6004c !important;
}
.header .headerBottom .nav .menu {
  flex: 1 1 100%;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}
.header .headerBottom .nav .menu .link {
  text-transform: uppercase;
  padding-right: 30px;
}
.header .headerBottom .nav .menu .link:last-of-type {
  padding-right: 0;
}
.header .headerBottom .nav .menu .link.active,
.header .headerBottom .nav .menu .link:active {
  color: #e6004c;
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .header .nav .menu .link {
    padding-right: 15px;
  }
  .header .nav .menu .link:last-of-type {
    padding-right: 0;
  }
  .header .nav .button.default.rounded {
    padding-right: 40px;
  }
  .header .nav .button.default.rounded .icon {
    right: 15px;
  }
}
@media screen and (min-width: 1441px) {
  .header .logoHolder {
    margin-right: 0;
  }
  .header .nav .menu {
    margin-left: 40px;
  }
  .header .userZone {
    margin-left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .header.hidden {
    opacity: 0;
    pointer-events: none;
  }
  .iconLink.cartLink {
    margin: 5px 20px 0px 0px;
  }
  .iconLink.cartLink.newCartLink {
    margin: 5px 5px 0px 0px;
  }
  .iconLink.cartLink .cartCounter {
    left: 8px !important;
    top: 28px !important;
  }
  .iconLink.cartLink .cartCounter.counterNew {
    top: 28px !important;
    left: 6px !important;
    font-size: 15px !important;
  }
  .modalCart {
    top: 65px;
    max-height: calc(var(--vh, 1vh) * 100 - 65px);
    padding-top: 20px;
  }
}
.iconLink.cartLink {
  position: relative;
}
.iconLink.cartLink .cartCounter {
  position: absolute;
  right: 2px;
  width: 100%;
  top: 13px;
  text-align: center;
  display: block;
  transform: translate(0, -70%);
  font-size: 17px;
  letter-spacing: 0;
  color: #e6004c;
  left: -0.5px;
}
.iconLink.cartLink .cartCounter.counterNew {
  top: 20px;
  left: -0.5px;
}
.icon.cart.full.null ~ .cartCounter {
  padding: 2px 0px 1px;
}
.iconLink.cartLink .cartCounter.active {
  color: #e6004c;
}
@media (max-width: 1024px) {
  .header.fullscreen {
    justify-content: center;
  }
}
@media screen and (min-width: 1281px) {
  .searchBarMobile {
    display: none;
  }
}
.searchBarMobile {
  width: 100%;
  position: fixed;
  top: 50px;
  height: 75px;
  background-color: #fff;
  z-index: 99;
  flex: 1;
  padding-top: 15px;
}
.searchBarMobile.fullscreen {
  background-color: #f5f3f3;
}
.areasButton.button.default.cta {
  display: inline-flex;
  background: transparent;
  border: 2px solid #e6004c;
  justify-content: center;
  align-items: center;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 12px;
  width: auto;
  min-width: 390px;
  margin: 0 auto 30px;
  border-radius: 40px;
  color: #000;
  transition: background-color 0.2s ease;
  padding: 0;
  margin-top: 30px;
  padding: 0 15px;
}
@media (max-width: 1024px) {
  .areasButton.button.default.cta {
    min-width: calc(100% - 20px);
  }
}
.areasButton.button.default.cta .icon {
  position: relative;
  top: auto;
  right: auto;
  margin-bottom: -7px;
}
.areasButton.button.default.cta:hover {
  background-color: #e6004c;
  color: #fff;
}
.button,
.input,
.textarea {
  border-radius: 0;
  box-shadow: none;
  outline: none;
}
.field {
  position: relative;
}
.field .icon {
  position: absolute;
  top: 15px;
  right: 20px;
}
.field .input {
  padding: 7px 40px 0 20px;
}
.field.textareaHolder .textarea {
  resize: none;
}
.button {
  background-color: transparent;
  cursor: pointer;
  padding-top: 0.2em;
}
.button.default.tag {
  min-height: 30px;
}
.button.default.rounded {
  border-radius: 25px;
  width: auto;
  display: inline-block;
  padding-right: 50px;
  position: relative;
}
.button.default.rounded.padding-right-20 {
  padding-right: 20px;
}
.button.default.rounded.backButton {
  border: none;
  min-height: 30px;
  max-height: 30px;
}
.button.default.rounded .icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: 20px;
}
.button.default.rounded .icon.cart-add {
  margin-top: -15px;
}
.button.default.rounded.iconLeft {
  padding-right: 20px;
  padding-left: 50px;
}
.button.default.rounded.iconLeft .icon {
  right: auto;
  left: 20px;
}
@media screen and (max-width: 736px) {
  .button.default.rounded {
    padding-right: 45px;
  }
  .button.default.rounded .icon {
    right: 15px;
  }
  .button.default.rounded.iconLeft {
    padding-left: 45px;
  }
  .button.default.rounded.iconLeft .icon {
    left: 15px;
  }
}
.button.default.rounded.icon {
  width: 50px;
  padding-right: 0;
}
.button.default.rounded.icon .icon {
  right: 13px;
}
.button.default.big {
  padding: 30px 40px;
}
.button.default.pinkFill {
  background-color: #cc1451;
  color: #fff;
  border: none;
}
.button.default.active {
  color: #e6004c;
}
.button.default.cta {
  color: #e6004c;
}
@media screen and (min-width: 1025px) {
  .button.default {
    color: #000;
  }
  .button.default:hover {
    color: #e6004c;
  }
  .button.default.cta {
    color: #e6004c;
    text-align: center;
    padding: 0 50px 0 20px;
  }
  .button.default.cta:hover {
    color: #fff;
    background-color: #e6004c;
  }
}
.newsletter .phase2 .actionsHolder button {
  padding: 0 20px !important;
}
.backButtonWrapper {
  min-height: 30px;
}
.textarea {
  padding: 20px;
}
.input,
.textarea {
  background-color: #faf8f8;
}
.input + label,
.textarea + label {
  position: absolute;
  pointer-events: none;
  top: 2px;
  color: #525252;
  font-size: 12px;
  letter-spacing: 0.1em;
  left: 6.666666666666667px;
  transform: translateY(0);
}
.input:focus,
.textarea:focus,
.input:active,
.textarea:active {
  color: #000;
  background-color: #fff;
}
.input:focus + label,
.textarea:focus + label,
.input:active + label,
.textarea:active + label {
  top: 2px;
  left: 6.666666666666667px;
  color: #000;
  transform: translateY(0);
}
.input:placeholder-shown,
.textarea:placeholder-shown {
  color: #000;
  background-color: #fff;
}
.input:placeholder-shown + label,
.textarea:placeholder-shown + label {
  color: #000;
  top: 25px;
  left: 20px;
  transform: translateY(-50%);
  pointer-events: none;
}
.input:placeholder-shown.textarea + label,
.textarea:placeholder-shown.textarea + label {
  transform: translateY(0);
  top: 10px;
}
.input:placeholder-shown:focus,
.textarea:placeholder-shown:focus,
.input:placeholder-shown:active,
.textarea:placeholder-shown:active {
  background-color: #fff;
}
.input::-webkit-input-placeholder,
.textarea::-webkit-input-placeholder {
  color: #000;
}
.input:-ms-input-placeholder,
.textarea:-ms-input-placeholder {
  color: #000;
}
.input::-moz-placeholder,
.textarea::-moz-placeholder {
  color: #000;
}
.input:-moz-placeholder,
.textarea:-moz-placeholder {
  color: #000;
  opacity: 1;
}
.fullScreen.searchBox .input:focus,
.fullScreen.searchBox .textarea:focus,
.fullScreen.searchBox .input:active,
.fullScreen.searchBox .textarea:active {
  width: calc(100% + 100px);
}
.fullScreen.searchBox .input:focus ~ .icon,
.fullScreen.searchBox .textarea:focus ~ .icon,
.fullScreen.searchBox .input:active ~ .icon,
.fullScreen.searchBox .textarea:active ~ .icon {
  right: -80px;
}
#contactForm.noPage {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
#contactForm.noPage .field {
  flex: 1 1 100%;
  width: 100%;
}
#contactForm.noPage .field .textarea {
  height: 130px;
}
#contactForm.noPage .holder {
  width: 100%;
}
@media screen and (min-width: 1441px) {
  #contactForm.noPage .holder .field {
    margin-left: 10px;
  }
  #contactForm.noPage .holder .field:first-child {
    margin-left: 0;
  }
}
.form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.form.change-password-form {
  justify-content: flex-start;
  gap: 20px;
}
@media screen and (min-width: 1025px) {
  .form {
    margin-left: auto;
    margin-right: auto;
    max-width: 1441px;
  }
}
@media screen and (min-width: 1920px) {
  .form {
    max-width: 1920px;
  }
}
.form .by2 {
  width: 100%;
}
.form .field {
  flex: 1 1 100%;
  margin-bottom: 10px;
}
.form .field.subjectFilled {
  box-shadow: 0 0 5px #fff;
  background-color: #f5f3f3;
}
@media screen and (min-width: 737px) {
  .form .field {
    flex: 0 0 auto;
    width: calc(50% - 5px);
  }
  .form .field.checkbox,
  .form .field.by1 {
    width: 100%;
    flex: 1 1 100%;
  }
  .form .field.by3 {
    width: calc(33.33% - 7px);
    flex: 0 0 auto;
  }
  .form .field.by6 {
    width: 28.333333333333336%;
    flex: 0 0 auto;
  }
  .form .field.per20 {
    width: calc(20% - 5px);
    flex: 0 0 auto;
  }
  .form .field.per40 {
    width: calc(40% - 5px);
    flex: 0 0 auto;
  }
}
.form.blocked .field {
  pointer-events: none;
}
.form.blocked .field .icon {
  display: none;
}
.rounded {
  flex: 0 0 auto;
}
.newsletter {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 0;
}
@media screen and (min-width: 1025px) {
  .newsletter {
    margin: 0;
    width: 100%;
  }
  .newsletter h2 {
    margin-top: 5px;
    flex: 0 0 240px;
  }
}
.newsletter .maxContainer {
  width: calc(100% - 120px);
  min-height: 400px;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 1025px) {
  .newsletter .maxContainer {
    padding: 0 75px;
  }
}
@media screen and (max-width: 736px) {
  .newsletter .maxContainer {
    width: calc(100% - 60px);
    padding: 0;
  }
}
.newsletter .maxContainer .phase1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
}
.newsletter .maxContainer .phase1 .imgNewsletter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}
.newsletter .maxContainer .phase1 .imgNewsletter img {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .newsletter .maxContainer .phase1 .imgNewsletter {
    width: 100%;
  }
  .newsletter .maxContainer .phase1 .imgNewsletter img {
    width: 70%;
  }
}
@media screen and (max-width: 736px) {
  .newsletter .maxContainer .phase1 .imgNewsletter img {
    width: 100%;
  }
}
.newsletter .maxContainer .phase1 .contentNewsletter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .newsletter .maxContainer .phase1 .contentNewsletter {
    height: auto;
    margin-top: 50px;
    width: 100%;
  }
}
.newsletter .maxContainer .phase1 .contentNewsletter h2 {
  line-height: 50px;
  width: 100%;
  text-align: left;
  max-height: 115px;
  border-bottom: 2px solid #000;
  font-size: 2rem;
  margin-bottom: 15px;
}
@media screen and (max-width: 1024px) {
  .newsletter .maxContainer .phase1 .contentNewsletter h2 {
    text-align: center;
  }
}
@media screen and (max-width: 736px) {
  .newsletter .maxContainer .phase1 .contentNewsletter h2 {
    font-size: 1.5rem;
    max-height: 200px;
  }
}
.newsletter .maxContainer .phase1 .contentNewsletter .subText {
  margin-top: 15px;
  font-size: 1.5rem !important;
  margin-bottom: 25px;
  width: 100%;
  font-size: 29px;
  font-weight: lighter;
}
@media screen and (max-width: 1024px) {
  .newsletter .maxContainer .phase1 .contentNewsletter .subText {
    text-align: center;
  }
}
@media screen and (max-width: 736px) {
  .newsletter .maxContainer .phase1 .contentNewsletter .subText {
    font-size: 1rem;
  }
}
.newsletter .maxContainer .phase1 .contentNewsletter .formStyle {
  width: 100%;
  display: flex;
  flex-direction: column !important;
}
.newsletter .maxContainer .phase1 .contentNewsletter .formStyle .field {
  width: 100%;
}
.newsletter .maxContainer .phase1 .contentNewsletter .formStyle button {
  margin-top: 10px;
  text-align: left;
  border-color: #e6004c;
  color: #e6004c;
  width: 170px;
}
@media screen and (max-width: 1024px) {
  .newsletter .maxContainer .phase1 .contentNewsletter .formStyle button {
    top: 1px;
  }
}
.newsletter .maxContainer .phase1 .contentNewsletter .formStyle button:hover {
  text-align: left;
  background-color: #e6004c;
  color: #fff;
}
.newsletter .phase2 {
  border: 2px solid;
  padding: 50px;
}
.newsletter .phase2 label {
  max-width: 70% !important;
}
.newsletter .phase2 .form {
  margin: 30px auto;
}
.newsletter .phase2 .form .areas {
  padding-left: 0;
}
.newsletter .phase2 .button + .button {
  margin-left: 20px;
}
.newsletter .phase2 .conditionsCkeck {
  margin: 10px 0;
}
.newsletter .phase2 .conditionsCkeck i::before {
  background-color: #fff !important;
  border: 1px solid #000;
  border-radius: 100px;
}
.newsletter .phase3 {
  text-align: center;
}
.newsletter .phase3 h2 {
  text-align: center;
  margin-bottom: 30px;
}
.newsletter.edit {
  margin: 0 auto;
  width: 100%;
}
.newsletter.edit .maxContainer {
  padding: 0;
}
@media screen and (max-width: 736px) {
  .newsletter.edit .maxContainer {
    width: 100%;
  }
}
.newsletter.edit h2 {
  flex: 1 0 auto;
}
@media screen and (max-width: 736px) {
  .newsletter.edit h2 {
    text-align: center;
    margin-bottom: 0;
  }
}
.newsletter.edit .divider {
  background-color: #f5f3f3;
}
.newsletter.edit .button {
  line-height: 44px;
}
.newsletter.edit .form > .field {
  margin: 0 auto;
}
.newsletter.edit .form > div p {
  margin: 0 auto;
  text-align: center;
  padding-left: 0;
  margin-bottom: 30px;
}
.newsletter.edit .form > div .email {
  margin-top: 15px;
  margin-bottom: 30px;
  color: #525252;
  font-size: 20px;
}
.newsletter.edit.feedback {
  padding: 0;
}
.newsletter.edit.feedback .maxContainer {
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.newsletter.edit.feedback h2 {
  flex: unset;
  width: 100%;
}
.newsletter.edit.feedback h3 {
  margin-bottom: 20px;
}
.newsletter.edit.feedback .text {
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #000;
}
.newsletter.edit.feedback ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  justify-items: flex-start;
  grid-gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  border: 2px solid #f5f3f3;
  border-radius: 30px;
  padding: 20px 30px;
}
@media screen and (max-width: 736px) {
  .newsletter.edit.feedback ul {
    grid-template-columns: repeat(1, 1fr);
  }
}
.newsletter.edit.feedback ul li {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
}
@media screen and (min-width: 1025px) and (min-width: 1441px) {
  .newsletter {
    width: 80%;
  }
}
@media screen and (min-width: 1025px) and (min-width: 1920px) {
  .newsletter {
    width: 95%;
  }
}
#cancelNewsletter.newsletter .phase1 .form .email {
  margin-top: 15px;
  margin-bottom: 30px;
  color: #525252;
  font-size: 20px;
}
#cancelNewsletter.newsletter .phase1 .form p:not(.email) {
  margin-bottom: 15px;
}
#cancelNewsletter.newsletter .phase1 .form .actions a span {
  margin-top: 13px;
  display: inline-block;
}
#cancelNewsletter.newsletter .phase1 .form .actions .button:not(:last-child) {
  margin-right: 16px;
}
.addressSuggest {
  margin: 30px auto;
}
.geosuggest .geosuggest__input-wrapper {
  margin-bottom: 10px;
  position: relative;
  background-color: #fff;
}
.geosuggest .geosuggest__input-wrapper::before {
  position: absolute;
  top: 10px;
  right: 15px;
}
.geosuggest .geosuggest__input-wrapper .geosuggest__input {
  width: 100%;
  min-height: 50px;
}
.geosuggest__item {
  margin-bottom: 10px;
  padding-left: 20px;
}
.geosuggest__item:hover,
.geosuggest__item.geosuggest__item--active {
  color: #e6004c;
}
.geosuggest__item:last-of-type {
  margin-bottom: 30px;
}
.googleMap {
  margin: 0 auto 20px auto;
  max-width: 1920px;
}
.geosuggest__suggests.geosuggest__suggests--hidden {
  display: none;
}
#account .accountConfig .collapsibleBlock .form {
  justify-content: flex-start;
}
#account .accountConfig .collapsibleBlock .form .field .input {
  background-color: #fff;
}
#account .accountConfig .collapsibleBlock .form .field .icon.hidden {
  display: none;
}
@media screen and (min-width: 737px) {
  #account .accountConfig .collapsibleBlock .form .field {
    width: calc(50% - 10px);
    margin-right: 20px;
  }
  #account .accountConfig .collapsibleBlock .form .field:nth-child(2n + 0) {
    margin-right: 0;
  }
}
.select {
  padding: 0;
  min-height: 35px;
}
.select .placeholder {
  padding: 0 10px 0 15px;
  padding-left: 0;
  width: 100%;
  height: 100%;
  min-height: 46px;
}
.select .placeholder i {
  position: relative;
  align-self: center;
  width: 15px;
  height: 15px;
  font-size: 15px;
  margin-right: 0;
  margin-left: 10px;
  top: -2px;
  left: 0;
}
.select .placeholder i:before {
  transition-delay: 0;
  position: absolute;
  transform: rotate(180deg);
  color: #000;
}
.select:hover {
  border-color: #e6004c;
}
.select:hover p,
.select:hover i:before {
  color: #e6004c;
}
.select .optionHolder {
  display: inline-block;
  position: absolute;
  top: 40px;
  width: auto;
  height: auto;
  padding: 0;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  z-index: 4;
}
.select .optionHolder > .scrollbarHolder {
  min-height: 160px;
}
.select .optionHolder .option {
  padding: 4px 20px;
  color: #000;
  -webkit-text-fill-color: #000;
  width: auto;
  height: 40px;
  cursor: pointer;
}
.select .optionHolder .option:hover {
  background-color: #f5f3f3;
}
.select .optionHolder .option.active {
  background-color: #e6004c;
  color: #fff;
  -webkit-text-fill-color: #fff;
}
.select.folded::after {
  position: absolute;
  pointer-events: none;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  opacity: 0.4;
}
.select.folded:hover::after {
  opacity: 1;
}
.select.folded .placeholder {
  cursor: pointer;
}
.select.folded .placeholder i:before {
  transform: rotate(0);
}
.select.folded .optionHolder {
  display: none;
}
.select.search .optionHolder {
  background-color: #fff;
  top: 33px;
}
.select.search .optionHolder > div {
  color: #000;
}
.select.search .optionHolder > div.active,
.select.search .optionHolder > div:hover {
  color: #fff;
  background-color: #333;
}
.sizePick {
  background-color: #f5f3f3;
  padding: 15px;
  margin-bottom: 20px;
}
.sizePick p {
  color: #525252;
  text-align: left;
}
.sizePick p em {
  color: #000;
  font-style: normal;
  padding: 0;
  font-size: 16px;
}
@media (min-width: 1025px) {
  .sizePick {
    padding: 20px 30px;
  }
}
@media (max-width: 1024px) {
  .sizePick {
    padding: 30px 45px;
  }
}
@media (max-width: 736px) {
  .sizePick {
    padding: 30px 25px;
  }
}
.sizePick .finishWrapper {
  margin-top: 10px;
}
.sizePick .finishWrapper .tag {
  font-size: 11px;
  background-color: transparent;
}
.sizePick .finishWrapper .tag:hover,
.sizePick .finishWrapper .tag .active {
  background-color: #000;
}
.sizePick .form p {
  padding: 0 0 0 10px;
}
.sizePick .form .field {
  flex-direction: row-reverse;
  margin: 0;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .sizePick .form .field {
    max-width: 400px;
  }
}
@media (max-width: 736px) {
  .sizePick .form .field {
    flex-direction: column-reverse;
  }
}
.sizePick .form .field label {
  color: #525252;
}
@media (min-width: 737px) {
  .sizePick .form .field label {
    margin-right: 20px;
  }
}
@media (max-width: 736px) {
  .sizePick .form .field label {
    margin-bottom: 20px;
  }
}
.sizePick .form .field .select {
  width: 100%;
  position: relative;
}
.sizePick .form .field .select .placeholder .icon:before {
  position: relative;
}
@media (max-width: 736px) {
  .sizePick .form .field .select .optionHolder {
    width: calc(100% + 4px);
    left: -2px;
  }
}
.sizePick .form .field .select .optionHolder .option.unavailable {
  color: #525252;
  -webkit-text-fill-color: #525252;
  background-color: #f5f3f3;
}
.field.checkbox {
  position: relative;
  height: auto;
  cursor: pointer;
}
.field.checkbox label {
  color: rgba(0, 0, 0, 0.5);
  pointer-events: all;
  position: relative;
  cursor: pointer;
  max-width: 99%;
  padding-left: 30px;
  display: block;
  top: 0 left 0;
}
.field.checkbox .icon {
  position: absolute;
  top: 0;
  left: 10px;
}
.field.checkbox .icon::after {
  background-color: #000;
  opacity: 0;
}
.field.checkbox .input {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 100%;
  opacity: 0;
}
.field.checkbox .input:checked ~ label {
  color: #000;
}
.field.checkbox .input:checked ~ .icon::after {
  opacity: 1;
  background-color: #e6004c;
}
.field.checkbox.littleCheck label {
  padding-left: 20px;
}
.field.checkbox.littleCheck .icon {
  left: 0;
  border: none;
}
.field.checkbox.littleCheck .icon::before {
  position: relative;
  display: inline-block;
  content: '\e90a';
  color: transparent;
  background-color: #f5f3f3;
  height: 20px;
  width: 20px;
}
.field.checkbox.littleCheck .icon::after {
  background-color: transparent;
  color: #e6004c;
  top: 2px;
  left: 2px;
  content: '\e90a';
}
.field.checkbox.littleCheck .input:checked ~ .icon::before {
  height: 20px;
  width: 20px;
}
.field.checkbox.littleCheck .input:checked ~ .icon::after {
  background-color: transparent;
  height: 20px;
  width: 20px;
}
.field.checkbox.toggle label {
  padding-left: 50px;
}
.field.checkbox.toggle .input {
  width: 50px;
}
.field.checkbox.toggle .input ~ .icon {
  color: #525252;
  width: 35px;
  border-radius: 10px;
}
.field.checkbox.toggle .input ~ .icon::after {
  height: 12px;
  width: 12px;
  background-color: #b3b1b1;
  transform: translateY(-6px);
  left: 2px;
  opacity: 1;
}
.field.checkbox.toggle .input:checked ~ .icon {
  width: 35px;
  border-radius: 10px;
}
.field.checkbox.toggle .input:checked ~ .icon::after {
  left: 17px;
  background-color: #e6004c;
}
.wrapRange {
  flex-basis: 90%;
}
@media (max-width: 1024px) {
  .wrapRange {
    flex-basis: 100%;
    margin-top: 10px;
  }
}
.form .select {
  min-height: 50px;
}
.form .select .placeholder p {
  font-size: 16px;
  padding: 13px;
}
.form .select .optionHolder {
  top: 48px;
  left: 0;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
}
.form .select .optionHolder .option:hover {
  background-color: #f5f3f3;
}
.containerLogin {
  padding: 25px 30px 0 30px;
  width: 100%;
  height: 100%;
  min-height: 310px;
  display: flex;
  background-color: #fff;
}
.forgot-password {
  display: -webkit-inline-box;
  margin-left: auto;
}
.btn-link {
  color: #e6004c;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit !important;
  text-decoration: none;
}
.btn-link:hover {
  text-decoration: underline;
}
.txt-gray {
  color: #525252;
}
.mt-5 {
  margin-top: 5em;
}
.close {
  transform: scale(1.7);
}
.footer {
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1rem;
}
@media screen and (min-width: 1025px) {
  .footer {
    width: calc(100% - 120px);
    max-width: 1440px;
  }
  .footer .contactLink.phone {
    pointer-events: none;
  }
  .footer .contactLink:hover {
    color: #e6004c;
  }
  .footer .contactLink.spacer {
    margin-bottom: 3px;
  }
}
.footerInner {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
}
@media (max-width: 1024px) {
  .footerInner {
    padding: 20px 0 80px;
  }
}
@media (min-width: 1025px) {
  .footerInner {
    margin: 70px auto 0;
    padding: 20px 60px 80px;
  }
}
.footerInner.expanded {
  background-color: #b3b1b1;
}
.footerInner.expanded .logo {
  margin-bottom: 0;
}
.footerInner .logo {
  display: block;
  width: 100%;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.footerInner .socialMedia {
  margin: 0 auto;
}
.footerInner .warranties {
  flex: 1 1 100%;
}
.footerInner .warranties > .icon {
  display: inline-block;
  margin: 20px auto;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  height: 30px;
  width: 30px;
  padding: 0;
}
.footerInner .warranties .warranty {
  padding-top: 5px;
  text-transform: uppercase;
}
.footerInner .copyRight {
  text-align: center;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .footerInner .copyRight {
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  .footerInner {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    background-color: #fff;
    color: #000;
    text-align: left;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footerInner > .divider {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .footerInner .warranties {
    flex: 0 0 auto;
  }
  .footerInner .warranties .warranty {
    display: block;
  }
  .footerInner .warranties .warranty .icon {
    margin-right: 5px;
    margin-top: -2px;
  }
  .footerInner .socialMedia .icon {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    padding: 0;
  }
  .footerInner .copyRight {
    width: 100%;
    margin-bottom: 60px;
  }
  .footerInner .copyRight :first-child {
    flex: 1;
  }
}
@media screen and (max-width: 1024px) {
  .footerInner {
    flex-direction: column;
  }
  .footerInner .copyRight :last-child {
    margin-top: 5px;
    font-size: 11px;
  }
  .footerInner .divider {
    width: 280px;
  }
  .footerInner.expanded .divider {
    background-color: #fff;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active) {
  .footerInner :first-child {
    flex: 1 0 auto;
  }
}
@media screen and (min-width: 1025px) and (min-width: 1281px) {
  .footerInner {
    width: 95%;
  }
  .footerInner .contactData {
    column-count: 2;
    flex: auto;
  }
  .footerInner .warranties {
    column-count: 1;
    flex: auto;
  }
}
@media screen and (min-width: 1025px) and (min-width: 1660px) {
  .footerInner .warranties {
    column-count: 1;
  }
}
.addressFooter {
  max-width: 280px;
  text-align: left;
}
.paymentMethod {
  flex: 1 1 100%;
}
.paymentMethod .img {
  height: 20px;
  width: auto;
  margin-right: 20px;
}
.paymentMethod .img.paypal {
  height: 18px;
}
.paymentMethod .img.visa {
  height: 13px;
  margin-bottom: 2px;
}
.paymentMethod .img.american {
  height: 30px;
  margin-right: 0;
  margin-bottom: 2px;
}
@media screen and (min-width: 1025px) {
  .paymentMethod {
    flex: 1 1 auto;
    padding: 0 20px;
  }
}
.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}
.slick-dots li button::before,
.slick-next::before,
.slick-prev::before {
  font-family: 40px;
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-next,
.slick-prev {
  z-index: 2;
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.slick-next:focus,
.slick-prev:focus,
.slick-next:hover,
.slick-prev:hover {
  color: transparent;
  outline: 0;
}
.slick-next:focus::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-prev:hover::before {
  opacity: 1;
}
.slick-next.slick-disabled::before,
.slick-prev.slick-disabled::before {
  opacity: 1;
}
.slick-next::before,
.slick-prev::before {
  font-size: 40px;
  line-height: 1;
  opacity: 1;
  color: #fff;
}
.slick-next.slick-prev,
.slick-prev.slick-prev {
  left: 0;
}
[dir='rtl'] .slick-next.slick-prev,
[dir='rtl'] .slick-prev.slick-prev {
  right: 0;
  left: auto;
}
.slick-next.slick-prev::before,
.slick-prev.slick-prev::before {
  content: '\e904';
  color: #000;
}
.slick-next.slick-next::before,
.slick-prev.slick-next::before,
[dir='rtl'] .slick-next.slick-prev::before,
[dir='rtl'] .slick-prev.slick-prev::before {
  content: '\e905';
  color: #000;
}
.slick-next.slick-next,
.slick-prev.slick-next {
  right: 0;
}
[dir='rtl'] .slick-next,
[dir='rtl'] .slick-prev {
  right: auto;
  left: 0;
}
[dir='rtl'] .slick-next::before,
[dir='rtl'] .slick-prev::before {
  content: '\e905';
  color: #000;
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  bottom: 65px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus::before,
.slick-dots li button:hover::before {
  opacity: 1;
}
.slick-dots li button::before {
  background-color: #000;
  opacity: 0;
}
.slick-dots li.slick-active button::before {
  background-color: #e6004c;
  opacity: 1;
}
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-slider .slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-slider .slick-list:focus {
  outline: 0;
}
.slick-slider .slick-list.dragging {
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-slider .slick-track {
  top: 0;
  left: 0;
  height: 100%;
}
.slick-slider .slick-track::before,
.slick-slider .slick-track::after {
  display: table;
  content: '';
}
.slick-slider .slick-track::after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.sliderHolder {
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 0;
  min-width: 0;
  overflow-x: hidden;
  width: calc(100% + 40px);
  max-width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  background-color: #f5f3f3;
  margin-bottom: 20px;
  overflow: hidden;
}
@media (max-width: 1025px) {
  .sliderHolder {
    flex-direction: column;
    justify-content: center;
    height: 500px;
  }
}
@media (max-width: 736px) {
  .sliderHolder {
    height: 600px;
  }
}
.sliderHolder .productBox {
  height: auto;
  width: 80%;
}
.sliderHolder .productBox.notBanner {
  width: 100%;
}
@media (max-width: 1025px) {
  .sliderHolder .productBox {
    width: 100%;
  }
}
.sliderHolder .imgBox {
  height: auto;
  width: 20%;
}
.sliderHolder .imgBox.hide {
  display: none;
}
@media (max-width: 1025px) {
  .sliderHolder .imgBox {
    width: 100%;
    padding-bottom: 60px;
  }
}
@media (max-width: 736px) {
  .sliderHolder .imgBox {
    padding-bottom: 80px;
  }
}
.sliderHolder .productBox.fichaProductoBOX {
  width: 100%;
}
.sliderHolder .productBox.fichaProductoIMG {
  width: 0;
}
.sliderHolder.relatedSlider {
  width: 100%;
}
.sliderHolder.relatedSlider:not(.fullscreen) .slick-list {
  height: 100%;
}
.sliderHolder .slide {
  background-color: #f5f3f3;
}
.sliderHolder .slide .slideInner {
  padding-bottom: 40px;
}
.sliderHolder.fullscreen {
  background-color: #f5f3f3;
  margin-top: -90px;
}
.sliderHolder.fullscreen.notImgBox .productBox {
  width: 100%;
}
.sliderHolder.fullscreen.notImgBox .imgBox {
  display: none;
}
.sliderHolder.fullscreen .slide .slideInner {
  padding-top: 70px;
}
@media screen and (max-height: 400px) {
  .sliderHolder.fullscreen {
    height: 350px;
    max-height: 350px;
  }
  .sliderHolder.fullscreen .slide {
    height: 350px;
  }
  .sliderHolder.fullscreen .slide .bottom {
    padding: 0 10px;
  }
}
@media screen and (min-height: 700px) {
  .sliderHolder.fullscreen {
    height: 600px;
    max-height: 600px;
  }
  .sliderHolder.fullscreen .slide {
    height: 600px;
  }
}
@media screen and (min-height: 900px) {
  .sliderHolder.fullscreen {
    height: 750px;
    max-height: 750px;
  }
  .sliderHolder.fullscreen .slide {
    height: 750px;
  }
}
@media screen and (min-height: 400px) and (max-height: 700px) {
  .sliderHolder.fullscreen {
    height: 550px;
    max-height: 550px;
  }
  .sliderHolder.fullscreen .slide {
    height: 550px;
  }
}
@media screen and (max-height: 415px) and (max-width: 823px) {
  .sliderHolder.fullscreen {
    height: 470px;
    max-height: 480px;
  }
  .sliderHolder.fullscreen .slide {
    height: 480px;
  }
}
@media screen and (max-height: 411px) and (max-width: 731px) {
  .sliderHolder.fullscreen {
    height: 330px;
    max-height: 330px;
  }
  .sliderHolder.fullscreen .slide {
    height: 330px;
    max-height: 330px;
  }
}
@media screen and (min-height: 340px) and (max-width: 568px) {
  .sliderHolder.fullscreen {
    height: 620px;
    min-height: 620px;
  }
  .sliderHolder.fullscreen .slide {
    height: 620px;
    min-height: 620px;
  }
}
@media (max-width: 736px) {
  .sliderHolder.regular {
    height: 550px;
  }
  .sliderHolder.regular:not(.comingSoon) .comingSoonBanner {
    display: none;
  }
  .sliderHolder.regular > .comingSoonBanner.image {
    display: flex;
  }
}
@media (min-width: 737px) {
  .sliderHolder.regular {
    height: 300px;
  }
}
@media (min-width: 1025px) {
  .sliderHolder.regular .slide:not(.slideRelated) {
    height: 300px;
  }
}
.sliderHolder.regular.product {
  background-color: transparent;
  height: auto;
  margin: 0 auto;
  width: 100%;
}
.sliderHolder.regular.product .slick-slider {
  height: auto;
}
.sliderHolder.regular.product .slick-slider .slick-list {
  height: 430px;
}
.sliderHolder.regular.product .slick-slider .slick-dots {
  position: relative;
  text-align: left;
}
.sliderHolder.regular.product .slick-slider .slick-dots li {
  height: 50px;
  margin: 0 10px 0 0;
  width: 50px;
  border: 1px solid #f5f3f3;
  border-radius: 0;
}
.sliderHolder.regular.product .slick-slider .slick-dots li.slick-active {
  border: 1px solid #b3b1b1;
}
.sliderHolder.regular.product .slick-slider .slick-dots li img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 5px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sliderHolder.regular.product .slick-slider .slick-dots li img {
    width: auto;
  }
}
.sliderHolder.regular.product .slide {
  background-color: transparent;
  height: 400px;
  padding: 10px;
  width: 200px !important;
  display: flex !important;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
@media (min-width: 320px) {
  .sliderHolder.regular.product .slide {
    width: 300px !important;
  }
}
@media (min-width: 480px) {
  .sliderHolder.regular.product .slide {
    width: 350px !important;
  }
}
@media (min-width: 737px) {
  .sliderHolder.regular.product .slide {
    width: 270px !important;
  }
}
@media (min-width: 1025px) {
  .sliderHolder.regular.product .slide {
    width: 350px !important;
  }
}
@media (min-width: 1281px) {
  .sliderHolder.regular.product .slide {
    width: 400px !important;
  }
}
.sliderHolder.regular.product .slide .imageWrapper {
  width: 100%;
  height: 100%;
}
.sliderHolder.regular.product .slide .imageWrapper.zoom > div {
  height: 100% !important;
}
@media (min-width: 737px) {
  .sliderHolder.regular.product .slide .imageWrapper {
    border: thin solid #f5f3f3;
  }
}
@media (min-width: 1025px) {
  .sliderHolder.regular.product .slide .imageWrapper.zoom:hover {
    position: relative;
    cursor: pointer;
    border: none;
  }
  .sliderHolder.regular.product .slide .imageWrapper.zoom:hover:after,
  .sliderHolder.regular.product .slide .imageWrapper.zoom:hover:before {
    pointer-events: none;
  }
  .sliderHolder.regular.product .slide .imageWrapper.zoom:hover:after {
    content: '';
    position: absolute;
    background-color: #e6004c;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    z-index: 1;
    top: 0;
    left: 0;
  }
  .sliderHolder.regular.product .slide .imageWrapper.zoom:hover:before {
    content: '\e91d';
    position: absolute;
    font-size: 50px;
    z-index: 2;
    color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sliderHolder.regular.product .slide .img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 20px !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sliderHolder.regular.product .slide .img {
    width: auto;
  }
}
.sliderHolder.productsBanner .slick-arrow {
  height: 100%;
  background-color: #f00 !important;
}
.sliderHolder.productsBanner .slick-arrow:before {
  font-size: 35px;
}
.sliderHolder.productsBanner .slick-arrow.slick-prev {
  left: 20px;
}
.sliderHolder.productsBanner .slick-arrow.slick-next {
  right: 20px;
}
.sliderHolder.productsBanner .slick-arrow:hover {
  background-color: rgba(230, 0, 76, 0.2);
}
.sliderHolder .icon {
  top: 10px;
  right: 10px;
  position: absolute;
  z-index: 1;
}
@media (max-width: 1024px) {
  .sliderHolder .icon.close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    height: 30px;
    width: 30px;
  }
}
.sliderHolder .productPreview {
  padding-left: 40px;
  padding-right: 40px;
}
.abc .slick-next,
.abc .slick-prev {
  height: auto;
  top: 0;
  bottom: 0;
  transform: none;
}
.abc .slick-next.slick-prev,
.abc .slick-prev.slick-prev {
  background-color: transparent;
  background-image: url('https://pics.imgix.net/img/white-fade-left.png');
  left: 0;
}
[dir='rtl'] .abc .slick-next.slick-prev,
[dir='rtl'] .abc .slick-prev.slick-prev {
  right: 0;
}
.abc .slick-next.slick-next,
.abc .slick-prev.slick-next {
  background-color: transparent;
  background-image: url('https://pics.imgix.net/img/white-fade-right.png');
  right: 0;
}
[dir='rtl'] .abc .slick-next,
[dir='rtl'] .abc .slick-prev {
  left: 0;
}
.promoPreview {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.promoPreview .top {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.promoPreview .top.withOnlyImage {
  width: 100%;
}
.promoPreview .top .onlyImage {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.promoPreview .top a.linkImg {
  display: block;
  width: inherit;
  height: inherit;
  height: 100%;
}
.promoPreview .top .img {
  flex: 0 0 auto;
  width: auto;
  margin: auto;
  object-fit: contain;
}
@media (orientation: landscape) {
  .promoPreview .top .img {
    height: 100%;
  }
}
@media screen and (orientation: portrait) and (max-width: 736px) {
  .promoPreview .top .img {
    flex: 0;
    height: 100%;
    width: auto;
  }
}
.promoPreview .bottom {
  flex: 0 0 auto;
  height: auto;
  text-align: left;
  padding: 0 20px;
  width: 100%;
  min-height: 120px;
}
@media (orientation: landscape) {
  .promoPreview .bottom {
    flex: 1 1 auto;
  }
}
.promoPreview .bottom h3 {
  font-size: 26px;
  width: 100%;
  margin-bottom: 0;
  text-transform: none;
}
.promoPreview .bottom p {
  margin: 0 auto;
}
.promoPreview .bottom p.small {
  font-size: 12px;
  margin-top: 20px;
}
.promoPreview .bottom h3 em,
.promoPreview .bottom p em {
  font-style: normal;
  color: #e6004c;
}
.promoPreview .bottom ul {
  list-style: disc;
  margin-top: 20px;
  padding-left: 20px;
  width: 100%;
}
.promoPreview .bottom ul li .link {
  color: #0cc1be;
}
.promoPreview .bottom ul li .link:hover {
  color: #09918f;
}
@media (max-width: 736px) {
  .promoPreview .bottom {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 50px;
  }
  .promoPreview .bottom h3 {
    font-size: 18px;
  }
  .promoPreview .bottom p,
  .promoPreview .bottom li {
    width: 100%;
    font-size: 13px;
  }
  .promoPreview .bottom p.small,
  .promoPreview .bottom li.small {
    margin-top: 10px;
  }
  .promoPreview .bottom ul {
    margin-top: 10px;
  }
  .promoPreview .bottom .divider {
    margin: 10px auto;
  }
  .promoPreview.slider1 .top img {
    margin-top: 30px;
  }
  .promoPreview.slider1 .bottom {
    height: calc(50% - 40px);
    margin-bottom: 40px;
  }
  .promoPreview.slider2 .top img {
    margin-left: -30px;
  }
  .promoPreview.slider2 .bottom {
    height: calc(50% - 40px);
    margin-bottom: 40px;
  }
  .promoPreview.slider3 .bottom {
    height: calc(50% - 40px);
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 737px) {
  .promoPreview {
    flex-flow: row nowrap;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  .promoPreview .top {
    padding: 120px 0 60px;
    height: 100%;
  }
  .promoPreview .bottom {
    padding-right: 60px;
  }
  .promoPreview.slider1 .top {
    width: 55%;
  }
  .promoPreview.slider1 .bottom {
    width: 45%;
  }
  .promoPreview.slider2 .top {
    width: 50%;
  }
  .promoPreview.slider2 .top img {
    margin-right: 20px;
  }
  .promoPreview.slider2 .bottom {
    width: 50%;
  }
  .promoPreview.slider3 .top {
    width: 50%;
    padding-left: 20px;
    padding-right: 10px;
  }
  .promoPreview.slider3 .bottom {
    width: 50%;
  }
}
@media screen and (min-width: 1025px) {
  .promoPreview .bottom {
    padding-right: 50px;
  }
  .promoPreview.slider1 .top {
    width: 65%;
  }
  .promoPreview.slider1 .bottom {
    width: 35%;
  }
  .promoPreview.slider2 .top {
    width: 50%;
  }
  .promoPreview.slider2 .top img {
    margin-right: 20px;
  }
  .promoPreview.slider2 .bottom {
    width: 50%;
  }
  .promoPreview.slider3 .top {
    width: 60%;
    padding-left: 20px;
    padding-right: 10px;
  }
  .promoPreview.slider3 .bottom {
    width: 40%;
  }
}
@media (max-width: 737px) and (orientation: portrait) {
  .promoPreview .top.withOnlyImage {
    height: 70%;
  }
}
@media (max-width: 736px) and (orientation: landscape) {
  .promoPreview {
    flex-flow: row nowrap;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
  }
}
@media (max-width: 736px) and (orientation: portrait) {
  .promoPreview .top {
    height: calc(50% - 70px);
    margin-top: 70px;
  }
}
@media (max-width: 736px) and (orientation: landscape) {
  .promoPreview .top {
    height: 80%;
    margin-top: 10px;
    padding-left: 20px;
  }
}
@media (max-width: 736px) and (orientation: landscape) {
  .promoPreview .bottom {
    margin-bottom: 0;
  }
}
@media (max-width: 736px) and (orientation: portrait) {
  .promoPreview .top {
    height: calc(50% - 70px);
    margin-top: 70px;
  }
}
@media (max-width: 736px) and (orientation: landscape) {
  .promoPreview .top {
    height: 80%;
    margin-top: 10px;
    padding-left: 20px;
  }
}
@media (max-width: 736px) and (orientation: landscape) {
  .promoPreview img {
    margin: 0;
  }
}
@media (max-width: 736px) and (orientation: portrait) {
  .promoPreview .top {
    height: calc(50% - 80px);
    margin-top: 80px;
    padding-right: 20px;
  }
}
@media (max-width: 736px) and (orientation: landscape) {
  .promoPreview .top {
    height: 40%;
    margin-top: 10px;
    padding-right: 0;
    padding-left: 20px;
  }
}
@media (max-width: 1024px) and (min-width: 737px) {
  .promoPreview .top {
    padding-bottom: 120px;
  }
}
@media (min-width: 737px) {
  .sliderHolder:not(.fullscreen) .productsBanner,
  .sliderHolder:not(.fullscreen) .slick-list {
    height: 300px;
  }
}
@media (min-width: 737px) {
  .sliderHolder:not(.fullscreen).slide:not(.slideRelated) {
    height: 300px;
  }
}
@media (min-width: 1025px) {
  .sliderHolder .previewHolder.productPreview {
    justify-content: center;
  }
}
@media (max-width: 736px) {
  .sliderHolder .previewHolder.productPreview.link .top {
    max-width: 200px;
  }
}
@media (min-width: 1025px) {
  .sliderHolder .previewHolder.productPreview.link .top {
    width: 20%;
    height: 100%;
  }
  .sliderHolder .previewHolder.productPreview.link .top.comingSoon {
    padding: 20px;
  }
}
.sliderHolder .previewHolder.productPreview.link .top > div {
  padding-top: 0;
  height: 100%;
}
.sliderHolder .previewHolder.productPreview.link .top .img {
  position: relative;
  top: 0;
  left: 0;
}
@media (min-width: 1025px) {
  .sliderHolder .previewHolder.productPreview.link .top .img {
    height: 100%;
    width: auto;
  }
}
@media (min-width: 1025px) {
  .sliderHolder .previewHolder.productPreview .bottom {
    width: calc(70% - 10px);
    padding-left: 30px;
    max-width: 800px;
  }
}
.sliderHolder .previewHolder.productPreview .bottom .title {
  font-size: 19px;
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sliderHolder .previewHolder.productPreview .bottom h2 {
  font-size: 19px;
  max-width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sliderHolder .previewHolder.productPreview .bottom h3 {
  font-size: 14px;
  margin-bottom: 8px;
}
.sliderHolder .previewHolder.productPreview .bottom .divider {
  margin: 10px auto;
}
.sliderHolder .previewHolder.productPreview .bottom .characts p {
  font-size: 14px;
}
.sliderHolder .previewHolder.productPreview .bottom .price {
  font-size: 22px;
  text-align: left;
}
.sliderHolder .previewHolder.productPreview .bottom .price em {
  font-size: 14px;
}
@media (max-width: 736px) {
  .sliderHolder .previewHolder.productPreview .bottom .row {
    display: block;
  }
}
@media (max-width: 736px) {
  .sliderHolder .previewHolder.productPreview .bottom .button {
    margin-top: 20px;
  }
}
@media (max-width: 1024px) {
  .sliderHolder .previewHolder.productPreview .bottom .button {
    border-color: #000;
    color: #000;
  }
}
@media (max-width: 1281px) {
  .sliderHolder .previewHolder.productPreview .bottom .button {
    min-height: 40px;
    font-size: 13px;
  }
}
@media (max-width: 1024px) {
  .sliderHolder.comingSoon,
  .sliderHolder.comingSoon.regular {
    height: auto;
  }
}
@media (min-width: 1025px) {
  .sliderHolder.comingSoon,
  .sliderHolder.comingSoon.regular {
    height: 300px;
  }
}
@media (max-width: 1024px) {
  .sliderHolder.comingSoon .slide,
  .sliderHolder.comingSoon.regular .slide,
  .sliderHolder.comingSoon .slide.regular,
  .sliderHolder.comingSoon.regular .slide.regular {
    height: auto;
  }
}
@media (min-width: 1025px) {
  .sliderHolder.comingSoon .slide,
  .sliderHolder.comingSoon.regular .slide,
  .sliderHolder.comingSoon .slide.regular,
  .sliderHolder.comingSoon.regular .slide.regular {
    height: 300px;
  }
}
.sliderHolder.comingSoon .slick-slider,
.sliderHolder.comingSoon .slick-slide {
  transition: padding 1s ease;
}
@media (min-width: 1025px) {
  .sliderHolder.comingSoon .slick-slider.paddingIn,
  .sliderHolder.comingSoon .slick-slide.paddingIn {
    padding-right: 300px;
  }
}
@media (min-width: 1281px) {
  .sliderHolder.comingSoon .slick-slider.paddingIn,
  .sliderHolder.comingSoon .slick-slide.paddingIn {
    padding-right: 400px;
  }
}
@media (min-width: 1441px) {
  .sliderHolder.comingSoon .slick-slider.paddingIn,
  .sliderHolder.comingSoon .slick-slide.paddingIn {
    padding-right: 500px;
  }
}
@media (min-width: 1920px) {
  .sliderHolder.comingSoon .slick-slider.paddingIn,
  .sliderHolder.comingSoon .slick-slide.paddingIn {
    padding-right: 600px;
  }
}
@media (max-width: 1024px) {
  .sliderHolder.productsBanner .top {
    margin: 20px 0;
  }
  .sliderHolder.productsBanner .top img {
    max-height: 200px;
    padding: 0;
    width: auto;
  }
}
.comingSoonBanner {
  background-color: #efecec;
  padding: 30px;
}
.comingSoonBanner.image {
  background-color: #f4f4f4;
}
@media (max-width: 1024px) {
  .comingSoonBanner {
    height: 150px;
    margin-top: 20px;
  }
  .comingSoonBanner.image {
    padding: 0;
  }
}
@media (min-width: 1025px) {
  .comingSoonBanner {
    height: 100%;
    top: 0;
    right: 0;
    width: 100%;
  }
}
@media (min-width: 1281px) {
  .comingSoonBanner {
    width: 100%;
  }
}
@media (min-width: 1441px) {
  .comingSoonBanner {
    width: 100%;
  }
}
@media (min-width: 1920px) {
  .comingSoonBanner {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .comingSoonBanner img {
    height: 150px;
    width: 100%;
    object-fit: contain;
  }
}
@media (min-width: 1025px) {
  .comingSoonBanner img {
    width: 100%;
  }
}
.comingSoonBanner p {
  color: #e6004c;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
.comingSoonBanner .button {
  border-color: #e6004c;
  color: #e6004c;
}
@media (max-width: 1281px) {
  .comingSoonBanner .button {
    min-height: 40px;
    font-size: 13px;
  }
}
.comingSoonBanner .button:hover {
  border-color: #cc1451;
  color: #cc1451;
}
.comingSoonBanner.animate {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 0.1s;
}
.comingSoonBanner.fadeInRight {
  animation-name: fadeInRight;
}
.comingSoonBanner.fadeInRight.first {
  animation-name: none;
}
.comingSoonBanner.fadeOutRight {
  animation-name: fadeOutRight;
}
.comingSoonBanner.fadeOutRight.first {
  opacity: 0;
}
.comingSoonProduct.product {
  width: 100%;
  padding-left: 50px;
}
@media (max-width: 1024px) {
  .comingSoonProduct.product {
    height: auto;
    padding-right: 50px;
  }
}
@media (min-width: 1025px) {
  .comingSoonProduct.product {
    height: 300px;
  }
}
@media (min-width: 1025px) {
  .comingSoonProduct.product.paddingIn {
    padding-right: 300px;
  }
}
@media (min-width: 1281px) {
  .comingSoonProduct.product.paddingIn {
    padding-right: 400px;
  }
}
@media (min-width: 1441px) {
  .comingSoonProduct.product.paddingIn {
    padding-right: 500px;
  }
}
@media (min-width: 1920px) {
  .comingSoonProduct.product.paddingIn {
    padding-right: 600px;
  }
}
@media (min-width: 1025px) {
  .comingSoonProduct.product .previewHolder.mainProduct {
    justify-content: center;
  }
}
@media (max-width: 736px) {
  .comingSoonProduct.product .previewHolder.mainProduct .top {
    max-width: 200px;
  }
}
@media (min-width: 1025px) {
  .comingSoonProduct.product .previewHolder.mainProduct .top {
    width: 30%;
    height: 100%;
  }
}
@media (min-width: 1025px) {
  .comingSoonProduct.product .previewHolder.mainProduct .top .img {
    height: 100%;
    width: auto;
  }
}
@media (min-width: 1025px) {
  .comingSoonProduct.product .previewHolder.mainProduct .bottom {
    width: calc(70% - 10px);
    padding-left: 0;
    max-width: 800px;
  }
}
.comingSoonProduct.product .previewHolder.mainProduct .bottom .title {
  text-transform: uppercase;
  color: #00a393;
  text-align: center;
  margin-bottom: 20px;
  min-height: auto;
  font-size: 16px;
  font-family: 'FrutigerLTStd-Bold';
}
.comingSoonProduct.product .previewHolder.mainProduct .bottom h2 {
  font-size: 19px;
  max-width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.comingSoonProduct.product .previewHolder.mainProduct .bottom h3 {
  font-size: 14px;
  margin-bottom: 8px;
}
.comingSoonProduct.product .previewHolder.mainProduct .bottom .divider {
  margin: 10px auto;
}
.comingSoonProduct.product .previewHolder.mainProduct .bottom .characts p {
  font-size: 14px;
}
.comingSoonProduct.product .previewHolder.mainProduct .bottom .price {
  font-size: 22px;
}
.comingSoonProduct.product .previewHolder.mainProduct .bottom .price em {
  font-size: 14px;
}
@media (max-width: 736px) {
  .comingSoonProduct.product .previewHolder.mainProduct .bottom .row {
    display: block;
  }
}
@media (max-width: 736px) {
  .comingSoonProduct.product .previewHolder.mainProduct .bottom .button {
    margin-top: 20px;
  }
}
@media (max-width: 1024px) {
  .comingSoonProduct.product .previewHolder.mainProduct .bottom .button {
    border-color: #000;
    color: #000;
  }
}
@media (max-width: 1281px) {
  .comingSoonProduct.product .previewHolder.mainProduct .bottom .button {
    min-height: 40px;
    font-size: 13px;
  }
}
.slideHome {
  position: relative;
}
.slideHome:before {
  content: ' ';
  width: 100%;
  height: 150px;
  padding-bottom: 15px;
  position: absolute;
  bottom: -5px;
  right: 0;
  z-index: 95;
  background-image: url('https://pics.imgix.net/img/homerestyle/axonSlideDec2.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.slick-prev {
  height: 100% !important;
}
.slick-prev:before {
  font-size: 40px !important;
  position: absolute;
  left: -10px;
}
.slick-prev:hover:before {
  color: #e6004c;
}
.slick-next {
  right: 0 !important;
  height: 100% !important;
}
.slick-next:before {
  font-size: 40px !important;
  position: absolute;
  right: 10px;
}
.slick-next:hover:before {
  color: #e6004c;
}
@-moz-keyframes fadeInRight {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes fadeInRight {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: none;
  }
}
@-o-keyframes fadeInRight {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: none;
  }
}
@-moz-keyframes fadeOutRight {
  from {
    transform: none;
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes fadeOutRight {
  from {
    transform: none;
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
@-o-keyframes fadeOutRight {
  from {
    transform: none;
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    transform: none;
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
.price {
  padding: 0;
}
.price em {
  margin-right: 1em;
}
@media screen and (min-width: 1025px) {
  .price {
    font-size: 26px;
  }
  .price em {
    margin-bottom: 0.7em;
  }
}
.previewHolder {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.previewHolder .top {
  flex: 0 0 auto;
  display: block;
}
.previewHolder .top.comingSoon .img {
  border: thin solid #d48216;
}
.previewHolder .top.comingSoon .comingSoonSpan {
  position: absolute;
  top: 10px;
  left: 10px;
  display: block;
  padding: 10px;
  background-color: #d48216;
  color: #fff;
  z-index: 2;
  font-size: 10px;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  width: auto;
  font-weight: 900;
  white-space: nowrap;
}
@media (max-width: 1024px) {
  .previewHolder .top.comingSoon .comingSoonSpan {
    left: 50%;
    transform: translateX(-50%);
  }
}
.previewHolder .top .img {
  flex: 1 1 auto;
  width: 100%;
  height: auto;
  margin: auto;
  padding: 20px;
}
.previewHolder .bottom {
  flex: 0 0 auto;
  height: auto;
  text-align: center;
  width: 100%;
  min-height: 120px;
}
.previewHolder .bottom .title {
  text-transform: none;
  min-height: 55px;
}
.previewHolder .bottom h3 {
  text-transform: none;
}
@media screen and (max-width: 1024px) {
  .previewHolder .bottom .price {
    text-align: left;
  }
}
.previewHolder .bottom .divider {
  background-color: #f5f3f3;
}
.previewHolder .bottom .divider.ebookPage {
  margin: 17px auto;
}
.previewHolder .pricesEbook {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 130px;
}
.previewHolder .pricesEbook .priceTypeWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.previewHolder .pricesEbook .priceTypeWrapper + .priceTypeWrapper {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 8px;
  margin-top: 5px;
}
.previewHolder .pricesEbook .priceTypeWrapper .priceType {
  text-transform: uppercase;
  color: #808080;
  font-size: 12px;
  letter-spacing: 3px;
}
.previewHolder .pricesEbook .priceTypeWrapper .price {
  margin-top: 0;
  margin-right: 20px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .previewHolder .comingSoon ~ .bottom {
    margin-top: 40px;
  }
}
.previewHolder.link .top {
  width: 100%;
  position: relative;
  margin: 26px 6px 12px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .previewHolder.link .top {
    margin-bottom: -25px;
  }
}
.previewHolder.link .top > div {
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .previewHolder.link .top > div {
    width: auto;
  }
}
.previewHolder.link .top .img {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  object-fit: contain;
  padding: 26px 6px 12px;
  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
				width auto */
}
@media (max-width: 1024px) {
  .previewHolder.link .top .img {
    display: block;
    -webkit-transform: translateZ(0);
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
  }
}
.previewHolder.link .top:not(.comingSoon) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex: 1;
  min-height: 250px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .previewHolder.link .top:not(.comingSoon) {
    flex: 1 0 auto;
  }
}
.previewHolder.link .top:not(.comingSoon) > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.previewHolder.link:hover .top .img,
.previewHolder.link:focus .top .img {
  opacity: 0.8;
}
.previewHolder.link:hover .bottom .title,
.previewHolder.link:focus .bottom .title {
  color: #e6004c;
}
@media screen and (min-width: 737px) {
  .slide .previewHolder,
  .product .previewHolder.mainProduct {
    flex-flow: row nowrap;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  .slide .previewHolder .top,
  .product .previewHolder.mainProduct .top {
    width: 40%;
  }
  .slide .previewHolder .bottom,
  .product .previewHolder.mainProduct .bottom {
    width: calc(60% - 20px);
  }
}
@media screen and (min-width: 1281px) {
  .slide .previewHolder .top,
  .product .previewHolder.mainProduct .top {
    width: 40%;
  }
  .slide .previewHolder .bottom,
  .product .previewHolder.mainProduct .bottom {
    width: 60%;
  }
}
.previewHolder.saleBanner .top.comingSoon .comingSoonSpan {
  background-color: #0dd9d6;
}
.previewHolder.saleBanner .top.comingSoon .img {
  border-color: #0dd9d6;
}
.previewHolder.saleBanner .bottom .promoTitle {
  text-transform: uppercase;
  color: #00a393;
  text-align: center;
  font-size: 16px;
  font-family: 'FrutigerLTStd-Bold';
  margin-bottom: 20px;
}
.previewHolder.saleBanner .bottom .holder {
  align-items: center;
}
.previewHolder.saleBanner .bottom .holder .price {
  font-family: 'FrutigerLTStd-Bold';
}
.previewHolder.saleBanner .bottom .saleText {
  background-color: rgba(13, 217, 214, 0.1);
  padding: 10px;
  color: #00a393;
  font-size: 14px;
  text-transform: uppercase;
}
.previewHolder.saleBanner:hover .top.comingSoon .comingSoonSpan {
  background-color: #e6004c;
}
.previewHolder.saleBanner:hover .top.comingSoon .img {
  border-color: #e6004c;
}
.previewHolder.saleBanner:hover .bottom .promoTitle {
  color: #e6004c;
}
.previewHolder.saleBanner:hover .bottom .saleText {
  background-color: rgba(230, 0, 76, 0.1);
  color: #e6004c;
}
.productPreview.slider {
  justify-content: space-around;
}
.productPreview.slider:hover .divider {
  background-color: #e6004c;
}
.productPreview.slider .top {
  max-width: 480px;
}
.productPreview.slider .title {
  padding-bottom: 0;
  text-align: left;
  min-height: 0;
}
@media (max-width: 1024px) {
  .productPreview.slider .title {
    min-height: 90px;
  }
}
.productPreview.slider .subTitle {
  text-align: left;
}
.productPreview.slider .authorsEdition {
  display: flex;
  justify-content: space-between;
}
.productPreview.slider .dateBanner {
  text-align: center;
  padding-top: 10px;
}
.productPreview.result .price {
  margin-top: 10px;
}
@media screen and (max-width: 1024px) {
  .productPreview.result .bottom h4.title {
    font-size: 16px;
    min-height: 45px;
    color: #262626;
  }
  .productPreview.result .bottom .price {
    text-align: left;
  }
}
@media screen and (min-width: 1025px) {
  .productPreview.result .bottom {
    overflow: hidden;
  }
  .productPreview.result .bottom h4.title {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.2em;
    letter-spacing: 0.04em;
    color: #262626;
  }
}
.productList:not(.related) {
  padding-top: 80px;
  width: 100%;
}
.productList .productPreview {
  width: calc(50% - 10px);
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  .productList .productPreview {
    width: 100%;
    margin-bottom: 20px;
  }
}
.productList .productPreview .bottom {
  text-align: left;
  padding: 0 6px;
}
.productList .productPreview .title {
  font-weight: normal;
  text-align: left;
  margin-bottom: 10px;
}
.productList .productPreview span {
  font-size: 12px;
  white-space: nowrap;
  text-align: left;
  padding-top: 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.productList .productPreview .subTitle {
  font-weight: normal;
  text-align: left;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
  max-height: 20px;
  margin: 9px 0 3px;
}
.productList .productPreview .subTitle > span {
  display: block;
}
@media screen and (min-width: 1025px) {
  .productList:not(.related) .productPreview {
    width: 25%;
    min-height: 376px;
  }
}
@media screen and (min-width: 1441px) {
  .productList:not(.related) .productPreview {
    width: 20%;
  }
}
@media screen and (min-width: 1920px) {
  .productList:not(.related) .productPreview {
    width: 15%;
  }
}
@media screen and (min-width: 737px) and (max-width: 1024px) {
  .productList:not(.related) .productPreview {
    width: 30%;
    padding: 10px;
  }
}
.productList.related .productPreview h4.title {
  max-height: 65px;
  min-height: 65px;
}
@media screen and (min-width: 737px) and (max-width: 1024px) and (min-width: 0) and (max-width: 320px) {
  .productList.related .productPreview {
    width: calc(33% - 10px);
  }
}
.predictiveWrapper {
  background-color: transparent;
  padding: 0 15px;
  margin-top: 68px;
  width: 100vw;
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 10;
}
@media screen and (min-width: 1025px) {
  .predictiveWrapper {
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.predictiveWrapper .predictive {
  flex: 1 1 100%;
  max-width: 670px;
  min-height: 50px;
  background-color: #fff;
  max-height: 150px;
  text-align: left;
  overflow-y: auto;
}
@media screen and (max-width: 1024px) {
  .predictiveWrapper.fullscreen {
    padding-left: 0;
    padding-right: 0;
  }
  .predictiveWrapper.fullscreen .predictive {
    max-width: calc(100% - 40px);
  }
}
@media screen and (min-width: 1025px) {
  .predictiveWrapper.fullscreen {
    max-width: 1441px;
  }
  .predictiveWrapper.fullscreen .predictive {
    max-width: 1441px;
  }
}
@media screen and (max-width: 1024px) {
  .predictiveWrapper.fullscreen {
    padding-left: 0;
    padding-right: 0;
  }
  .predictiveWrapper.fullscreen .predictive {
    max-width: calc(100% - 40px);
  }
}
.predictiveWrapper .predictiveItem {
  min-height: 50px;
  padding: 0 20px;
  width: 100%;
}
.predictiveWrapper .predictiveItem:hover,
.predictiveWrapper .predictiveItem.selected {
  color: #e6004c;
  background-color: rgba(0, 0, 0, 0.06);
}
.resultsWrapper.fadeIn {
  left: 0;
}
.resultsWrapper {
  width: 100%;
  height: 2em;
  display: block;
  position: fixed;
  z-index: 9;
  top: 130px;
  text-align: center;
}
@media (min-width: 737px) {
  .resultsWrapper {
    padding-top: 15px;
  }
}
.resultsWrapper.fadeIn {
  opacity: 1;
  bottom: 0;
  height: auto;
  overflow-y: auto;
  top: 150px;
}
.resultsWrapper .results {
  padding: 20px 40px 0;
  margin-bottom: 20px;
}
@media screen and (max-width: 736px) {
  .resultsWrapper .results {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.resultsWrapper .results .predictiveSearch {
  padding-top: 20px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
@media (min-width: 1025px) {
  .resultsWrapper .results .predictiveSearch .button .icon {
    margin-right: 0;
  }
}
.productListed {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border-color: transparent;
}
.productListed:first-of-type {
  margin-top: 0;
}
.productListed:last-of-type {
  margin-bottom: 0;
}
.productListed .img {
  height: 60px;
  width: 60px;
  margin-right: 10px;
  object-fit: contain;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .productListed .img {
    width: auto;
  }
}
.productListed .title {
  padding-top: 0;
  text-align: left;
}
.productListed .subTitle {
  width: auto;
  color: #525252;
  font-style: italic;
  text-align: left;
}
.productListed.button:hover .title,
.productListed.button:hover .subTitle {
  color: #e6004c;
}
.bill .productListed {
  padding: 0;
}
.bill .productListed .price {
  min-width: 90px;
}
.bill .productListed:first-of-type {
  margin-top: 10px;
}
.product .row {
  position: relative;
}
.product .previewHolder.mainProduct {
  padding-top: 10px;
}
.product .previewHolder.mainProduct .saleText {
  background-color: rgba(230, 0, 76, 0.1);
  padding: 10px;
  color: #e6004c;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  width: auto;
  margin: 0;
}
.product .previewHolder.mainProduct .bottom {
  text-align: left;
}
.product .previewHolder.mainProduct .bottom h1 {
  text-transform: none;
  font-size: 26px;
  margin-bottom: 8px;
}
.product .previewHolder.mainProduct .bottom h2 {
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 10px;
  font-style: italic;
}
.product .previewHolder.mainProduct .bottom .tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
}
.product .previewHolder.mainProduct .bottom .dateAvailable {
  display: inline-block;
  width: auto;
  color: #333;
  font-style: italic;
  padding-left: 5px;
  margin-top: 10px;
}
@media (max-width: 736px) {
  .product .previewHolder.mainProduct .bottom .dateAvailable {
    margin-bottom: 10px;
  }
}
.product .previewHolder.mainProduct .bottom .row {
  margin-bottom: 10px;
}
.product .previewHolder.mainProduct .bottom .row.bookPrice {
  margin-bottom: 5px;
}
@media (max-width: 480px) {
  .product .previewHolder.mainProduct .bottom .row {
    flex-direction: column;
    align-items: flex-start;
  }
}
.product .previewHolder.mainProduct .bottom .row .price {
  text-align: left;
  padding-top: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
  color: #00a393;
}
@media (max-width: 480px) {
  .product .previewHolder.mainProduct .bottom .row .price {
    margin-bottom: 10px;
  }
}
.product .previewHolder.mainProduct .bottom .row .price .iva {
  text-transform: none;
  margin-left: 10px;
  color: #008577;
}
.product .previewHolder.mainProduct .bottom .row .emailMeWrapper {
  width: auto;
}
.product .previewHolder.mainProduct .bottom .row .button {
  vertical-align: middle;
}
@media (max-width: 480px) {
  .product .previewHolder.mainProduct .bottom .row .button {
    align-self: center;
  }
}
.product .previewHolder.mainProduct .bottom .row .button.disabled {
  background-color: #fff !important;
  color: #a9a9a9;
  pointer-events: none;
}
.product .previewHolder.mainProduct .bottom .row .button.caChing .icon::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e6004c;
  opacity: 0;
  height: 0;
  width: 0;
  border-radius: 50%;
  animation: buyButton 700ms cubic-bezier(0, -0.03, 0, 0.99);
}
@-moz-keyframes buyButton {
  80% {
    height: 70px;
    width: 70px;
    opacity: 0.4;
  }
  100% {
    height: 90px;
    width: 90px;
    opacity: 0;
  }
}
@-webkit-keyframes buyButton {
  80% {
    height: 70px;
    width: 70px;
    opacity: 0.4;
  }
  100% {
    height: 90px;
    width: 90px;
    opacity: 0;
  }
}
@-o-keyframes buyButton {
  80% {
    height: 70px;
    width: 70px;
    opacity: 0.4;
  }
  100% {
    height: 90px;
    width: 90px;
    opacity: 0;
  }
}
@keyframes buyButton {
  80% {
    height: 70px;
    width: 70px;
    opacity: 0.4;
  }
  100% {
    height: 90px;
    width: 90px;
    opacity: 0;
  }
}
.product .previewHolder.mainProduct .bottom .row .button.emailMe {
  margin-left: 20px;
}
.product .previewHolder.mainProduct .bottom .row.freeDelivery {
  justify-content: flex-start;
}
.product .previewHolder.mainProduct .bottom .row.freeDelivery .tag {
  margin-left: 10px;
}
.product .previewHolder.mainProduct .bottom .row.freeDelivery .freeDeliveryWrapper {
  min-width: 150px;
  margin: 0;
}
.product .previewHolder.mainProduct .bottom .emailedMsg {
  font-size: 16px;
  opacity: 1;
  display: block;
  animation: fadeIn 900ms ease;
}
.product .previewHolder.mainProduct .bottom .emailedMsg.hide {
  transition: opacity 700ms ease-out;
  opacity: 0;
}
@-moz-keyframes fadeIn {
  0% {
    font-size: 0;
    opacity: 0;
    display: none;
  }
  50% {
    font-size: 0;
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    font-size: 0;
    opacity: 0;
    display: none;
  }
  50% {
    font-size: 0;
    opacity: 0;
    display: none;
  }
}
@-o-keyframes fadeIn {
  0% {
    font-size: 0;
    opacity: 0;
    display: none;
  }
  50% {
    font-size: 0;
    opacity: 0;
    display: none;
  }
}
@keyframes fadeIn {
  0% {
    font-size: 0;
    opacity: 0;
    display: none;
  }
  50% {
    font-size: 0;
    opacity: 0;
    display: none;
  }
}
.product .previewHolder.mainProduct .bottom .emailMeForm {
  padding: 20px;
  width: 100%;
}
.product .previewHolder.mainProduct .bottom .emailMeForm .form .field {
  margin-bottom: 0;
}
.product .previewHolder.mainProduct .bottom .emailMeForm p {
  padding: 0;
}
.product .previewHolder.mainProduct .bottom .emailMeForm p:not(.error) {
  margin-bottom: 10px;
}
.product .previewHolder.mainProduct .bottom .emailMeForm p.error {
  margin-top: 10px;
}
.product .previewHolder.mainProduct .bottom .pricesWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin: 27px auto 10px;
}
@media (max-width: 736px) {
  .product .previewHolder.mainProduct .bottom .pricesWrapper {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem {
  border: 2px solid #b3b1b1;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 40px 20px 10px 20px;
  background-color: #eee;
  height: 100%;
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem .titleWrapper {
  position: absolute;
  top: 15px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem .titleWrapper .icon {
  color: #b3b1b1;
}
.product
  .previewHolder.mainProduct
  .bottom
  .pricesWrapper
  .priceItem
  .titleWrapper
  .icon.info:before {
  font-size: 28px;
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem .titleWrapper h2 {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.9px;
  font-weight: 700;
  margin-bottom: 0;
  margin-left: 5px;
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem .productInfo {
  position: absolute;
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem .productInfo .icon {
  color: #b3b1b1;
}
.product
  .previewHolder.mainProduct
  .bottom
  .pricesWrapper
  .priceItem
  .productInfo
  .productInfoPopUp {
  position: absolute;
  background-color: #fff;
  border: 2px solid #000;
  padding: 10px;
  width: 200px;
  border-radius: 10px;
  top: 0;
}
@media (min-width: 737px) {
  .product
    .previewHolder.mainProduct
    .bottom
    .pricesWrapper
    .priceItem
    .productInfo
    .productInfoPopUp {
    left: 30px;
  }
}
@media (max-width: 736px) {
  .product
    .previewHolder.mainProduct
    .bottom
    .pricesWrapper
    .priceItem
    .productInfo
    .productInfoPopUp {
    right: 0;
    top: 30px;
  }
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem .row {
  position: relative;
  margin: 0;
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem .row .price {
  width: auto;
  font-weight: 800;
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem.selected {
  border-width: 4px;
  cursor: default;
  border-color: #f9b;
  background-color: #fff;
  box-shadow: 3px 4px 5px 0 #cacaca;
}
.product .previewHolder.mainProduct .bottom .pricesWrapper .priceItem.selected .icon {
  color: #e6004c;
}
.product .previewHolder.mainProduct .bottom .buyContainer {
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 736px) {
  .product .previewHolder.mainProduct .bottom .buyContainer {
    flex-direction: column;
  }
}
.product .previewHolder.mainProduct .bottom .buyContainer .deliveryTagsWrapper {
  display: flex;
  align-items: center;
  width: auto;
  margin-right: 50px;
}
.product .previewHolder.mainProduct .bottom .buyContainer .deliveryTagsWrapper .freeDelivery {
  margin-right: 20px;
  margin-bottom: 0;
}
.product .previewHolder.mainProduct .bottom .buyContainer .buttonBuy {
  flex-direction: column;
  justify-content: center;
  margin: 0;
}
@media (max-width: 736px) {
  .product .previewHolder.mainProduct .bottom .buyContainer .buttonBuy {
    margin-top: 20px;
  }
}
.product .previewHolder.mainProduct .bottom .buyContainer .buttonBuy .message {
  text-align: center;
}
.product .previewHolder.mainProduct .bottom .buyContainer.ebookPage .buttonBuy {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}
.product .previewHolder.mainProduct .bottom .buyContainer.ebookPage .buttonBuy > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product .previewHolder.mainProduct .bottom .buyContainer.ebookPage .buttonBuy.selectedLeft span {
  order: 2;
}
.product .previewHolder.mainProduct .bottom .buyContainer.ebookPage .buttonBuy.selectedLeft > div {
  order: 1;
}
.product .productData {
  padding-top: 10px;
}
.product .productData.magazines h4 {
  width: 100%;
}
.product .collapsibleContent {
  padding-left: 30px;
  margin: 0 auto;
}
.product .collapsibleContent a:not(.tag) {
  color: #e6004c;
}
.product .collapsibleContent.attached {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.product .collapsibleContent.attached a {
  position: relative;
  padding-left: 35px;
  height: 30px;
  display: flex;
  align-items: center;
}
.product .collapsibleContent.attached a:before {
  content: '';
  width: 25px;
  height: 30px;
  background-image: url('https://pics.imgix.net/img/pdf-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.product .collapsibleContent.attached a + a {
  margin-top: 10px;
}
.pack {
  background-color: #f5f3f3;
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  position: relative;
}
.pack .packNum {
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 15px;
  top: 10px;
  font-size: 11px;
}
.pack .info {
  padding-top: 10px;
  flex: 1 1 100%;
  max-width: calc(100% - 200px);
}
.pack .info .packItem {
  padding: 7px 10px 5px 10px;
  margin: 5px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
}
.pack .info .packItem span {
  flex: 0 0 auto;
  line-height: 1em;
  margin-top: 2px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
}
.pack .info .packItem.main {
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.06);
}
.pack .actions {
  margin-left: 30px;
  flex: 1 1 auto;
}
.pack .actions .price {
  position: relative;
  font-size: 18px;
  padding-top: 5px;
  margin-bottom: 20px;
  margin-right: 10px;
}
.pack .actions .price span {
  text-transform: none;
  display: block;
  position: absolute;
  right: 0;
  top: 100%;
  font-size: 12px;
  line-height: 1em;
}
.pack .actions .button {
  min-height: 40px;
}
.magazinePreview.right {
  justify-content: flex-end;
}
.magazinePreview > .img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .magazinePreview > .img {
    width: auto;
  }
}
.magazinePreview .info {
  flex: 1 1 100%;
}
.magazinePreview .info h2,
.magazinePreview .info h3,
.magazinePreview .info p {
  flex: 1 1 100%;
}
.magazinePreview .info h2 {
  text-transform: none;
  font-size: 26px;
}
.magazinePreview .info h3 {
  text-transform: none;
}
.magazinePreview .buttonGroup {
  flex: 0 0 auto;
}
.magazinePreview .buttonGroup .button {
  font-size: 10px;
  font-weight: bold;
  color: #525252;
  text-transform: uppercase;
  z-index: 1;
  width: auto;
}
.magazinePreview .buttonGroup .button.active {
  z-index: 2;
  color: #000;
  background-color: #faf8f8;
}
.magazinePreview .buttonGroup .button:hover {
  z-index: 3;
  color: #e6004c;
}
.magazinePreview .buttonGroup .button + .button {
  margin-left: -2px;
}
.magazinePreview .link {
  width: auto;
  flex: 0 0 auto;
  border-radius: 25px;
  padding: 15px;
  padding-left: 20px;
}
.magazinePreview .link .icon {
  margin-left: 5px;
  margin-top: -2px;
}
@media screen and (max-width: 736px) {
  .magazinePreview {
    flex-wrap: wrap;
  }
  .magazinePreview .buttonGroup {
    width: 100%;
    margin-top: 20px;
  }
  .magazinePreview .buttonGroup .button {
    width: 50%;
  }
}
.packList {
  width: 100%;
}
.collapsibleBlock.columns .collapsibleContent {
  column-count: 2;
  column-gap: 30px;
}
@media screen and (min-width: 1025px) {
  .collapsibleBlock.columns .collapsibleContent {
    column-count: 3;
  }
}
@media screen and (max-width: 736px) {
  .collapsibleBlock.columns .collapsibleContent {
    column-count: 1;
  }
}
.collapsibleBlock.columns .collapsibleContent h4 {
  margin-bottom: 10px;
}
.collapsibleBlock.columns .collapsibleContent ul {
  margin-top: 10px;
  margin-bottom: 10px;
  list-style: disc;
  display: inline-block;
  width: 100%;
  padding-left: 25px;
}
.collapsibleBlock.columns .collapsibleContent ul li {
  margin-bottom: 5px;
}
.relatedWrapper .sliderHolder {
  background-color: #fff;
}
.relatedWrapper .sliderHolder.regular {
  height: auto;
  margin-left: 0;
  margin-right: 0;
}
.relatedWrapper .sliderHolder.regular .slick-list {
  margin: 0;
}
.relatedWrapper .sliderHolder.regular .slide {
  height: auto;
}
.relatedWrapper .sliderHolder .slide {
  background-color: #fff;
}
.relatedWrapper .sliderHolder .slide .related {
  flex-wrap: nowrap;
}
.relatedWrapper .sliderHolder .slide .related .previewHolder {
  display: block;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.relatedWrapper .sliderHolder .slide .related .previewHolder .top,
.relatedWrapper .sliderHolder .slide .related .previewHolder .bottom {
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .relatedWrapper .sliderHolder .slide .related .previewHolder .top {
    min-height: 200px;
  }
}
.characts {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.characts .noBar {
  border-right: none;
}
.characts h3.brandName {
  text-transform: capitalize;
}
.characts h3,
.characts p {
  flex: 0 0 auto;
  display: block;
  letter-spacing: 0.07em;
  font-style: normal;
  padding: 0 10px;
  margin: 0;
  border-right: thin solid #b3b1b1;
}
.characts h3:first-child,
.characts p:first-child {
  padding-left: 0;
  margin-top: 0;
}
.characts h3:last-child,
.characts p:last-child {
  border-right: none;
}
.ReactModal__Overlay {
  z-index: 4000;
}
.ebookInfo .link {
  color: #e6004c;
}
.ebookInfo .link:hover {
  color: #a21d48;
}
.ebookInfo h3 {
  margin-bottom: 20px;
}
.ebookInfo p {
  margin-bottom: 20px;
}
.ebookInfo .ebookInfoSection + .ebookInfoSection {
  margin-top: 50px;
}
.ebookInfo .ebookInfoSection ul {
  margin-left: 20px;
}
.ebookInfo .ebookInfoSection li + li {
  margin-top: 10px;
}
.ebookInfo .ebookInfoSection.apps .mobileApps {
  margin-left: 30px;
  padding-left: 30px;
  border-left: 2px solid #000;
}
.ebookInfo .functionality ul {
  margin-left: 40px;
}
.ebookInfo .functionality li:before {
  content: '\2022';
  color: #e6004c;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.ebookInfo .steps {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.ebookInfo .steps span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e6004c;
  border: 3px solid #e6004c;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}
.ebookInfo .steps li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc((100% / 3) - 40px);
}
.ebookInfo .steps li + li {
  margin-top: 0;
}
.padding-top-18 {
  padding-top: 18px;
}
.display-flex {
  display: flex;
}
.margin-top-30 {
  margin-top: 30px;
}
.counter {
  font-weight: normal;
  font-style: italic;
  text-align: center;
  padding: 0 0 15px 0;
  font-size: 14px;
  letter-spacing: 0;
}
.counter .link {
  color: #e6004c;
  font-style: normal;
  display: inline-block;
  padding: 7px 10px 3px 10px;
  background-color: #f5f3f3;
  margin-left: 15px;
}
@media screen and (max-width: 736px) {
  .counter .link {
    display: block;
    margin: 10px auto;
    padding-top: 20px;
    padding-bottom: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .counter .link:hover {
    opacity: 0.6;
  }
}
.filtersWrapper {
  background-color: #fff;
  width: 100%;
  height: 80px;
  z-index: 4;
  padding-top: 20px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.filtersWrapper.fixed {
  position: fixed;
  top: 165px;
  left: 0;
  right: 0;
  transform: translateX(0);
  z-index: 3001;
}
@media screen and (max-width: 1024px) {
  .filtersWrapper.fixed {
    top: 145px;
  }
}
.filters {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  background-color: #fff;
}
@media screen and (max-width: 1024px) {
  .filters {
    width: calc(100% - 40px);
    padding-top: 0;
  }
  .filters.fixed {
    position: fixed;
    top: 149px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3002;
  }
}
@media screen and (min-width: 1025px) {
  .filters {
    border: none;
    max-width: 1400px;
    width: calc(100% - 200px);
    max-height: 70px;
    overflow: visible;
  }
  .filters.magazine {
    width: 900px;
  }
}
@media screen and (min-width: 1920px) {
  .filters {
    max-width: 1800px;
  }
}
.filters .filterHeader {
  height: 50px;
  background-color: #fff;
  padding: 0 20px;
  width: calc(100% - 40px);
}
@media screen and (max-width: 1024px) {
  .filters .filterHeader {
    width: 100%;
  }
}
.filters .filterHeader > * {
  color: #525252;
}
.filters .filterHeader > *:first-child {
  flex: 1 1 100%;
  line-height: 50px;
  text-align: left;
}
.filters .filterHeader > *:first-child .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.filters .filterHeader > *:last-child {
  flex: 0 0 auto;
}
.filters .filtersOrder,
.filters .filtersReset {
  position: absolute;
  top: 0;
}
.filters .filtersOrder {
  left: -30px;
  padding-top: 10px;
  margin-top: 17px;
}
.filters .activeFilters {
  color: #e6004c;
}
.filters .filtersReset {
  right: -30px;
}
.filters .filtersReset span {
  font-size: 11px;
  letter-spacing: 0.08em;
  display: block;
  max-width: 20px;
  text-transform: uppercase;
}
.filters .filtersReset:hover {
  color: #e6004c;
}
.filters .filtersReset.inactive {
  color: #525252;
  pointer-events: none;
}
.filters .buttonGroup {
  width: 100%;
}
.filters .buttonGroup .button {
  background-color: #fff;
  width: calc(50% + 2px);
  margin-right: -1px;
  margin-left: -1px;
  color: #525252;
  flex: 0 0 auto;
  z-index: 3;
}
.filters .buttonGroup .button.active {
  z-index: 4;
  border-color: #525252;
  color: #000;
}
.filters .buttonGroup .button.focused {
  z-index: 4;
  border-color: #000;
  color: #e6004c;
}
.filters .buttonGroup .button:hover {
  z-index: 8;
  border-color: #e6004c;
  color: #e6004c;
}
@media screen and (max-width: 1024px) {
  .filters.active {
    position: fixed;
    width: 100%;
    bottom: 0;
    border: 0;
    padding: 15px 30px 30px;
    top: 50px;
    z-index: 20;
    pointer-events: all;
  }
}
.filters.inactive {
  color: #525252;
}
.filters.inactive.used {
  color: #000;
}
#magazines .buttonGroup .button {
  width: calc(50% + 2px);
}
.filterWrapper {
  height: 100%;
}
.filterWrapper .filterHeader {
  width: 100%;
  height: 50px;
  padding: 0 30px;
}
.filterWrapper .filterHeader > * {
  color: #000;
}
.filterWrapper .filterHeader > *:first-child {
  pointer-events: none;
}
.filterWrapper .filterContent {
  height: calc(100% - 120px);
  width: 100%;
}
.filterWrapper .filterContent .scrollbarHolder {
  height: 100%;
}
.filterWrapper .filterFooter {
  padding: 0;
}
.filterWrapper .filterFooter.actionsHolder {
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.filterWrapper .filterFooter.actionsHolder .button:first-child {
  margin-left: 0;
}
.filtersOrder {
  width: 20px;
}
.filtersOrder::before {
  content: 'Ordenar por:';
  font-size: 11px;
  letter-spacing: 0.08em;
  position: absolute;
  bottom: calc(100% - 10px);
  right: 0;
  text-align: right;
  text-transform: uppercase;
  font-family: 'FrutigerLTStd-Roman';
}
.filtersOrder .button {
  height: 20px;
  width: 20px;
  display: block;
  margin-bottom: 10px;
  color: #525252;
  position: relative;
  overflow: visible;
}
.filtersOrder .button.active {
  color: #000;
}
.filtersOrder .button:hover {
  color: #e6004c;
}
.filtersOrder .button:hover:before {
  position: absolute;
  font-size: 11px;
  height: 30px;
  background: #fff;
  z-index: 11;
  top: -6px;
  left: 29px;
  padding-top: 3px;
}
.filtersOrder .button:hover.alfabeta:before {
  content: 'Orden alfabético';
  width: 120px;
}
.filtersOrder .button:hover.visited:before {
  content: 'Más visitados';
  width: 95px;
}
.filtersOrder .button:hover.euro:before {
  content: 'Orden por precio';
  width: 115px;
}
.filtersOrder .button:hover.calendar:before {
  content: 'Novedad';
  width: 90px;
}
.pageContent#tags {
  margin-top: 40px;
}
.pageContent#filters .filters {
  flex: 1 1 100%;
}
.pageContent#filters .filters .row {
  padding-top: 10px;
  flex: 1;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .pageContent#filters .filters .row {
    flex: 1 0 auto;
  }
}
.pageContent#filters .filters .row .scrollbarHolder {
  flex: 1;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .pageContent#filters .filters .row .scrollbarHolder {
    flex: 1 0 auto;
  }
}
.pageContent#filters .filters .row .scrollbarHolder::before {
  content: '';
  display: block;
  position: absolute;
  height: 75px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.filterOpen {
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 18px;
  margin-left: -1px;
  margin-right: -1px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  overflow-y: auto;
}
@media (min-width: 1025px) {
  .filterOpen {
    max-height: 65vh;
  }
}
.filterOpen .button:not(.tag) {
  display: block;
}
.filterOpen .button.filterOrder .icon {
  margin-right: 15px;
}
.filterOpen .button.filterOrder.active {
  color: #e6004c;
}
.filterOpen.productType {
  width: calc(50% + 2px);
  left: 0;
}
.filterOpen.areasOfInterest {
  width: calc(25% + 2px);
  left: calc(25% + 2px);
}
.filterOpen.version {
  width: 50%;
  right: 0px;
}
.filterOpen.language {
  width: 50%;
}
.filterOpen.productType,
.filterOpen.areasOfInterest,
.filterOpen.version,
.filterOpen.language {
  text-align: center;
}
.filterOpen.productType > div,
.filterOpen.areasOfInterest > div,
.filterOpen.version > div,
.filterOpen.language > div {
  margin: 0 auto !important;
  display: inline-block;
}
.filterOpen.productType > div .button,
.filterOpen.areasOfInterest > div .button,
.filterOpen.version > div .button,
.filterOpen.language > div .button {
  text-align: left;
  display: block;
  width: auto;
  padding: 5px 0;
}
.filterOpen.productType > div .button span,
.filterOpen.areasOfInterest > div .button span,
.filterOpen.version > div .button span,
.filterOpen.language > div .button span {
  font-size: 13px;
  color: #e6004c;
}
.filterOpen.productType .button,
.filterOpen.areasOfInterest .button,
.filterOpen.version .button,
.filterOpen.language .button {
  position: relative;
}
.filterOpen.productType .button .check,
.filterOpen.areasOfInterest .button .check,
.filterOpen.version .button .check,
.filterOpen.language .button .check {
  position: absolute;
  left: -25px;
  color: #e6004c;
}
.filterOpen.areasOfInterest {
  height: auto;
}
.filterOpen.areasOfInterest.entering.filterOpen .button {
  padding-left: 20px;
  position: relative;
}
.filterOpen.areasOfInterest.entering.filterOpen .button.etc.cta {
  margin-left: 20px;
  margin-top: 10px;
}
.filterOpen.areasOfInterest.entering.filterOpen .button.active .icon.check {
  left: -7px;
  top: 3px;
}
.filterOpen.areasOfInterest.expanded {
  width: calc(100% + 2px);
  left: 0;
  height: 630px;
  justify-content: center;
}
.filterOpen.areasOfInterest.expanded > div {
  width: 100%;
  height: 100%;
}
.filterOpen.areasOfInterest.expanded > div .scrollbarHolder {
  flex: 1;
  width: 100%;
  height: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .filterOpen.areasOfInterest.expanded > div .scrollbarHolder {
    flex: 1 0 auto;
  }
}
.filterOpen.areasOfInterest.expanded .etc {
  margin-top: 20px;
}
.filterOpen.other .etc {
  display: inline-flex !important;
}
.filterOpen.price {
  width: calc(50% + 2px);
  left: 25%;
  padding-top: 30px;
}
.filterOpen.exiting {
  opacity: 0.2;
}
.filter {
  margin: 0 20px 20px 20px;
}
.filter > .link {
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}
.filter > .link,
.filter > .button {
  color: #000;
}
.filter .collapsibleHeading {
  line-height: 50px;
}
.filter .collapsibleContent {
  padding: 20px;
  margin-top: 10px;
}
.filter .collapsibleContent .button {
  display: block;
  margin: 10px auto;
  color: #000;
}
.filter .collapsibleContent .button span {
  color: #e6004c;
}
.filter .collapsibleContent .button::before {
  display: inline-block;
}
.filter .collapsibleContent .button em {
  font-style: normal;
  color: #e6004c;
  opacity: 0.5;
}
.filter .collapsibleContent .button.active {
  color: #000;
}
.filter .collapsibleContent .button.active em {
  opacity: 1;
}
.filter .collapsibleContent .button.tag {
  margin: 5px 4px;
  display: inline-block;
  min-height: 30px;
  text-align: left;
  color: #e6004c;
  border-color: #e6004c;
}
@media (max-width: 1024px) {
  .filter .collapsibleContent .button.tag {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.filter .collapsibleContent .button.default:not(.tag) {
  width: 45px;
}
.filter .collapsibleContent .button.default::before {
  content: none;
}
.filter .collapsibleContent .filterOrder {
  margin: 10px 0;
}
.filter .collapsibleContent .filterOrder:before {
  content: none;
}
.filter .collapsibleContent .filterOrder.active {
  color: #e6004c;
}
.filter .collapsibleContent .filterOrder .icon {
  margin-right: 20px;
}
.filter .collapsibleContent .tagList .button span {
  color: inherit;
}
.filter.noCheck .collapsibleContent > .button::before {
  content: '';
  display: none;
}
.filter.collapsed .collapsibleHeading {
  background-color: #f5f3f3;
  color: #e6004c;
  border-color: #000;
}
.filter.inactive {
  color: #525252;
}
.filter.inactive > .button,
.filter.inactive > .link {
  color: #525252;
}
.input-range__slider {
  appearance: none;
  background: #fff;
  border: 2px solid #e6004c;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  width: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.54rem;
  outline: none;
  position: absolute;
  top: 50%;
  -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.input-range__slider:active {
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #f5f3f3;
  border: 2px solid #b3b1b1;
  box-shadow: none;
  transform: none;
}
.input-range__slider-container {
  transition: left 0.3s ease-out;
}
.input-range__label {
  color: #000;
  font-family: inherit;
  font-size: 0;
  transform: translateZ(0);
  white-space: nowrap;
}
.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}
.input-range__label--min.input-range__label--min,
.input-range__label--max.input-range__label--min {
  left: 0;
}
.input-range__label--min.input-range__label--max,
.input-range__label--max.input-range__label--max {
  right: 0;
}
.input-range__label--min.input-range__label--max .input-range__label-container,
.input-range__label--max.input-range__label--max .input-range__label-container {
  left: 50%;
}
.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}
.input-range__label-container {
  left: -50%;
  position: relative;
}
.input-range__track {
  background: #b3b1b1;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 2px;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
  background: #b3b1b1;
}
.input-range__track.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}
.input-range__track.input-range__track--active {
  background-color: #000;
}
.input-range {
  height: 1rem;
  position: relative;
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 1025px) {
  .input-range {
    width: calc(100% - 70px);
  }
}
.priceRangeLabel {
  display: block;
  margin-top: 10px;
  width: 100%;
  color: #000;
  text-align: center;
}
.priceRangeLabel.inactive {
  color: #525252;
}
.filtersHeader p {
  padding-top: 2.5px;
}
.filtersHeader .orderForStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.filtersHeader .orderForStyle p {
  padding-top: 2.5px;
}
.filtersHeader .orderForStyle .filterOptionSelected {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 2px solid;
  border-color: #000;
  opacity: 0.5;
}
.filtersHeader .orderForStyle .filterOptionSelected p {
  padding-top: 2.5px;
  margin-left: 5px;
  color: #000;
}
.filtersHeader .orderForStyle .filterOptionSelected .calendar {
  color: #000;
}
.abc {
  position: relative;
  height: 50px;
}
.abc.fixed {
  position: fixed;
  top: 170px;
  z-index: 10000;
  width: 100%;
  left: 0;
  background-color: #fff;
}
@media screen and (max-width: 1024px) {
  .abc.fixed {
    top: 120px;
  }
}
.abc .buttonHolder {
  width: 100%;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1025px) {
  .abc .buttonHolder {
    justify-content: center;
  }
}
.abc .buttonHolder .relevance {
  border: none;
  width: auto;
  color: #000;
  position: relative;
}
.abc .buttonHolder .relevance:after {
  content: '';
  background: #525252;
  width: 2px;
  height: 50%;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.abc .buttonHolder .relevance:hover,
.abc .buttonHolder .relevance.active {
  color: #e6004c;
}
.abc > .button {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  background-size: 100% auto;
  background-repeat: repeat-y;
  z-index: 1;
  width: 30px;
  pointer-events: none;
}
.abc > .button .icon {
  pointer-events: all;
}
.abc > .button.prev {
  left: 0;
  background-image: url('https://pics.imgix.net/img/white-fade-left.png');
}
.abc > .button.prev .icon {
  margin-left: -15px;
}
.abc > .button.next {
  right: 0;
  background-image: url('https://pics.imgix.net/img/white-fade-right.png');
}
.abc > .button.next .icon {
  margin-right: -15px;
  margin-left: auto;
  transform: rotate(180deg);
}
.abc .scrollbarHolder {
  height: 50px;
  position: relative;
}
.abc .buttonGroup {
  max-height: 50px;
  height: 100%;
  width: auto;
}
.abc .buttonGroup .button {
  flex: 0 0 auto;
  padding: 10px 10px 5px 10px;
  user-select: none;
}
.abc .buttonGroup .button.inactive {
  color: #525252;
  pointer-events: none;
}
.abc .buttonGroup .button.active {
  color: #e6004c;
}
@media screen and (min-width: 1025px) {
  .abc .buttonGroup {
    justify-content: center;
  }
}
.tags {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.tags.collapsibleContent {
  justify-content: flex-start;
}
.tag {
  color: #e6004c;
  padding: 0 13px 0 13px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 15px;
  margin: 5px 4px;
}
.tag:not(.soon, .tag .stock, .tag .noStock) {
  min-height: 30px;
}
.tag.soon,
.tag.eBook,
.tag.stock,
.tag.noStock {
  background-color: #b3b1b1;
  cursor: text;
  line-height: 23px;
  padding-top: 2px;
  max-height: 25px;
  margin-left: 0;
  color: #fff;
  border: none;
}
.tag.soon.stock,
.tag.eBook.stock,
.tag.stock.stock,
.tag.noStock.stock {
  background-color: #0dd9d6;
}
@media screen and (min-width: 1025px) {
  .tag {
    color: #000;
    border-color: #000;
  }
  .tag:hover {
    color: #e6004c;
    border-color: #e6004c;
  }
  .tag.soon:hover,
  .tag.stock:hover,
  .tag.eBook:hover,
  .tag.noStock:hover {
    color: #fff;
  }
}
.tag.filter {
  width: auto;
  padding: 0 15px;
  font-size: 14px;
  background-color: #fff;
}
.tag.filter.active,
.tag.filter:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.tag.filter span {
  font-size: 10px;
  letter-spacing: -0.08;
  margin-left: 5px;
}
.tagList.green .tag.filter {
  color: #9bd315;
  border-color: #9bd315;
}
.tagList.green .tag.filter.active,
.tagList.green .tag.filter:hover {
  color: #fff;
  background-color: #9bd315;
  border-color: #9bd315;
}
.tagList.teal .tag.filter {
  color: #0dd9d5;
  border-color: #0dd9d5;
}
.tagList.teal .tag.filter.active,
.tagList.teal .tag.filter:hover {
  color: #fff;
  background-color: #0dd9d5;
  border-color: #0dd9d5;
}
.tagList.orange .tag.filter {
  color: #e99c5b;
  border-color: #e99c5b;
}
.tagList.orange .tag.filter.active,
.tagList.orange .tag.filter:hover {
  color: #fff;
  background-color: #e99c5b;
  border-color: #e99c5b;
}
.tagList.grey .tag.filter {
  color: #b3b1b1;
  border-color: #b3b1b1;
}
.tagList.grey .tag.filter.active,
.tagList.grey .tag.filter:hover {
  color: #fff;
  background-color: #b3b1b1;
  border-color: #b3b1b1;
}
.tagList.black .tag.filter {
  color: #000;
  border-color: #000;
}
.tagList.black .tag.filter.active,
.tagList.black .tag.filter:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.tagList.green .tag.filter.nextPublications {
  color: #e6004c;
  border-color: #e6004c;
}
.tagList.green .tag.filter.nextPublications.active,
.tagList.green .tag.filter.nextPublications:hover {
  color: #fff;
  background-color: #e6004c;
  border-color: #e6004c;
}
.tagList {
  margin: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .tagList {
    flex-direction: column;
  }
}
.tagList > span {
  margin: 0 10px 0 0;
  display: inline-block;
  font-size: 22px;
}
.tagList.start > span {
  margin-top: 5px;
}
.tagList .tagListWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
@media (max-width: 1024px) {
  .tagList.priceOther {
    align-items: center;
    flex-direction: row;
  }
}
.tag.green {
  color: #9bd315;
  border-color: #9bd315;
}
.tag.green.active,
.tag.green:hover {
  color: #fff;
  background-color: #9bd315;
  border-color: #9bd315;
}
.tag.teal {
  color: #0dd9d5;
  border-color: #0dd9d5;
}
.tag.teal.active,
.tag.teal:hover {
  color: #fff;
  background-color: #0dd9d5;
  border-color: #0dd9d5;
}
.tag.orange {
  color: #e99c5b;
  border-color: #e99c5b;
}
.tag.orange.active,
.tag.orange:hover {
  color: #fff;
  background-color: #e99c5b;
  border-color: #e99c5b;
}
.tag.grey {
  color: #b3b1b1;
  border-color: #b3b1b1;
}
.tag.grey.active,
.tag.grey:hover {
  color: #fff;
  background-color: #b3b1b1;
  border-color: #b3b1b1;
}
.tag.black {
  color: #000;
  border-color: #000;
}
.tag.black.active,
.tag.black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.popUp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  justify-content: flex-end;
}
.popUp.modal {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
}
.popUp.modal .modal {
  text-align: left;
}
.popUp.modal .modal img {
  margin-bottom: 40px;
}
.popUp.modal .modal h1 {
  font-size: 1.3rem;
  margin-bottom: 15px;
}
.popUp.modal .modal p {
  margin-bottom: 15px;
}
.popUp.modal .modal p a {
  color: #e6004c;
  text-decoration: none;
}
.popUp.modal:not(.scroll) .modal {
  max-width: 470px;
}
.popUp.modal.scroll {
  overflow-y: scroll;
  justify-content: flex-start;
}
.popUp .modal {
  background-color: #fff;
  display: block;
  width: 100%;
  padding: 30px 20px;
  position: relative;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .popUp .modal {
    overflow-y: auto;
  }
}
.popUp .modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.popUp .modal .actionsHolder {
  padding-bottom: 0;
  padding-top: 20px;
}
.popUp#cookies {
  pointer-events: none;
}
.popUp#cookies .modal {
  pointer-events: auto;
  border: none;
  background-color: rgba(20, 20, 20, 0.8);
  color: #fff;
}
.popUp#cookies .modal h2,
.popUp#cookies .modal p {
  text-align: center;
}
.popUp#cookies .modal i {
  cursor: pointer;
}
.popUp#cookies .modal i:hover {
  color: #e6004c;
}
.popUp#cookies .modal .link {
  color: #e6004c;
}
.popUp#cookies .modal .link:hover {
  color: #cc1451;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-top-24 {
  margin-top: -24px;
}
.padding-top-25 {
  padding-top: 25px;
}
.text-right {
  text-align: right;
}
.zIndex {
  z-index: 99;
}
.cartHeader {
  text-align: center;
  position: fixed;
  top: 50px;
  height: 50px;
  left: 0;
  width: 100vw;
  background-color: #fff;
  transition: left 300ms ease-out;
}
.cartHeader.error .icon {
  background-color: #e6004c;
  border-radius: 50%;
  color: #fff;
}
.cartHeader.sidebarOpen {
  left: 100%;
}
.cartHeader .icon {
  color: #525252;
  margin: 30px -2px/2, 5px;
}
.cartHeader .icon.bullet-check {
  background-color: #0dd9d6;
  border: none;
}
.cartHeader .icon.bullet-check::before {
  color: #fff;
}
.cartHeader .divider {
  margin: 0 15px;
  width: auto;
}
@media screen and (min-width: 1025px) {
  .cartHeader {
    max-width: 1441px;
    left: 50%;
    transform: translateX(-50%);
    top: 80px;
  }
  .cartHeader .divider {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1441px) {
  .cartHeader {
    padding: 0 9% 0;
  }
}
@media screen and (min-width: 1920px) {
  .cartHeader {
    max-width: 1920px;
    padding: 0 2% 0;
  }
}
@media screen and (min-width: 1025px) {
  .cartContent {
    margin: 0 auto;
  }
}
@media screen and (max-width: 1024px) {
  .cartContent {
    margin-top: 20px;
  }
}
.cartContent .cartSummary .options {
  display: flex;
  justify-content: space-between;
}
.cartContent .cartSummary .freeDeliveryWrapper {
  width: 150px;
  margin: 0;
}
.cartContent .cartSummary .freeDeliveryWrapper.ebook {
  display: none;
}
.cartContent .cartSummary .mixedItemsWrapper {
  margin-top: 20px;
}
.cartToggle .button {
  color: #525252;
}
.cartToggle .button .img {
  opacity: 0.3;
}
.cartToggle .button .shippingOptions {
  opacity: 1;
}
.cartToggle .button:hover {
  z-index: 2;
}
.cartToggle .button.active {
  color: #000;
  background-color: #dedddd;
  z-index: 3;
}
.cartToggle .button.active .img {
  opacity: 1;
}
.cartToggle .button.address {
  text-align: left;
  padding: 30px 20px;
}
.cartToggle .button.address.add {
  text-align: center;
}
.cartToggle .button.shipping {
  padding: 0 20px;
  max-height: 170px;
  min-height: 170px;
  position: relative;
}
.cartToggle .button.shipping:before {
  left: -2px;
  top: 10px;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 900;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
}
.cartToggle .button.shipping .img {
  height: 45px;
  width: auto;
  display: block;
  margin: 0 auto 10px auto;
}
.cartToggle .button.shipping em {
  display: block;
}
.cartToggle .button.shipping .price {
  display: block;
  margin-top: 5px;
  text-align: center;
}
.cartToggle .button.economyShipping {
  padding: 0 20px;
  max-height: 170px;
  min-height: 170px;
  position: relative;
}
.cartToggle .button.economyShipping:before {
  content: 'Económico';
  background-color: #e6004c;
  position: absolute;
  left: -2px;
  top: 10px;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 900;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
}
.cartToggle .button.freeShipping {
  padding: 0 20px;
  max-height: 170px;
  min-height: 170px;
  position: relative;
}
@media (max-width: 736px) {
  .cartToggle .button.freeShipping {
    padding: 50px 20px 20px;
    min-height: 200px;
    max-height: 200px;
  }
}
.cartToggle .button.freeShipping:before {
  content: 'Envío Gratis';
  background-color: #e6004c;
  position: absolute;
  left: -2px;
  top: 10px;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 900;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
}
.cartToggle .button.freeShipping .img {
  height: 45px;
  width: auto;
  display: block;
  margin: 0 auto 10px auto;
}
.cartToggle .button.freeShipping em {
  display: block;
}
.cartToggle .button.freeShipping .price {
  display: block;
  margin-top: 5px;
  text-align: center;
}
.cartToggle .button.payment {
  height: 50px;
}
@media (min-width: 737px) {
  .cartToggle .button.payment {
    padding-left: 10px;
    padding-right: 10px;
  }
  .cartToggle .button.payment > .holder {
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 736px) {
  .cartToggle .button.payment {
    flex-direction: column;
    height: auto;
    padding: 10px 10px 20px;
  }
  .cartToggle .button.payment > .holder {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
.cartToggle .button.payment span {
  padding-top: 4px;
  margin-right: 10px;
}
.cartToggle .button.payment .visa {
  height: 13px;
  width: auto;
  margin-right: 10px;
}
.cartToggle .button.payment .american {
  height: 30px;
  width: auto;
  margin-right: 10px;
}
.cartToggle .button.payment .mastercard {
  height: 18px;
  margin-right: 10px;
  width: auto;
}
.cartToggle .button.payment .paypal {
  height: 17px;
  width: auto;
}
.cartToggle .button.payment.disabled {
  pointer-events: none;
  background-color: #fff !important;
}
@media (max-width: 736px) {
  .cartToggle .button.payment img {
    margin: 10px;
  }
}
.cartToggle.noMargin .button {
  margin: -2px 0 0 0;
}
.cartToggle.noMargin .button:first-of-type {
  margin-top: 0;
}
.cartToggle.noMargin.horizontal .button {
  height: 50px;
  margin-top: 0;
}
.cartToggle.noMargin.horizontal .button + .button {
  margin-left: -2px;
}
.cartToggle.noMargin.horizontal .active + .button {
  border-left: 2px solid #000;
}
.cartToggle.noMargin.vertical .button + .button {
  margin-top: -2px;
}
.cartToggle.noMargin.vertical .active + .button {
  border-top: none;
}
@media screen and (min-width: 1025px) {
  .cartToggle.noMargin.vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .cartToggle.noMargin.vertical .button + .button {
    margin-top: 0;
    margin-left: -2px;
  }
  .cartToggle.noMargin.vertical .active + .button {
    border-left: 2px solid #000;
    border-top: 2px solid #b3b1b1;
  }
  .cartToggle.noMargin.vertical .active + .button:hover {
    border-top: 2px solid #e6004c;
  }
}
.cartToggle.disabled {
  opacity: 0.5;
  pointer-events: none !important;
}
.distribution .cartToggle.disabled + p {
  opacity: 0.5;
}
.deliveryInfo p {
  padding-bottom: 10px;
}
.deliveryInfo p.comment {
  color: #525252;
}
.cartCalc {
  padding-bottom: 10px;
  align-items: flex-start;
}
@media (max-width: 736px) {
  .cartCalc {
    align-items: center;
  }
}
.cartCalc .promo {
  position: relative;
  width: 100%;
}
.cartCalc .promo .form {
  justify-content: flex-start;
  flex: 0 0 auto;
  width: 100%;
  margin-left: 0;
}
.cartCalc .promo .form > .holder {
  width: 100%;
}
.cartCalc .promo .form .field {
  width: calc(100% - 150px);
  margin-bottom: 0;
  margin-right: 10px;
}
.cartCalc .promo .form .field .input::-webkit-search-cancel-button {
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: 5px;
}
.cartCalc .promo .form .field .icon {
  cursor: pointer;
}
.cartCalc .promo .form .field .icon:hover {
  color: #e6004c;
}
.cartCalc .promo .feedback {
  padding: 10px 0 0;
  text-align: left;
  width: 100%;
  animation: fadeIn 700ms ease;
}
.cartCalc .promo .feedback .icon {
  margin-right: 10px;
}
.cartCalc .promo .feedback .icon:before {
  font-size: 30px;
  color: #00a393;
}
.cartCalc .promo .feedback .icon.close:before {
  color: #e6004c;
}
.cartCalc .promo .feedback p {
  color: #00a393;
}
.cartCalc .promo .feedback.error p {
  color: #e6004c;
}
.cartCalc .promo .feedback.hide {
  transition: opacity 500ms ease-out;
  opacity: 0;
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    padding-left: 0;
  }
  50% {
    opacity: 0;
    padding-left: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    padding-left: 0;
  }
  50% {
    opacity: 0;
    padding-left: 0;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    padding-left: 0;
  }
  50% {
    opacity: 0;
    padding-left: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    padding-left: 0;
  }
  50% {
    opacity: 0;
    padding-left: 0;
  }
}
@media (max-width: 1024px) and (min-width: 737px) {
  .cartCalc .promo .feedback {
    margin-top: 10px;
  }
}
.cartCalc .taxes {
  margin-bottom: 30px;
  padding: 0 10px;
  width: 100%;
}
.cartCalc .taxes p {
  width: 100%;
}
.cartCalc .taxes p.coupon {
  width: 100%;
  margin-bottom: 10px;
  font-style: italic;
  font-size: 14px;
  position: relative;
  /* strong
				flex none */
}
@media screen and (max-width: 736px) {
  .cartCalc .taxes p.coupon .holder {
    flex-direction: row;
  }
}
.cartCalc .taxes p.coupon .button {
  margin-left: 10px;
  margin-bottom: 2px;
}
.cartCalc .taxes p.coupon .button:hover {
  color: #e6004c;
}
.cartCalc .taxes p.subtotal {
  color: #000;
  margin-bottom: 10px;
}
.cartCalc .taxes p.iva,
.cartCalc .taxes p.tax {
  color: #525252;
  margin-bottom: 5px;
}
.cartCalc .taxes p.tax strong {
  flex: 0 0 50%;
}
.cartCalc .taxes p.total {
  color: #00a393;
  margin-top: 10px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cartCalc .taxes p.total {
    font-size: 16px;
  }
}
@media screen and (min-width: 1025px) {
  .cartCalc {
    align-items: flex-start;
  }
  .cartCalc .taxes {
    flex: 0 0 auto;
    width: 35%;
    order: 2;
    margin-bottom: 0;
  }
  .cartCalc .taxes .subtotal strong {
    flex: auto;
  }
  .cartCalc .taxes .iva,
  .cartCalc .taxes .tax {
    font-style: italic;
    font-size: 14px;
  }
  .cartCalc .taxes .total {
    font-size: 26px;
    line-height: 1em;
  }
  .cartCalc .taxes:first-child,
  .cartCalc .taxes:last-child {
    margin-left: auto;
  }
  .cartCalc .holder {
    width: 60%;
  }
}
@media screen and (max-width: 1024px) {
  .cartCalc {
    flex-wrap: nowrap;
    flex-direction: column-reverse;
  }
  .cartCalc .holder {
    width: 100%;
  }
  .cartCalc .holder > div {
    width: calc(100% - 5px);
    min-width: 250px;
  }
  .cartCalc .promo .feedback {
    padding: 10px 0 0;
    justify-content: center;
    position: relative;
    right: 0;
    top: 0;
  }
  .cartCalc .taxes p.coupon .button {
    left: 190px;
  }
}
@media screen and (max-width: 736px) {
  .cartCalc .holder {
    flex-direction: column-reverse;
  }
  .cartCalc .holder > div {
    width: 100%;
  }
  .cartCalc .holder .promo {
    max-width: 100%;
  }
  .cartCalc .holder .promo .form .holder {
    flex-direction: row;
  }
  .cartCalc .holder .promo .form .holder .field {
    min-width: 0;
  }
  .cartCalc .holder .promo .feedback {
    top: 15px;
  }
}
@media screen and (min-width: 1025px) {
  .cart .field.userRemovable {
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 1025px) {
  .cart .text.noSidePad .center {
    text-align: left;
  }
}
.cartItem {
  margin-bottom: 15px;
}
.cartItem .img {
  flex: 0 0 auto;
  width: 110px;
  height: 110px;
  object-fit: contain;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cartItem .img {
    width: auto;
  }
}
.cartItem .itemData {
  flex: 1 1 100%;
  padding: 5px 5px 5px 5px;
}
.cartItem .itemData p {
  flex: 0 0 auto;
}
.cartItem .itemData p.delivery,
.cartItem .itemData p.charge {
  margin-top: 4px;
  font-style: italic;
  max-width: 100%;
  color: #d48216;
}
.cartItem .itemData p.title {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: 45.2px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cartItem .itemData .cartItemFooter {
  flex: 1 1 auto;
  justify-content: space-between;
}
.cartItem .itemData .cartItemFooter .price {
  line-height: 35px;
}
@media screen and (max-width: 1024px) {
  .cartItem .itemData .cartItemFooter {
    width: 100%;
    margin-top: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .cartItem .itemData {
    position: relative;
    padding-right: 40%;
  }
  .cartItem .itemData p {
    flex: 1 1 100%;
  }
  .cartItem .itemData .cartItemFooter {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    padding-left: 20px;
  }
}
.itemCounter {
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  min-width: 80px;
  text-align: center;
  padding: 0 6px;
}
.itemCounter.disabled {
  border: none;
  padding-right: 20px;
}
.distribution {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
}
@media (min-width: 737px) {
  .distribution {
    margin-top: 20px;
  }
}
.distribution .cartToggle {
  flex: 1 1 100%;
}
.distribution p {
  flex: 1 1 100%;
  text-align: center;
  margin-top: 10px;
}
@media screen and (min-width: 1025px) {
  .distribution {
    order: 3;
    flex: 0 0 auto;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .distribution .cartToggle {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .distribution p {
    text-align: left;
    padding-left: 20px;
  }
}
.thankyou {
  background-color: #f5f3f3;
}
.thankyou h2 {
  text-align: center;
}
.cartFooter {
  align-items: center;
}
.cartFooter .cta a {
  text-decoration: none;
  color: #e6004c;
}
.cartFooter .message {
  flex: 1 1 100%;
  padding-left: 0;
  padding-right: 20px;
  text-align: right;
  margin-top: 0;
}
.cartFooter .blank {
  width: 130px;
  flex: 0 0 auto;
}
.icon.caChing {
  position: relative;
}
.icon.caChing::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e6004c;
  opacity: 0;
  height: 0;
  width: 0;
  border-radius: 50%;
  animation: buyButton 700ms cubic-bezier(0, -0.03, 0, 0.99);
}
@-moz-keyframes buyButton {
  80% {
    height: 40px;
    width: 40px;
    opacity: 0.4;
  }
  100% {
    height: 60px;
    width: 60px;
    opacity: 0;
  }
}
@-webkit-keyframes buyButton {
  80% {
    height: 40px;
    width: 40px;
    opacity: 0.4;
  }
  100% {
    height: 60px;
    width: 60px;
    opacity: 0;
  }
}
@-o-keyframes buyButton {
  80% {
    height: 40px;
    width: 40px;
    opacity: 0.4;
  }
  100% {
    height: 60px;
    width: 60px;
    opacity: 0;
  }
}
@keyframes buyButton {
  80% {
    height: 40px;
    width: 40px;
    opacity: 0.4;
  }
  100% {
    height: 60px;
    width: 60px;
    opacity: 0;
  }
}
.emptyCart {
  color: #e6004c;
  font-size: 12px;
  padding-top: 5px;
  padding-left: 10px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .emptyCart {
    letter-spacing: 1px;
  }
}
.emptyCart .icon {
  margin-top: -4px;
  margin-left: 5px;
}
.cart-message {
  border: 2px solid #333;
  padding: 20px;
  color: #d48216;
  margin-bottom: 30px;
}
.cart-message p {
  margin-bottom: 20px;
}
.cart-message p:last-child {
  margin-bottom: 0;
}
.priceShipping {
  display: block;
  text-align: center;
  margin-left: 10px;
}
.addressInfo {
  margin-top: 20px;
}
.stripeModalWrapper .modal {
  border-width: 3px;
}
.stripeModalWrapper .popUp.modal:not(.scroll) .modal {
  max-width: 550px;
}
@media screen and (max-width: 320px) {
  .stripeModalWrapper .popUp.modal:not(.scroll) .modal {
    min-height: 100%;
  }
}
.stripeModalWrapper form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stripeModalWrapper form .securePayment {
  border-bottom: 3px solid #000;
  padding-bottom: 20px;
}
.stripeModalWrapper form .securePayment .lock {
  margin-right: 5px;
  background: #000;
}
.stripeModalWrapper form .securePayment button {
  border: none;
  padding: 0;
  color: #e6004c;
  font-size: 13px;
  text-align: left;
  font-weight: 900 !important;
  min-height: auto;
}
.stripeModalWrapper form .securePayment button:hover {
  text-decoration: underline;
}
.stripeModalWrapper form .securePayment .securePaymentModal {
  position: absolute;
  z-index: 1;
  background: #faf8fc;
  border: 3px solid #000;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
}
@media screen and (max-width: 320px) {
  .stripeModalWrapper form .securePayment .securePaymentModal {
    max-height: 90%;
    overflow-y: scroll;
  }
}
.stripeModalWrapper form .securePayment .securePaymentModal button {
  text-align: right;
}
.stripeModalWrapper .button {
  border-width: 3px;
  font-size: 17px;
}
.stripeModalWrapper .button:disabled,
.stripeModalWrapper .button.disabled {
  pointer-events: none;
  opacity: 0.5;
  color: #fff;
}
.stripeModalWrapper .inputsWrapper {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 20px;
}
.stripeModalWrapper .inputsWrapper label {
  color: #525252;
  font-size: 12px;
  letter-spacing: 0.1em;
}
.stripeModalWrapper .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 4em;
  font-family: 'Frutiger', monospace;
  border: 3px solid #000;
  outline: 0;
  border-radius: 1px;
  background: #fff;
}
.stripeModalWrapper .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.11) 0 4px 6px, rgba(0, 0, 0, 0.078) 0 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
.stripeModalWrapper .cardNumber {
  width: 100%;
}
.stripeModalWrapper .cardDate {
  width: 60%;
  margin-right: 10px;
}
.stripeModalWrapper .cardCode {
  width: 35%;
}
.stripeModalWrapper .ElementsApp,
.stripeModalWrapper .ElementsApp .InputElement {
  text-align: center;
}
.feedback {
  text-align: center;
  color: #525252;
  padding: 30px;
}
.form .feedback {
  text-align: right;
  padding: 2px 20px 0 20px;
  margin: auto 0;
  flex: 1 1 auto;
  font-style: italic;
}
.form .feedback.success {
  color: #00a393;
}
.form .feedback.error {
  color: #e6004c;
}
.feedback.cartFloat {
  background-color: rgba(226, 226, 226, 0.95);
  transform: translateX(-50%);
  top: 175px;
  max-width: 1920px;
  border-radius: 10px;
  margin-top: 20px;
  position: fixed;
  width: calc(100% - 40px);
  left: 50%;
  color: #000;
  font-size: 14px;
  animation: cartFloatIn 700ms ease;
}
.feedback.cartFloat.success {
  background-color: rgba(220, 245, 244, 0.95);
  color: #00a393;
}
.feedback.cartFloat.error {
  color: #cc1451;
  background-color: rgba(255, 206, 222, 0.95);
}
.feedback.cartFloat.info {
  color: #d48216;
  background-color: rgba(255, 238, 206, 0.95);
}
.feedback.cartFloat.hide {
  transition: opacity 700ms ease-out;
  opacity: 0;
}
@-moz-keyframes cartFloatIn {
  0% {
    font-size: 0;
    padding: 0;
    margin-top: 0;
  }
  50% {
    font-size: 0;
    padding: 0;
    margin-top: 0;
  }
}
@-webkit-keyframes cartFloatIn {
  0% {
    font-size: 0;
    padding: 0;
    margin-top: 0;
  }
  50% {
    font-size: 0;
    padding: 0;
    margin-top: 0;
  }
}
@-o-keyframes cartFloatIn {
  0% {
    font-size: 0;
    padding: 0;
    margin-top: 0;
  }
  50% {
    font-size: 0;
    padding: 0;
    margin-top: 0;
  }
}
@keyframes cartFloatIn {
  0% {
    font-size: 0;
    padding: 0;
    margin-top: 0;
  }
  50% {
    font-size: 0;
    padding: 0;
    margin-top: 0;
  }
}
.feedback.chooseAreas {
  padding-top: 20px;
}
.message {
  font-size: 11px;
  font-style: italic;
  margin-top: 4px;
  padding-left: 5px;
}
.message.error {
  color: #e6004c;
}
.message.success {
  color: #00a393;
}
#rutas {
  padding: 5px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
#rutas .scrollbarHolder {
  height: 100%;
  width: 100%;
}
#rutas p {
  line-height: 20px;
  font-size: 11px;
  background-color: #eee;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto 5px auto;
  padding: 20px 10px;
  border-radius: 3px;
  text-align: left;
  font-weight: normal;
  letter-spacing: 0.06em;
}
#rutas p:nth-of-type(2) {
  margin-top: 50px;
}
#rutas p:first-of-type {
  background: #fff;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 10px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
#rutas p:first-of-type span a {
  width: 35px;
  text-align: center;
}
#rutas p strong {
  font-weight: normal;
  flex: 1 1 100%;
  padding-left: 10px;
}
#rutas p span {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
#rutas p span a {
  text-align: center;
}
#rutas p span a:nth-of-type(1) {
  display: block;
}
@media screen and (min-width: 320px) {
  #rutas p span a:nth-of-type(1) {
    display: none;
  }
}
#rutas p span a:nth-of-type(2) {
  display: none;
}
@media screen and (min-width: 320px) and (max-width: 1920px) {
  #rutas p span a:nth-of-type(2) {
    display: block;
  }
}
#rutas p span a:nth-of-type(3) {
  display: none;
}
@media screen and (min-width: 1025px) {
  #rutas p span a:nth-of-type(3) {
    display: block;
  }
}
#rutas p span a i {
  opacity: 0.2;
  display: block;
  margin: auto 10px;
  height: 15px;
  width: 15px;
  background-color: #808080;
  border-radius: 50%;
  transition: all 300ms ease-out;
}
#rutas p span a.ok i {
  opacity: 1;
  background-color: #32cd32;
}
#rutas p span a.ok i:hover {
  box-shadow: 0px 0px 5px #ff0;
}
.p01-style {
  background-color: #f00;
}
.p01-style p {
  color: #008000;
}
.knowAxon-style {
  margin: 50px 0 150px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}
.knowAxon-style.isLogged {
  margin: 100px 0 100px 0;
}
@media screen and (max-width: 1440px) {
  .knowAxon-style {
    overflow-y: hidden;
  }
}
@media screen and (max-width: 1024px) {
  .knowAxon-style {
    overflow-y: hidden;
  }
}
@media screen and (max-width: 737px) {
  .knowAxon-style {
    padding: 0 35px;
  }
}
@media screen and (max-width: 736px) {
  .knowAxon-style {
    margin: 50px 0 50px 0;
  }
}
.knowAxon-style .maxContainer {
  width: calc(100% - 120px);
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}
@media screen and (min-width: 1025px) {
  .knowAxon-style .maxContainer {
    padding: 0 75px;
  }
}
@media screen and (max-width: 736px) {
  .knowAxon-style .maxContainer {
    width: calc(100% - 60px);
  }
}
.knowAxon-style .maxContainer .contentBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  height: 100%;
  min-height: 400px;
}
@media screen and (max-width: 1024px) {
  .knowAxon-style .maxContainer .contentBox {
    width: 100%;
    height: auto;
    margin-bottom: 75px;
    min-height: 0;
  }
}
.knowAxon-style .maxContainer .contentBox .textContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.knowAxon-style .maxContainer .contentBox .textContent h3 {
  width: 100%;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  padding: 25px 0 17.5px 0;
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 22px;
}
@media screen and (max-width: 480px) {
  .knowAxon-style .maxContainer .contentBox .textContent h3 {
    line-height: 35px;
  }
}
.knowAxon-style .maxContainer .contentBox .textContent p {
  width: 100%;
  text-align: left;
  letter-spacing: auto;
  line-height: 30px;
  font-size: 0.85rem;
}
.knowAxon-style .maxContainer .contentBox .textContent p a {
  color: #e6004c;
  text-decoration: none;
}
.knowAxon-style .maxContainer .contentBox .textContent p a:hover {
  color: #a21d48;
}
.knowAxon-style .maxContainer .contentBox .contactContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 736px) {
  .knowAxon-style .maxContainer .contentBox .contactContent {
    margin-top: 25px;
  }
}
.knowAxon-style .maxContainer .contentBox .contactContent p {
  font-size: 0.85rem;
  letter-spacing: 2.3px;
  line-height: 30px;
}
@media screen and (max-width: 480px) {
  .knowAxon-style .maxContainer .contentBox .contactContent p {
    text-align: center;
  }
}
.knowAxon-style .maxContainer .contentBox .contactContent p .icon {
  color: #e6004c;
}
.knowAxon-style .maxContainer .contentBox .contactContent p .icon.tel {
  margin-right: 5px;
}
.knowAxon-style .maxContainer .contentBox .contactContent p .icon.dir {
  margin-right: 10px;
}
.knowAxon-style .maxContainer .contentBox .contactContent p a {
  color: #e6004c;
  text-decoration: none;
}
.knowAxon-style .maxContainer .contentBox .contactContent p a:hover {
  color: #a21d48;
}
@media screen and (max-width: 1024px) and (max-width: 737px) {
  .knowAxon-style .maxContainer .contentBox {
    height: auto;
  }
}
.knowAxon-style .maxContainer .imageBox {
  width: 55%;
  height: 100%;
}
@media screen and (min-width: 1025px) and (max-width: 1441px) {
  .knowAxon-style .maxContainer .imageBox {
    position: absolute;
    right: -60px;
  }
}
@media screen and (max-width: 1024px) {
  .knowAxon-style .maxContainer .imageBox {
    width: 100%;
    height: 350px;
  }
}
.knowAxon-style .maxContainer .imageBox img {
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 1024px) and (max-width: 1500px) {
  .knowAxon-style .maxContainer .imageBox.size1 {
    display: none;
  }
}
.knowAxon-style .maxContainer .imageBox.size2 {
  display: none;
}
@media screen and (min-width: 1024px) and (max-width: 1500px) {
  .knowAxon-style .maxContainer .imageBox.size2 {
    display: flex;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1500px) and (max-width: 1441px) {
  .knowAxon-style .maxContainer .imageBox.size2 {
    position: absolute;
    right: -60px;
  }
}
.productsHome-style {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
.productsHome-style .maxContainer {
  width: calc(100% - 120px);
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 1025px) {
  .productsHome-style .maxContainer {
    padding: 0 75px;
    height: 470px;
  }
}
@media screen and (max-width: 736px) {
  .productsHome-style .maxContainer {
    width: calc(100% - 60px);
  }
}
.productsHome-style .maxContainer .titleMobile {
  position: relative;
  width: 100%;
  font-size: 1.5rem;
  font-family: 'FrutigerLTStd-Roman';
  margin-bottom: 35px;
  margin-top: 55px;
}
.productsHome-style .maxContainer .titleMobile::after {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 27.5%;
  background-color: #e6004c;
  border-radius: 100px;
  left: 0;
  top: 30px;
}
.productsHome-style .maxContainer .titleMobile::before {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 70%;
  border-radius: 100px;
  background-color: #000;
  right: 0;
  top: 30px;
}
@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .productsHome-style .maxContainer .titleMobile {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .productsHome-style .maxContainer .titleMobile {
    display: none;
  }
}
.productsHome-style .maxContainer .contentBox {
  position: relative;
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
}
.productsHome-style .maxContainer .contentBox .titleDesktop {
  position: absolute;
  width: 60%;
  font-size: 1.3rem;
  top: 30px;
  font-family: 'FrutigerLTStd-Roman';
}
@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .productsHome-style .maxContainer .contentBox .titleDesktop {
    width: 60%;
  }
}
@media screen and (max-width: 1024px) {
  .productsHome-style .maxContainer .contentBox .titleDesktop {
    display: none;
  }
}
.productsHome-style .maxContainer .contentBox .titleDesktop::after {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 27.5%;
  background-color: #e6004c;
  border-radius: 100px;
  left: 0;
  top: 30px;
}
.productsHome-style .maxContainer .contentBox .titleDesktop::before {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 70%;
  border-radius: 100px;
  background-color: #000;
  right: 0;
  top: 30px;
}
.productsHome-style .maxContainer .contentBox .itemsBoxContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
@media screen and (min-width: 1025px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer {
    margin-top: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer {
    height: auto;
  }
}
.productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox {
  height: 100%;
  width: 20%;
  height: 80%;
}
@media screen and (max-width: 1200px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox {
    height: auto;
  }
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox .bottom h4 {
    display: -webkit-box;
    max-width: 100%;
    line-height: 1.1em;
    max-height: 55px;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    margin-bottom: 20px;
  }
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox .bottom span {
    display: none;
  }
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox .bottom .price {
    color: #00a393;
    text-align: right;
    font-family: 'FrutigerLTStd-Bold';
  }
}
@media screen and (max-width: 1024px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox {
    width: 47.5%;
    margin: 25px 10px;
    max-height: 500px;
  }
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox.secondBigBoss {
    width: 47.5%;
  }
}
@media screen and (max-width: 1024px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox {
    width: 45%;
    margin: 25px 10px;
    max-height: 500px;
  }
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox.secondBigBoss {
    width: 45%;
  }
}
@media screen and (max-width: 736px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox {
    width: 100%;
    margin: 25px 10px;
  }
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox.secondBigBoss {
    width: 100%;
  }
}
.productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox.bigBoss {
  width: 40%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox.bigBoss {
    width: 47.5%;
  }
}
@media screen and (max-width: 1024px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox.bigBoss {
    width: 45%;
  }
}
@media screen and (max-width: 736px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox.bigBoss {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .productsHome-style
    .maxContainer
    .contentBox
    .itemsBoxContainer
    .itemBox.bigBoss
    .previewHolder
    .bottom {
    padding: 0 50px;
  }
  .productsHome-style
    .maxContainer
    .contentBox
    .itemsBoxContainer
    .itemBox.bigBoss
    .previewHolder
    .bottom
    .title {
    font-size: 15px;
  }
  .productsHome-style
    .maxContainer
    .contentBox
    .itemsBoxContainer
    .itemBox.bigBoss
    .previewHolder
    .bottom
    .price {
    font-size: 25px;
  }
  .productsHome-style
    .maxContainer
    .contentBox
    .itemsBoxContainer
    .itemBox.bigBoss
    .previewHolder
    .bottom
    .price
    em {
    font-size: 17px;
  }
}
@media screen and (min-width: 1025px) {
  .productsHome-style .maxContainer .contentBox .itemsBoxContainer .itemBox .previewHolder .bottom {
    min-height: 0;
    padding: 0 15px;
  }
  .productsHome-style
    .maxContainer
    .contentBox
    .itemsBoxContainer
    .itemBox
    .previewHolder
    .bottom
    .title {
    display: -webkit-box;
    max-width: 100%;
    line-height: 1.1em;
    max-height: 55px;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    font-size: 13px;
    min-height: 42px;
    margin-bottom: 20px;
  }
  .productsHome-style
    .maxContainer
    .contentBox
    .itemsBoxContainer
    .itemBox
    .previewHolder
    .bottom
    span {
    display: none;
  }
  .productsHome-style
    .maxContainer
    .contentBox
    .itemsBoxContainer
    .itemBox
    .previewHolder
    .bottom
    .price {
    color: #00a393;
    text-align: right;
    font-family: 'FrutigerLTStd-Bold';
    font-size: 19px;
  }
  .productsHome-style
    .maxContainer
    .contentBox
    .itemsBoxContainer
    .itemBox
    .previewHolder
    .bottom
    .price
    em {
    margin-right: 5px;
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .productsHome-style .itemsBoxContainer .itemBox.bigBoss .productPreview .top div {
    height: 350px;
    margin-bottom: 10px;
  }
  .productsHome-style .itemsBoxContainer .itemBox.bigBoss .productPreview .top div img {
    padding: 0;
    margin: 0;
    width: auto;
    height: 100%;
    max-height: 400px;
    padding-bottom: 20px;
  }
}
.productsHome-style.reverse .maxContainer .contentBox .titleDesktop {
  left: 0;
  top: 55px;
  text-align: right;
}
.productsHome-style.reverse .maxContainer .contentBox .titleDesktop::after {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 70%;
  background-color: #000;
  border-radius: 100px;
  left: 0;
  top: 30px;
}
.productsHome-style.reverse .maxContainer .contentBox .titleDesktop::before {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 27.5%;
  border-radius: 100px;
  background-color: #e6004c;
  right: 0;
  top: 30px;
}
.productsHome-style.reverse .maxContainer .contentBox .itemsBoxContainer {
  flex-direction: row-reverse;
}
.infoTop-style {
  margin: 100px 0;
  margin-bottom: 75px;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 0;
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .infoTop-style {
    margin: 75px 0 35px 0;
  }
}
@media screen and (max-width: 1024px) {
  .infoTop-style {
    margin-top: 0;
    padding-top: 50px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
.infoTop-style .maxContainer {
  width: calc(100% - 120px);
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 1025px) {
  .infoTop-style .maxContainer {
    padding: 0 75px;
  }
}
@media screen and (max-width: 736px) {
  .infoTop-style .maxContainer {
    width: calc(100% - 60px);
    padding: 0;
  }
}
.infoTop-style .maxContainer h1 {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}
.infoTop-style .maxContainer .contentBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.infoTop-style .maxContainer .separatorTop,
.infoTop-style .maxContainer .separatorBottom {
  position: relative;
  width: 100%;
  height: 2px;
  border-radius: 10px;
}
.infoTop-style .maxContainer .contentBox {
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1024px) and (min-width: 480px) {
  .infoTop-style .maxContainer .contentBox {
    margin: 25px 0;
  }
}
.infoTop-style .maxContainer .contentBox .iconsBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.infoTop-style .maxContainer .contentBox .iconsBox .caracteristicBox {
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
@media screen and (max-width: 628px) {
  .infoTop-style .maxContainer .contentBox .iconsBox .caracteristicBox {
    width: 100%;
    margin: 25px 0;
  }
  .infoTop-style .maxContainer .contentBox .iconsBox .caracteristicBox.fullWidth {
    width: 100%;
  }
}
@media screen and (max-width: 321px) {
  .infoTop-style .maxContainer .contentBox .iconsBox .caracteristicBox {
    flex-direction: column;
    width: 100%;
    margin: 25px 0;
  }
  .infoTop-style .maxContainer .contentBox .iconsBox .caracteristicBox.fullWidth {
    width: 100%;
  }
}
.infoTop-style .maxContainer .contentBox .iconsBox .caracteristicBox i {
  margin-right: 40px;
}
@media screen and (max-width: 321px) {
  .infoTop-style .maxContainer .contentBox .iconsBox .caracteristicBox i {
    margin-left: -10.5px;
    margin-right: 0;
    margin-bottom: 5px;
  }
}
.infoTop-style .maxContainer .contentBox .iconsBox .caracteristicBox i::before {
  font-size: 2.25rem;
}
.infoTop-style .maxContainer .contentBox .iconsBox .caracteristicBox p {
  font-size: 1rem;
  margin-top: 5px;
}
.infoTop-style .maxContainer .separatorTop::after {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 27.5%;
  background-color: #e6004c;
  border-radius: 100px;
  left: 0;
}
.infoTop-style .maxContainer .separatorTop::before {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 70%;
  border-radius: 100px;
  background-color: #000;
  right: 0;
}
.infoTop-style .maxContainer .separatorBottom::after {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 70%;
  border-radius: 100px;
  background-color: #000;
  left: 0;
}
.infoTop-style .maxContainer .separatorBottom::before {
  position: absolute;
  content: ' ';
  height: 3px;
  width: 27.5%;
  border-radius: 100px;
  background-color: #e6004c;
  right: 0;
}
.whiteSpace-style {
  height: 150px;
}
.prueba01 {
  background-color: #f00;
  width: 100%;
}
.p01 {
  color: #008000;
}
.cartIco {
  width: 37px;
  position: relative;
  left: -3px;
  top: 1px;
}
.userIco {
  width: 28px;
  margin-left: 8px;
  top: 2px;
  left: -3px;
  position: relative;
}
.userIcolink {
  margin-left: 12px;
}
.cartIcolink,
.userIcolink {
  border-radius: 50%;
  position: relative;
  top: 2px;
  width: 37px;
  height: 37px;
}
.cartIcolink:hover,
.userIcolink:hover {
  background-color: rgb(230 0 76/20%);
  border-radius: 50%;
}
.contenidoProd {
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.userIcoPanel {
  filter: invert(1);
  margin-right: 10px;
}
.userLogged.active .userIcolink {
  width: auto;
}
.userLogged.active .userIcolink:hover {
  background-color: transparent;
}
.userLogged.active .userIcolink span {
  margin-left: 7px;
}
.userLogged.active .userIcolink .userIco {
  margin-left: 3px;
  top: -1px;
}
.sinProduct {
  padding: 40px 28px 2px;
  font-size: 18px;
  text-align: center;
}
@supports (padding-top: constant(safe-area-inset-bottom)) {
  body {
    padding: env(safe-area-inset-bottom) env(safe-area-inset-right) env(safe-area-inset-bottom)
      env(safe-area-inset-left);
  }
}

@media (display-mode: fullscreen) {
  body {
    padding: 0;
  }
}

@media (display-mode: standalone) {
  body {
    padding: 0;
  }
}
.discountImg {
  width: 100%;
  position: relative;
  top: 20px;
}

.bannerImg {
  width: 23%;
  position: absolute;
  top: 120px;
  cursor: pointer;
}
.bannerImg2 {
  width: 60%;
  position: relative;
  cursor: pointer;
}
.discount {
  width: 140px;
  margin-right: 30px;
}
.row.bookPrice.withDiscount {
  justify-content: left !important;
}
.contentWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 5fr 4fr;
  gap: 40px;
}

.contentWrapper2 {
  display: grid;
  align-items: center;
  grid-template-columns: 5fr 4fr;
  gap: 40px;
}
.headingConfianza {
  font-weight: 900;
  font-size: 30px !important;
  letter-spacing: 0.07em;
  font-family: 'FrutigerLTStd-Light';
  margin-bottom: 30px !important;
  margin-top: 50px !important;
}
.headingConfianza.lineh {
  min-width: 310px;
}
.wfull,
.wfull img {
  width: 100%;
}
.wfull h2 {
  text-transform: none;
  font-weight: 800;
  font-size: 22px;
  margin-bottom: 20px;
}
.subTitleSp {
  display: none;
}
.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.textWrap p,
.organismText p {
  font-weight: 400;
  width: 100%;
  text-align: left;
  letter-spacing: auto;
  line-height: 24px;
  font-size: 1rem;
}
.specialText {
  font-family: 'FrutigerLTStd-Bold';
  text-decoration: none;
  transition: all 0.3s;
}

.specialText img {
  width: 18px;
  margin-left: 5px;
  padding-top: 3px;
}

.specialText:hover {
  opacity: 0.7;
}
.organismText p {
  margin-bottom: 15px;
}
.specialText,
.blueTitle {
  color: rgb(0, 26, 175);
}
.blueTitle {
  font-family: 'FrutigerLTStd-Roman';
}
.textWrap p:first-child {
  margin-bottom: 40px;
}
.blueTitle {
  max-width: 85%;
  margin-left: 0;
}
.contWrap {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 5px;
}
.imgWrap img:not(:last-child) {
  margin-bottom: 80px;
}
.b2b {
  max-width: 150px;
  margin-bottom: 20px;
}
.separator,
.separatorPink {
  height: 3px;
  display: block;
  border-radius: 5px;
  width: 100%;
}
.separator {
  background-color: black;
  max-width: 420px;
}
.separatorPink {
  background-color: #e6004c;
  max-width: 165px;
}
.wrapSeparator {
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: center;
  margin-bottom: 100px;
  margin-top: -20px;
}
.phase1.New {
  display: grid !important;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: 40px;
}
.contentNewsletter {
  background-color: rgb(170, 206, 255);
  padding: 20px 40px;
  position: relative;
  width: 100% !important;
  display: grid !important;
  gap: 10px;
  grid-template-columns: 1.5fr 2fr;
}
.contentForm {
  position: relative;
  z-index: 2;
}
.imgNewWrap {
  position: relative;
  height: 100%;
}
.phase1.New .subText {
  color: rgb(41, 109, 232);
}
.phase1.New .contentForm .rounded {
  background-color: rgb(239, 119, 69);
  color: white !important;
  border: none !important;
  display: flex;
  align-items: center;
}
.phase1.New .contentForm .rounded span {
  text-transform: uppercase !important;
  margin-top: 4px;
}
.phase1.New .contentForm .rounded .icon.arrow-right {
  margin-top: -10px;
}
.imgBanner {
  position: absolute;
  width: 128%;
  z-index: 1;
  top: 50%;
  max-width: 390px;
  transform: translateY(-50%);
}
.newsletterWrapper {
  margin-top: 160px !important;
  margin-bottom: 80px;
}
#email.input {
  border-radius: 50px;
  background-color: white;
  border: none;
  max-width: 370px;
}
#newsletter .field .input::placeholder,
#newsletter .field .input {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 22px;
  color: #a3a3a3;
}
#newsletter .field .input {
  margin-left: 0px !important;
}
.imgHome {
  max-width: 300px;
  width: 100%;
}
.imgHomeMv {
  display: none;
  width: 100%;
  object-fit: cover;
}
.homeInTop {
  margin: 0px 0px 100px 0px !important;
}
.homeNew .headerBottom {
  display: none !important;
}
.homeNew .header {
  height: 110px;
  min-height: 110px;
}
.homeNew #home {
  padding-top: 20px;
}
#contactPage .contactData .addressFooter {
  max-width: none;
}
.searchBarMobile {
  display: none;
}
.mv,
.mv2 {
  display: none;
}
.lineh {
  line-height: 1.6em;
}
.footer .footerInner .contactData.desktop {
  display: grid !important;
  grid-template-columns: 1.75fr 1fr;
  width: fit-content;
  max-width: 395px;
  gap: 10px !important;
}
.footer .footerInner .contactData.desktop {
  display: flex;
  gap: 4px;
  flex-direction: column;
  width: fit-content;
}
.footer .footerInner .contactData.desktop a {
  width: fit-content;
}
.lifeChat {
  max-width: 115px;
  margin: auto;
  margin-bottom: 80px !important;
}
@media (max-width: 1220px) {
  .contentWrapper {
    grid-template-columns: 1fr 1.5fr;
  }
}
@media (max-width: 1180px) {
  .lifeChat {
    max-width: 115px;
    margin: auto;
    margin-bottom: 0px !important;
  }
  .dstop {
    display: none;
  }
  .newsletterWrapper {
    margin-top: 120px !important;
  }
  .mv {
    display: block;
  }
  .subTitleSp {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 800;
    text-transform: none;
  }
  .contentWrapper .wfull h2 {
    display: none;
  }
  .contentWrapper,
  .contWrap {
    display: flex;
    flex-direction: column;
  }
  .contWrap {
    gap: 20px;
  }
  .phase1.New,
  .newsletterWrapper,
  .newsletter.bordered,
  .newsletterWrapper .maxContainer {
    display: block !important;
  }
  .newsletterWrapper .maxContainer {
    margin: 0 auto;
  }
  .phase1.New .contentNewsletter {
    min-height: 400px !important;
    margin-top: 30px !important;
  }
  .imgWrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    max-width: 550px;
    align-items: center;
  }
  .imgWrap img {
    margin-bottom: 0px !important;
  }
}
@media (max-width: 1024px) {
  .footer .footerInner .contactData.desktop {
    display: flex !important;
  }
  .subText.sp {
    text-align: left !important;
  }
  .imgHome {
    display: none;
  }
  .homeNew .header {
    height: 50px;
    min-height: 50px;
  }
  .imgHomeMv {
    display: initial;
  }
  .searchBarMobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .flexBlock {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  .mv2 {
    display: block;
  }
  .dstop2 {
    display: none;
  }
  .contentWrapper2 {
    display: flex;
    flex-direction: column;
  }
  .wrapSeparator {
    margin-bottom: 60px;
    margin-top: 0px;
  }
}

@media (max-width: 600px) {
  .contentNewsletter {
    display: block !important;
  }
  .br {
    display: none;
  }
  .contentNewsletter {
    padding-bottom: 40px;
  }
  .imgNewWrap {
    height: 230px;
  }
  .imgNewWrap .imgBanner {
    max-width: 230px !important;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
}
@media (max-width: 500px) {
  .imgWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .imgWrap img {
    max-width: 150px;
    margin: 0 auto;
  }
}

.countDownWrapper {
  font-size: 10px;
  background-color: #ffe46a;
  border: 1px solid #ffaa00;
  padding: 3px 4px 0.5px;
  border-radius: 3px;
  min-width: 140px;
  text-align: center;
}
.countDownWrapper span {
  font-size: 12px;
  font-weight: 700;
  margin-right: 1px;
}

.offerTypeWrapper {
  color: #e6004c;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 1px;
}
.offerTypeWrapper button,
.btn {
  color: #e6004c;
  opacity: 0.63;
  background-color: transparent;
  transition: opacity 0.2s;
}
.offerTypeWrapper button:hover {
  opacity: 1;
}
.infoPopUpWrapper {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100%;
  align-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  backdrop-filter: blur(4px);
  padding: 40px;
}
.infoPopUpContent {
  background-color: #fff;
  position: relative;
  border-radius: 20px;
  max-width: 768px;
  margin: 0 auto;
  padding: 44px 32px;
  border: 2px solid #e6004c;
  box-shadow: 0px 6px 8px -2px rgba(0, 0, 0, 0.2), 0px 8px 14px -4px rgba(0, 0, 0, 0.3);
}
.infoPopUpContent h3 {
  color: #e6004c;
  font-size: 20px;
  margin-bottom: 20px;
}
.infoPopUpContent p {
  font-size: 16px;
  text-align: left;
}
.infoPopUpContent p:not(:last-child) {
  margin-bottom: 32px;
}
.close-button {
  color: #e6004c;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: all 0.15s;
  border: 2px solid #e6004c;
  border-radius: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.close-button:hover {
  color: #fff;
  background-color: #e6004c;
}

.availability-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  pointer-events: none;
}
.availability-text-wrapper {
  text-align: right;
}
.availability-label {
  font-size: 11px;
  font-style: italic;
  color: #666666;
}
.availability-msg {
  font-size: 12px;
  color: #111111;
  font-weight: 700;
}
.card-bottom-wrapper,
.card-top-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.card-bottom-wrapper-only-component {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

}
.card-top-wrapper {
  align-items: flex-start;
  min-height: 40px;
}
.card-top-wrapper .title-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.card-top-wrapper .title-wrapper h2 {
  margin-bottom: 0px !important;
  font-size: 12px !important;
}
.card-top-wrapper .freeDelivery .conditionsButton p {
  font-size: 9px;
  letter-spacing: 0.75px;
}
.w-fit {
  width: fit-content;
}
.priceItem.priceItem-New {
  padding: 10px 14px !important;
  gap: 5px;
  justify-content: space-between !important;
  min-width: 360px;
}
.card-top-wrapper .freeDelivery {
  height: fit-content;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.price,
.price .old-price,
.price.price-without-tax {
  font-size: 20px;
  color: #008f80 !important;
  font-weight: 300 !important;
  font-family: 'FrutigerLTStd-bold';
  text-align: left;
}
.price.price-without-tax,
.price.price-without-tax .old-price {
  font-size: 13px;
  line-height: 16px;
}
.price .old-price,
.price.price-without-tax .old-price {
  margin-right: 8px;
  color: #a3a3a3 !important;
  font-weight: 100 !important;
}
.price span.iva {
  margin-left: 5px !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #008f80 !important;
  font-family: 'FrutigerLTStd-bold' !important;
}
.offer-tag {
  border: 1.5px solid #05998b;
  border-radius: 8px;
  font-size: 9px;
  letter-spacing: 0.75px;
  color: #e6004c;
  font-family: 'FrutigerLTStd-Bold';
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 10px;
}
.offer-tag span {
  padding: 0px 6px;
  padding-top: 1.5px;
}
.offer-tag .discount {
  background-color: #05998b;
  border-radius: 6px;
  padding-top: 1px;
  height: 15px;
  color: white;
  font-size: 13px;
  width: 60px;
  text-align: center;
  margin-right: 0px;
}
.title-wrapper i {
  flex-shrink: 0 !important;
}
.product .previewHolder.mainProduct.new .bottom .pricesWrapper {
  display: flex !important;
  flex-direction: row;
}
.product .previewHolder.mainProduct.new .bottom .pricesWrapperOnlyEbook {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
}
.product .previewHolder.mainProduct.new .bottom .buyContainer.ebookPage {
  max-width: 740px;
}
.priceItem.priceItem-New.alone {
  border: 2px solid #b3b1b1;
  border-radius: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 4px 5px 0 #cacaca;
}

.card-top-wrapper.alone {
  min-height: auto !important;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@media (max-width: 1320px) {
  .product .previewHolder.mainProduct.new .bottom .pricesWrapper {
    display: flex !important;
    flex-direction: column;
  }
}
@media (max-width: 580px) {
  .freeDeliveryWrapper.w-fit .conditionsButton {
    bottom: 10px !important;
    right: 34px !important;
  }
  .priceItem.priceItem-New {
    min-width: auto;
  }
  .infoPopUpContent {
    padding: 44px 24px 32px;
  }
  .close-button {
    top: 10px;
    right: 10px;
  }
  .infoPopUpWrapper {
    padding: 16px;
  }
  .infoPopUpContent h3 {
    color: #e6004c;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .infoPopUpContent p {
    font-size: 14px;
  }
  .infoPopUpContent p:not(:last-child) {
    margin-bottom: 24px;
  }
}
@media (max-width: 440px) {
  .card-bottom-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .availability-wrapper {
    flex-direction: row-reverse;
  }
  .availability-text-wrapper {
    text-align: left;
  }
}
